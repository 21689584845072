import { USER_TYPES, MODULE_NAMES } from '@helpers/constants';

export const MODULES = {
  orderList: {
    name: MODULE_NAMES.order,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  supplier: {
    name: MODULE_NAMES.supplier,
    permissions: [USER_TYPES.internal]
  },
  customer: {
    name: MODULE_NAMES.customer,
    permissions: [USER_TYPES.internal]
  },
  product: {
    name: MODULE_NAMES.product,
    permissions: [USER_TYPES.internal]
  },
  task: {
    name: MODULE_NAMES.task,
    permissions: [USER_TYPES.internal]
  },
  order: {
    name: MODULE_NAMES.order,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  orderActivity: {
    name: MODULE_NAMES.orderActivity,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  orderTracking: {
    name: MODULE_NAMES.orderTracking,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  orderDocuments: {
    name: MODULE_NAMES.orderDocuments,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  orderPeople: {
    name: MODULE_NAMES.orderPeople,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  orderOverview: {
    name: MODULE_NAMES.orderOverview,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  orderStuffingPhotos: {
    name: MODULE_NAMES.orderStuffingPhotos,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  email: {
    name: MODULE_NAMES.email,
    permissions: [USER_TYPES.internal]
  },
  enquiry: {
    name: MODULE_NAMES.enquiry,
    permissions: [USER_TYPES.internal, USER_TYPES.customer]
  },
  finance: {
    name: MODULE_NAMES.payable,
    permissions: [USER_TYPES.internal]
  },
  quote: {
    name: MODULE_NAMES.quote,
    permissions: [USER_TYPES.internal]
  }
};
