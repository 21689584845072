import React from 'react';
import { Skeleton, Stack, Box, Divider } from '@mui/material';

export default function SkeletonQuoteListing() {
  return (
    <main style={{ padding: '24px' }}>
      {/** --- Enquiry Header Section (Tabs + Button) --- */}
      <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Stack direction="row" spacing={2}>
          {/* Skeleton for Tabs */}
          <Skeleton variant="text" width={120} height={30} />
          <Skeleton variant="text" width={120} height={30} />
        </Stack>

        {/* Skeleton for Add Enquiry button */}
        <Skeleton variant="rectangular" width={120} height={36} />
      </section>

      {/** --- Filters Section (Search Bar + Pagination) --- */}
      <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        {/* Skeleton for search bar */}
        <Skeleton variant="rectangular" width="40%" height={36} />

        {/* Skeleton for top pagination (Previous / Page / Next) */}
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="circular" width={36} height={36} />
          <Skeleton variant="text" width={50} height={30} />
          <Skeleton variant="circular" width={36} height={36} />
        </Stack>
      </section>

      {/** --- Enquiry List Section (Cards) --- */}
      <section style={{ marginBottom: '16px' }}>
        {/** Skeleton for multiple cards */}
        {Array.from({ length: 8 }).map((_, index) => (
          <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #E2E2E2', borderRadius: 1 }}>
            {/* Top row of the card */}
            <Stack direction="row" spacing={2} mb={2}>
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={80} />
            </Stack>
            <Divider />
            {/* Bottom row (buttons or details) */}
            <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
              <Skeleton variant="rectangular" width={100} height={32} />
              <Skeleton variant="rectangular" width={60} height={32} />
            </Stack>
          </Box>
        ))}
      </section>

      {/** --- Bottom Pagination --- */}
      <section>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={36} height={36} />
          <Skeleton variant="text" width={50} height={30} />
          <Skeleton variant="circular" width={36} height={36} />
        </Stack>
      </section>
    </main>
  );
}
