import QuoteForm from '@components/common/quote-form';
import { ChangeEvent, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Badge, Button, Drawer, IconNode, Tab, Tabs, ToolTip, Typography } from '@components/base';
import css from './index.module.scss';
import { getEnquiryList } from '@services/quote.service';
import notify from '@helpers/toastify-helper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  AutoComplete,
  DeletePrompt,
  ListPlaceholder,
  PaginationScroller,
  SearchBar
} from '@components/common';
import debounce from 'lodash.debounce';
import EnquiryCard from './components/enquiry-card';
import SkeletonQuoteListing from './components/skeleton';
import { fetchAllUsers } from '@services/user.service';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { set } from 'lodash';
import Images from '@assets/images';
import { getPermissionFromLocalStorage } from '@helpers/utils';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';

interface IFetchEnquiryList {
  page?: number;
  search: string;
  created_by: string;
}

const ENQUIRY_MAPPING = [
  {
    value: 1,
    key: 'quote_table',
    default: true
  },
  {
    value: 2,
    key: 'cost_calculator'
  }
];

const getDefaultTab = () => {
  const defaultTab = ENQUIRY_MAPPING.find((tab) => tab.default);
  return defaultTab ? defaultTab : ENQUIRY_MAPPING[0];
};

const itemLimit = 10;

const defaultTab = getDefaultTab();

const QuoteListing = (props: any) => {
  const { actions } = props.modulePermissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const showQuoteFormParam = searchParams.get('showQuoteForm');
  const initialPageNumber = parseInt(searchParams.get('page') || '1', 10);
  const activeTabParam = searchParams.get('activeTab');
  const debounceSearch = useRef<any>();
  const initialOrderState = useMemo(() => {
    return getValueByKey(activeTabParam || defaultTab.key);
  }, [activeTabParam]);

  function getValueByKey(key: string) {
    const state = ENQUIRY_MAPPING.find((item) => item.key === key);
    return state ? state.value : defaultTab.value;
  }

  const [quoteState, setQuoteState] = useState<any>({
    isLoading: false,
    isSearchLoading: false,
    showQuoteForm: showQuoteFormParam === 'true' ? true : false,
    activeStep: initialOrderState,
    enquiryCount: 0,
    enquiryList: [],
    user_list: [],
    currentPageNumber: initialPageNumber,
    searchText: '',
    created_by: [],
    initialUserList: [],
    showDisclaimer: false
  });

  const {
    isLoading,
    isSearchLoading,
    showQuoteForm,
    activeStep,
    enquiryCount,
    enquiryList,
    currentPageNumber,
    searchText,
    created_by,
    user_list,
    initialUserList,
    showDisclaimer
  } = quoteState;

  const fetchCreatedByList = async () => {
    setQuoteState((prevState: any) => ({ ...prevState, isLoading: true }));
    const userResponse = await fetchAllUsers({ role: 'internal' });
    if (userResponse.success) {
      const { data: user_list } = userResponse;
      setQuoteState((prevState: any) => ({
        ...prevState,
        user_list: user_list.users,
        isLoading: false,
        initialUserList: user_list.users
      }));
    } else {
      setQuoteState((prevState: any) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch order POC list', severity: 'error' });
    }
  };

  const filterCreatedBy = async (event: any, param: string) => {
    if (param && param != created_by.given_name) {
      const userResponse = await fetchAllUsers({ role: 'internal', name: param });
      if (userResponse.success) {
        const { data: user_list } = userResponse;
        setQuoteState((prevState: any) => ({
          ...prevState,
          user_list: user_list.users,
          isLoading: false
        }));
      } else {
        setQuoteState((prevState: any) => ({ ...prevState, isLoading: false }));
        notify({ message: 'Unable to fetch order POC list', severity: 'error' });
      }
    } else {
      setQuoteState((prevState: any) => ({ ...prevState, user_list: initialUserList }));
    }
  };

  const fetchEnquiryList = async (props: IFetchEnquiryList) => {
    const response = await getEnquiryList(props);
    if (response.success) {
      setQuoteState((prevState: any) => ({
        ...prevState,
        enquiryList: response.data.results,
        enquiryCount: response.data.count,
        isSearchLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.error || 'Something Went Wrong'
      });
    }
  };

  const handleQuoteFormClick = () => {
    setQuoteState((prevState: any) => ({
      ...prevState,
      showQuoteForm: !prevState.showQuoteForm
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      showQuoteForm: showQuoteForm ? 'false' : 'true'
    });
  };

  const handlePageChange = async (newPage: number) => {
    setQuoteState((prev: any) => ({ ...prev, isLoading: true }));
    await fetchEnquiryList({
      page: newPage,
      search: searchText,
      created_by: created_by?.user_id
    });
    setQuoteState((prevState: any) => ({
      ...prevState,
      isLoading: false,
      currentPageNumber: newPage
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage.toString()
    });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(enquiryCount / itemLimit)) return;
    handlePageChange(value);
  };

  const handleStepChange = async (_event: SyntheticEvent, newValue: number) => {
    setQuoteState((prev: any) => ({
      ...prev,
      activeStep: newValue
    }));
    setSearchParams(() => {
      return {
        ...Object.fromEntries(searchParams),
        activeTab: ENQUIRY_MAPPING.find((item) => item.value === newValue)?.key || ''
      };
    });
  };

  const fetchSearchResult = async (searchText: string) => {
    await fetchEnquiryList({
      page: 1,
      search: searchText,
      created_by: created_by?.user_id
    });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: '1'
    });
  };

  const handleOrderSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setQuoteState((prevState: any) => ({
      ...prevState,
      currentPageNumber: 1,
      searchText: value.trim(),
      isSearchLoading: true
    }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };

  useEffect(() => {
    fetchCreatedByList();
  }, []);

  useEffect(() => {
    fetchEnquiryList({
      page: 1,
      search: searchText,
      created_by: created_by?.user_id
    });
    setQuoteState((prevState: any) => ({
      ...prevState,
      currentPageNumber: 1
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: '1'
    });
  }, [created_by]);

  if (isLoading) {
    return <SkeletonQuoteListing />;
  }

  const onClose = () => {
    setQuoteState((prevState: any) => ({
      ...prevState,
      showDisclaimer: true
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      showQuoteForm: showQuoteForm ? 'false' : 'true'
    });
  };

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main className={css.mainWrapper}>
        <section className={css.enquiryHeader}>
          <div className={css.enquiryHeaderTitle}>
            {/* <Tabs value={activeStep} onChange={handleStepChange}> */}
            {/* <Tab
              label={
                <div className={css.titleTextIcon}>
                  <Typography variant="h2">Quote Table</Typography>
                  <Badge>{enquiryCount}</Badge>
                </div>
              }
              value={1}
            /> */}
            {/* <Tab
              label={
                <div className={css.titleTextIcon}>
                  <Typography variant="h2">Cost Calculator</Typography>
                </div>
              }
              value={2}
            /> */}
            {/* </Tabs> */}
            <div className={css.titleTextIcon}>
              <Typography variant="h2">Quote Table</Typography>
              <Badge>{enquiryCount}</Badge>
            </div>
          </div>
          <AccessWrapper show={actions?.create}>
            <Button
              variant="contained"
              onClick={() => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  showQuoteForm: 'true'
                });
                handleQuoteFormClick();
              }}>
              Add Enquiry
            </Button>
          </AccessWrapper>
        </section>

        <AccessWrapper show={actions?.read}>
          <section className={css.enquiryFilters}>
            <div className={css.filterSearchSelect}>
              <div className={css.searchBar}>
                <SearchBar
                  isLoading={isSearchLoading}
                  placeholder="Search"
                  className={css.search}
                  onChange={handleOrderSearch}
                />
                <div>
                  <ToolTip
                    title={'Search By: Customer Name, Product Name, Enquiry Id'}
                    placement="right">
                    <div>
                      <IconNode src={Images.alertGrey} alt="eye icon" />
                    </div>
                  </ToolTip>
                </div>
              </div>
              <AutoComplete
                isClearable
                options={user_list}
                onInputChange={(event: any, param: string) => filterCreatedBy(event, param)}
                onInputSelection={(event: any, param: string) => {
                  setQuoteState((prevState: any) => ({
                    ...prevState,
                    created_by: param
                  }));
                }}
                keyOption="given_name"
                getOptionValue={(option: any) => option.user_id}
                placeholder="Search User"
                width="270px"
              />
            </div>
            <PaginationNavigator
              currentPageNumber={currentPageNumber}
              count={enquiryCount}
              itemLimit={itemLimit}
              validatePageChange={validatePageChange}
            />
          </section>

          <section className={css.enquiryList}>
            {enquiryCount > 0
              ? enquiryList.map((enquiry: any) => (
                  <EnquiryCard key={enquiry.enquiry_id} data={enquiry} />
                ))
              : !isLoading && (
                  <ListPlaceholder
                    handleButtonClick={() => {
                      setSearchParams({
                        ...Object.fromEntries(searchParams),
                        showQuoteForm: 'true'
                      });
                      handleQuoteFormClick();
                    }}
                    title="No Enquiry Added Yet"
                    supportingText="When an Enquiry is added by you, they will show up here"
                    buttonText="Add Enquiry"
                  />
                )}
          </section>
        </AccessWrapper>

        {!isLoading && (
          <PaginationScroller
            variant="text"
            defaultPage={1}
            count={enquiryCount}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={validatePageChange}
          />
        )}

        <AccessWrapper show={actions?.create}>
          <Drawer open={showQuoteForm} anchor="right" onClose={onClose}>
            <QuoteForm
              onClose={onClose}
              onSubmitClose={() => {
                setQuoteState((prevState: any) => ({
                  ...prevState,
                  showQuoteForm: false
                }));
              }}
            />
          </Drawer>

          {showDisclaimer && (
            <DeletePrompt
              open={showDisclaimer}
              onClose={() =>
                setQuoteState((prevState: any) => ({
                  ...prevState,
                  showDisclaimer: false
                }))
              }
              onConfirm={() =>
                setQuoteState((prevState: any) => ({
                  ...prevState,
                  showDisclaimer: false,
                  showQuoteForm: false
                }))
              }
              message="Are you sure you want to close?"
            />
          )}
        </AccessWrapper>
      </main>
    </AccessWrapper>
  );
};

export default QuoteListing;

interface PaginationNavigatorProps {
  currentPageNumber: number;
  count: number;
  itemLimit: number;
  validatePageChange: (event: any, value: number) => void;
}

const PaginationNavigator = (props: PaginationNavigatorProps) => {
  const { currentPageNumber, count, itemLimit, validatePageChange } = props;
  return (
    <div className={css.paginationFilter}>
      <ToolTip title="Previous Page">
        <ArrowBackIosIcon
          sx={{
            height: '40px',
            cursor: currentPageNumber > 1 ? 'pointer' : 'default',
            color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
          }}
          onClick={() => validatePageChange(null, currentPageNumber - 1)}
        />
      </ToolTip>
      <Typography className={css.pageOfTotalPage}>
        {currentPageNumber} of {Math.ceil(count / itemLimit)}
      </Typography>
      <ToolTip title="Next Page">
        <ArrowForwardIosIcon
          sx={{
            height: '40px',
            cursor: currentPageNumber < Math.ceil(count / itemLimit) ? 'pointer' : 'default',
            color: currentPageNumber < Math.ceil(count / itemLimit) ? '#000000' : '#e2e2e2'
          }}
          onClick={() => validatePageChange(null, currentPageNumber + 1)}
        />
      </ToolTip>
    </div>
  );
};
