import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import EnquiryFilters from './components/filters';
import EnquiryGenericInformation from './components/generic-information';
import css from './index.module.scss';
import NonConsolidationQuote from './components/non-consolidation';
import ConsolidationQuote from './components/consolidation';
import { Button } from '@components/base';

const QuoteGenerator = (props: any) => {
  const {
    filterState,
    setFilterState,
    setQuoteFormState,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    onFormSubmit,
    filterProductOptions,
    productOptions,
    filterPorts,
    portOfLoadingOptions,
    portOfDischargeOptions,
    isEdit
  } = props;
  const { consolidation } = filterState;
  const { control, getValues, reset, handleSubmit } = useFormContext();

  const currency1 = getValues('genericInformation.currency1');
  const primaryUnitWatch = useWatch({
    name: 'genericInformation.primaryUnit',
    control
  });

  const {
    fields: fields,
    append: append,
    remove: remove
  } = useFieldArray({
    name: 'quote',
    control
  });

  const handleAddFields = () => {
    consolidation
      ? append({
          product: [
            {
              isExpanded: true,
              productName: null,
              productPackaging: null,
              dealQuantity: null,
              dealQuantityUnit: primaryUnitWatch,
              base_price: {
                value: null,
                currency: currency1,
                cost_id: null
              },
              fob_price: {
                value: null,
                currency: currency1,
                cost_id: null
              },
              cif_price: {
                value: null,
                currency: currency1,
                cost_id: null
              },
              cfr_price: {
                value: null,
                currency: currency1,
                cost_id: null
              },
              profitMarginExpected: null,
              final_exWorks_price: {
                value: null,
                currency: currency1,
                cost_id: null
              },
              finalQuote_selling_price: {
                value: null,
                currency: currency1,
                cost_id: null
              },
              countryOfOrigin: null,
              moq: null,
              moqUnit: primaryUnitWatch
            }
          ],
          totalWeight: null,
          totalWeightUnit: primaryUnitWatch,
          totalLocalFreight: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          totalPortAndClearanceExpenses: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          totalFreight: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          portOfLoading: null,
          portOfDischarge: null,
          insuranceCost: 0.1,
          additionalCost: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          financeCost: null,
          discount: null,
          incoTerms: null,
          switchBlCost: {
            value: null,
            currency: currency1,
            cost_id: null
          }
        })
      : append({
        quote_id: null,
          isExpanded: true,
          productName: null,
          productPackaging: null,
          dealQuantity: null,
          countryOfOrigin: null,
          dealQuantityUnit: primaryUnitWatch,
          base_price: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          fob_price: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          cif_price: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          cfr_price: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          moq: null,
          moqUnit: primaryUnitWatch,
          totalWeight: null,
          totalWeightUnit: primaryUnitWatch,
          totalLocalFreight: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          totalPortAndClearanceExpenses: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          totalFreight: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          portOfLoading: null,
          portOfDischarge: null,
          insuranceCost: 0.1,
          additionalCost: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          financeCost: null,
          profitMarginExpected: null,
          discount: null,
          incoTerms: null,
          switchBlCost: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          final_exWorks_price: {
            value: null,
            currency: currency1,
            cost_id: null
          },
          finalQuote_selling_price: {
            value: null,
            currency: currency1,
            cost_id: null
          }
        });
  };

  const handleDuplicateFields = (index: number) => {
    append(getValues().quote[index]);
  };

  const resetForm = () => {
    reset();
  };

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.mainWrapper}>
      <EnquiryFilters
        setFilterState={setFilterState}
        filterState={filterState}
        resetForm={resetForm}
        isEdit={isEdit}
      />
      <EnquiryGenericInformation
        setQuoteFormState={setQuoteFormState}
        filterState={filterState}
        selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
      />

      <div className={css.contentWrapper}>
        {!consolidation &&
          fields.map((item: any, index: number) => {
            const handleDeleteClick = () => {
              remove(index);
            };
            return (
              <NonConsolidationQuote
                key={index}
                index={index}
                isExpanded={`quote.${index}.isExpanded`}
                productName={`quote.${index}.productName`}
                productPackaging={`quote.${index}.productPackaging`}
                dealQuantity={`quote.${index}.dealQuantity`}
                dealQuantityUnit={`quote.${index}.dealQuantityUnit`}
                basePrice={`quote.${index}.base_price.value`}
                basePriceCurrency={`quote.${index}.base_price.currency`}
                fobPrice={`quote.${index}.fob_price.value`}
                fobPriceCurrency={`quote.${index}.fob_price.currency`}
                fobPriceManualOverride={`quote.${index}.fob_price.manualOverride`}
                moq={`quote.${index}.moq`}
                moqUnit={`quote.${index}.moqUnit`}
                totalWeight={`quote.${index}.totalWeight`}
                totalWeightUnit={`quote.${index}.totalWeightUnit`}
                totalLocalFrieght={`quote.${index}.totalLocalFreight.value`}
                totalLocalFrieghtCurrency={`quote.${index}.totalLocalFreight.currency`}
                totalPortAndClearanceExpenses={`quote.${index}.totalPortAndClearanceExpenses.value`}
                totalPortAndClearanceExpensesCurrency={`quote.${index}.totalPortAndClearanceExpenses.currency`}
                totalFreight={`quote.${index}.totalFreight.value`}
                totalFreightCurrency={`quote.${index}.totalFreight.currency`}
                portOfLoading={`quote.${index}.portOfLoading`}
                portOfDischarge={`quote.${index}.portOfDischarge`}
                insuranceCost={`quote.${index}.insuranceCost`}
                additionalCost={`quote.${index}.additionalCost.value`}
                additionalCostCurrency={`quote.${index}.additionalCost.currency`}
                financeCost={`quote.${index}.financeCost`}
                profitMarginExpected={`quote.${index}.profitMarginExpected`}
                discount={`quote.${index}.discount`}
                incoTerms={`quote.${index}.incoTerms`}
                switchBlCost={`quote.${index}.switchBlCost.value`}
                switchBLCostCurrency={`quote.${index}.switchBlCost.currency`}
                cifPrice={`quote.${index}.cif_price.value`}
                cfrPrice={`quote.${index}.cfr_price.value`}
                cifPriceManualOverride={`quote.${index}.cif_price.manualOverride`}
                cfrPriceManualOverride={`quote.${index}.cfr_price.manualOverride`}
                cifPriceCurrency={`quote.${index}.cif_price.currency`}
                cfrPriceCurrency={`quote.${index}.cfr_price.currency`}
                finalExWorksPrice={`quote.${index}.final_exWorks_price.value`}
                finalExWorksPriceCurrency={`quote.${index}.final_exWorks_price.currency`}
                finalQuoteSellingPrice={`quote.${index}.finalQuote_selling_price.value`}
                countryOfOrigin={`quote.${index}.countryOfOrigin`}
                handleDeleteClick={handleDeleteClick}
                fieldLength={fields.length}
                handleDublicateQuote={handleDuplicateFields}
                filterState={filterState}
                handleAddFields={handleAddFields}
                selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
                selectedCurrency={selectedCurrency}
                filterProductOptions={filterProductOptions}
                productOptions={productOptions}
                filterPorts={filterPorts}
                portOfLoadingOptions={portOfLoadingOptions}
                portOfDischargeOptions={portOfDischargeOptions}
                isEdit={isEdit}
              />
            );
          })}

        {consolidation &&
          fields.map((item: any, index: number) => {
            const handleDeleteClick = () => {
              remove(index);
            };
            return (
              <ConsolidationQuote
                key={item.id}
                quoteIndex={index}
                handleQuoteDeleteClick={handleDeleteClick}
                handleDublicateQuote={handleDuplicateFields}
                totalWeight={`quote.${index}.totalWeight`}
                totalWeightUnit={`quote.${index}.totalWeightUnit`}
                totalLocalFrieght={`quote.${index}.totalLocalFreight.value`}
                totalLocalFrieghtCurrency={`quote.${index}.totalLocalFreight.currency`}
                totalPortAndClearanceExpenses={`quote.${index}.totalPortAndClearanceExpenses.value`}
                totalPortAndClearanceExpensesCurrency={`quote.${index}.totalPortAndClearanceExpenses.currency`}
                totalFreight={`quote.${index}.totalFreight.value`}
                totalFreightCurrency={`quote.${index}.totalFreight.currency`}
                portOfLoading={`quote.${index}.portOfLoading`}
                portOfDischarge={`quote.${index}.portOfDischarge`}
                insuranceCost={`quote.${index}.insuranceCost`}
                additionalCost={`quote.${index}.additionalCost.value`}
                additionalCostCurrency={`quote.${index}.additionalCost.currency`}
                financeCost={`quote.${index}.financeCost`}
                discount={`quote.${index}.discount`}
                incoTerms={`quote.${index}.incoTerms`}
                fieldLength={fields.length}
                filterState={filterState}
                handleAddFields={handleAddFields}
                selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
                selectedCurrency={selectedCurrency}
                filterProductOptions={filterProductOptions}
                productOptions={productOptions}
                filterPorts={filterPorts}
                portOfLoadingOptions={portOfLoadingOptions}
                portOfDischargeOptions={portOfDischargeOptions}
                switchBlCost={`quote.${index}.switchBlCost.value`}
                switchBLCostCurrency={`quote.${index}.switchBlCost.currency`}
                isEdit={isEdit}
              />
            );
          })}
        <Button variant="outlined-secondary" onClick={() => handleAddFields()}>
          Add Quote
        </Button>
        <div className={css.actionButtons}>
          <Button variant="contained" type="submit">
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default QuoteGenerator;
