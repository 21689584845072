import { Fragment, useMemo } from 'react';
import NavigationBar from '@components/common/navbar';
import { Outlet } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';

function MainLayout() {
  const navItems = useMemo(
    () => [
      {
        to: `/${CLIENT_ROUTES.order}`,
        children: 'Orders'
      },
      {
        to: `/${CLIENT_ROUTES.supplier}`,
        children: 'Suppliers'
      },
      {
        to: `/${CLIENT_ROUTES.customer}`,
        children: 'Customers'
      },
      {
        to: `/${CLIENT_ROUTES.product}`,
        children: 'Products'
      },
      {
        to: `/${CLIENT_ROUTES.enquiry}`,
        children: 'Enquiry'
      },
      {
        to: `/${CLIENT_ROUTES.task}`,
        children: 'Tasks'
      },
      {
        to: `/${CLIENT_ROUTES.finance}`,
        children: 'Payables'
      },
      {
        to: `/${CLIENT_ROUTES.quoteGenerator}`,
        children: 'Quote'
      }
    ],
    []
  );

  const navItemsForCustomer = useMemo(
    () => [
      {
        to: `/${CLIENT_ROUTES.order}`,
        children: 'Orders'
      },
      {
        to: `/${CLIENT_ROUTES.enquiry}`,
        children: 'Enquiry'
      }
    ],
    []
  );

  return (
    <Fragment>
      <NavigationBar items={navItems} navItemsForCustomer={navItemsForCustomer} />
      <Outlet />
    </Fragment>
  );
}
export default MainLayout;
