import { CheckboxLabel } from '@components/base';
import { IFilterState } from '../../../..';
import css from './index.module.scss';
import DeletePrompt from '@components/common/delete-prompt';
import { useState } from 'react';

const EnquiryFilters = (props: any) => {
  const { setFilterState, filterState, resetForm, isEdit } = props;
  const { fcl, mtt, consolidation } = filterState;

  const [showPrompt, setShowPrompt] = useState(false);

  const handleConsolidationChange = (value: boolean) => {
    if (value !== consolidation) {
      setShowPrompt(true);
    }
  };

  const handleConfirmSwitch = () => {
    setFilterState((prevState: IFilterState) => ({
      ...prevState,
      consolidation: !consolidation,
      mtt: false
    }));
    resetForm && resetForm();
    setShowPrompt(false);
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  return (
    <div className={css.filterWrapper}>
      <div className={css.fiterHeading}>Filter :</div>
      <div className={css.filterContainer}>
        <div className={css.filter}>
          <CheckboxLabel
            onClick={() =>
              setFilterState((prevState: IFilterState) => ({
                ...prevState,
                fcl: true
              }))
            }
            checked={fcl}
            label={<div className={css.filterLabel}>FCL</div>}
          />
          <CheckboxLabel
            onClick={() =>
              setFilterState((prevState: IFilterState) => ({
                ...prevState,
                fcl: false
              }))
            }
            checked={!fcl}
            label={<div className={css.filterLabel}>LCL</div>}
          />
        </div>
        <div className={css.filter}>
          <CheckboxLabel
            onClick={() =>
              setFilterState((prevState: IFilterState) => ({
                ...prevState,
                mtt: false
              }))
            }
            checked={!mtt}
            label={<div className={css.filterLabel}>Non-MTT</div>}
          />
          <CheckboxLabel
            onClick={() =>
              setFilterState((prevState: IFilterState) => ({
                ...prevState,
                mtt: true
              }))
            }
            checked={mtt}
            label={<div className={css.filterLabel}>MTT</div>}
          />
        </div>
        <div className={css.filter}>
          <CheckboxLabel
            onClick={() => handleConsolidationChange(false)}
            disabled={isEdit}
            checked={!consolidation}
            label={<div className={css.filterLabel}>Non-Consolidation</div>}
          />
          <CheckboxLabel
            onClick={() => handleConsolidationChange(true)}
            disabled={isEdit}
            checked={consolidation}
            label={<div className={css.filterLabel}>Consolidation</div>}
          />
        </div>
      </div>

      <DeletePrompt
        open={showPrompt}
        onClose={handleClosePrompt}
        onConfirm={handleConfirmSwitch}
        message={`Switching to a ${
          consolidation ? 'Non-Consolidation' : 'Consolidation'
        } will reset your current data. Are you sure you want to continue?`}
      />
    </div>
  );
};

export default EnquiryFilters;
