import css from './index.module.scss';
import QuotePackageWrapper from '../quote-package-wrapper';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import Images from '@assets/images';
import { IconNode, ToolTip, Typography } from '@components/base';
import { AutoComplete, SelectLabel, TextField } from '@components/common';
import QuoteProductFields from './components/quote-product-field';
import { INCO_TERMS, PORT_FIELD_TYPES, WEIGHT_SYSTEM } from '@helpers/constants';
import { formatPortOptions } from '@helpers/utils';

const ConsolidationQuote = (props: any) => {
  const {
    quoteIndex,
    handleQuoteDeleteClick,
    handleDublicateQuote,
    fieldLength,
    totalWeight,
    totalWeightUnit,
    totalLocalFrieght,
    totalLocalFrieghtCurrency,
    totalPortAndClearanceExpenses,
    totalPortAndClearanceExpensesCurrency,
    totalFreight,
    totalFreightCurrency,
    portOfLoading,
    portOfDischarge,
    insuranceCost,
    additionalCost,
    additionalCostCurrency,
    financeCost,
    discount,
    incoTerms,
    filterState,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    filterPorts,
    portOfLoadingOptions,
    portOfDischargeOptions,
    filterProductOptions,
    productOptions,
    switchBLCostCurrency,
    switchBlCost
  } = props;
  const { fcl, mtt, consolidation } = filterState;
  const { control, getValues, setValue } = useFormContext();

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove
  } = useFieldArray({
    name: `quote.${quoteIndex}.product`,
    control
  });

  const currency1 = getValues(`genericInformation.currency1`);
  const primaryUnit = useWatch({ name: 'genericInformation.primaryUnit' });

  const handleAddProductFields = () => {
    productAppend({
      isExpanded: true,
      productName: null,
      productPackaging: null,
      dealQuantity: null,
      dealQuantityUnit: primaryUnit,
      base_price: {
        value: null,
        currency: currency1,
        cost_id: null
      },
      fob_price: {
        value: null,
        currency: currency1,
        cost_id: null,
        manualOverride: false
      },
      profitMarginExpected: null,
      final_exWorks_price: {
        value: null,
        currency: currency1,
        cost_id: null,
        manualOverride: false
      },
      cif_price: {
        value: null,
        currency: currency1,
        cost_id: null,
        manualOverride: false
      },
      cfr_price: {
        value: null,
        currency: currency1,
        cost_id: null,
        manualOverride: false
      },
      moq: null,
      moqUnit: primaryUnit,
      countryOfOrigin: null
    });
  };

  const handleDuplicateProductField = (quoteIndex: number, index: number) => {
    const values = getValues();
    const product = values.quote[quoteIndex].product[index];
    productAppend(product);
  };

  const primaryUnitWatch = useWatch({ name: 'genericInformation.primaryUnit', control });

  return (
    <main className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <div className={css.headerText}>Quote {quoteIndex + 1}</div>
        <div className={css.headerIcons}>
          <ToolTip title="Delete">
            <div>
              <IconNode
                src={fieldLength > 1 ? Images.deleteRed : Images.delete}
                onClick={() => (fieldLength > 1 ? handleQuoteDeleteClick() : undefined)}
              />
            </div>
          </ToolTip>
          <ToolTip title="Dublicate">
            <div>
              <IconNode
                src={Images.dublicateRed}
                onClick={() => handleDublicateQuote(quoteIndex)}
              />
            </div>
          </ToolTip>
        </div>
      </div>
      <div className={css.contentWrapper}>
        <QuoteProductFields
          fields={productFields}
          remove={productRemove}
          handleAddProductFields={handleAddProductFields}
          quoteIndex={quoteIndex}
          handleDuplicateProductField={handleDuplicateProductField}
          selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
          selectedCurrency={selectedCurrency}
          filterProductOptions={filterProductOptions}
          productOptions={productOptions}
        />
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label="Total local freight"
            unitPlaceholder="Currency"
            inputPlaceholder="Total local freight"
            input={totalLocalFrieght}
            unit={totalLocalFrieghtCurrency}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
          <QuotePackageWrapper
            label="Total Port and Clearance Expenses"
            unitPlaceholder="Currency"
            inputPlaceholder="Total Port and Clearance Expenses"
            input={totalPortAndClearanceExpenses}
            unit={totalPortAndClearanceExpensesCurrency}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label={fcl ? 'Total weight in one container' : 'Total weight'}
            unitPlaceholder="Unit"
            inputPlaceholder="Total weight"
            input={totalWeight}
            unit={totalWeightUnit}
            control={control}
            unitOptions={selectedWeightSystemChildOptions}
            currency={false}
          />
          <QuotePackageWrapper
            label={fcl ? 'Total freight per FCL ' : 'Total freight'}
            unitPlaceholder="Currency"
            inputPlaceholder="Total freight"
            input={totalFreight}
            unit={totalFreightCurrency}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={portOfLoading}
            control={control}
            render={({ field, fieldState }) => {
              const onPortSelection = (event: any, param: any) => {
                if (param) {
                  setValue(portOfLoading, param);
                } else {
                  setValue(portOfLoading, null);
                }
              };
              const formattedPortOfLoading = formatPortOptions(portOfLoadingOptions);
              return (
                <AutoComplete
                  {...field}
                  required
                  label="Port Of Loading"
                  placeholder="Start typing"
                  options={formattedPortOfLoading}
                  onInputChange={(event: any, param: any) =>
                    filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_LOADING)
                  }
                  onInputSelection={onPortSelection}
                  keyOption="label"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper1}
                />
              );
            }}
          />
          <Controller
            name={portOfDischarge}
            control={control}
            render={({ field, fieldState }) => {
              const onPortSelection = (event: any, param: any) => {
                if (param) {
                  setValue(portOfDischarge, param);
                } else {
                  setValue(portOfDischarge, null);
                }
              };
              const formattedPortOfDischarge = formatPortOptions(portOfDischargeOptions);
              return (
                <AutoComplete
                  {...field}
                  label="Port Of Discharge"
                  placeholder="Start typing"
                  options={formattedPortOfDischarge}
                  onInputChange={(event: any, param: any) =>
                    filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_DISCHARGE)
                  }
                  onInputSelection={onPortSelection}
                  keyOption="label"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper1}
                />
              );
            }}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={insuranceCost}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Insurance cost
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={incoTerms}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                options={INCO_TERMS}
                label="Inco Terms"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>

        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label={
              <span>
                Additional
                {primaryUnitWatch && (
                  <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                )}
              </span>
            }
            unitPlaceholder="Currency"
            inputPlaceholder="Additional Cost"
            input={additionalCost}
            unit={additionalCostCurrency}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
          {mtt && (
            <QuotePackageWrapper
              label="Switch BL Cost"
              unitPlaceholder="Currency"
              inputPlaceholder="Switch BL Cost"
              input={switchBlCost}
              unit={switchBLCostCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
            />
          )}
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={financeCost}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Finance cost
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={discount}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Discount
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </main>
  );
};

export default ConsolidationQuote;
