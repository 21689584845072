import { useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { getQuoteDocuments } from '@services/quote.service';
import { useParams } from 'react-router-dom';
import notify from '@helpers/toastify-helper';
import { DataGrid, NoDocumentPlaceholder, TableSkeleton } from '@components/common';
import { CellProps } from 'react-table';
import { getFormattedDate, getFormattedTime, openDocumentSignedLink } from '@helpers/utils';
import { IconNode, ToolTip } from '@components/base';
import Images from '@assets/images';

const GeneratePDFTab = () => {
  const params = useParams();

  const [documentState, setDocumentState] = useState({
    documentData: [],
    isLoading: false
  });

  const { documentData, isLoading } = documentState;

  const getDocuments = async () => {
    setDocumentState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await getQuoteDocuments(params.id ? params.id : '');
    if (response.success) {
      setDocumentState((prevState) => ({
        ...prevState,
        documentData: response.data?.documents ?? [],
        isLoading: false
      }));
    } else {
      setDocumentState((prevState) => ({ ...prevState, isLoading: false }));
      notify({
        message: response?.message ?? 'Something Went Wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  const [documentColumn, document] = useMemo(() => {
    const column: any = [
      {
        Header: 'File Name',
        accessor: 'documentName'
      },
      {
        Header: 'Created By',
        accessor: 'created_by'
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <div className={css.createdAt}>
              <div>{getFormattedDate(value)}</div>
              <div>{getFormattedTime(value)}</div>
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'url',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <div className={css.downloadLinks}>
              <ToolTip title="Open PDF">
                <div>
                  <IconNode
                    src={Images.colouredPdf}
                    onClick={() => {
                      openDocumentSignedLink(value.pdf_path);
                    }}
                  />
                </div>
              </ToolTip>
              <ToolTip title="Download Word">
                <div>
                  <IconNode
                    src={Images.colouredWord}
                    onClick={() => {
                      openDocumentSignedLink(value.docx_path);
                    }}
                  />
                </div>
              </ToolTip>
            </div>
          );
        }
      }
    ];

    const row: any = (documentData.length > 0 ? documentData : [])?.map((doc: any) => {
      return {
        documentName: doc.filename,
        created_by: doc.created_by,
        created_at: doc.created_at,
        url: {
          docx_path: doc.docx_path,
          pdf_path: doc.pdf_path
        }
      };
    });

    return [column, row];
  }, [documentData]);

  useEffect(() => {
    getDocuments();
  }, [params]);

  if (isLoading) {
    return <TableSkeleton rowsNum={10} colsNum={4} />;
  }

  return (
    <>
      {documentData.length ? (
        <DataGrid columns={documentColumn} data={document} />
      ) : (
        !isLoading && <NoDocumentPlaceholder title="No Quote Added/Generated Yet" />
      )}
    </>
  );
};

export default GeneratePDFTab;
