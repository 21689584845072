import { Controller, useFormContext, useWatch } from 'react-hook-form';
import css from './index.module.scss';
import { AutoComplete, TextField } from '@components/common';
import { Button, IconNode, ToolTip, Typography } from '@components/base';
import Images from '@assets/images';
import { useEffect } from 'react';
import {
  calculateCFRPrice,
  calculateCIFPrice,
  calculateExWorksPrice,
  calculateFobPrice,
  calculateQuotedPrice
} from '@helpers/utils';
import QuotePackageWrapper from '../../../quote-package-wrapper';
import { createFilterOptions } from '@mui/material';

const filter = createFilterOptions<any>();

const QuoteProductFields = (props: any) => {
  const {
    fields,
    handleAddProductFields,
    remove,
    quoteIndex,
    handleDuplicateProductField,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    filterProductOptions,
    productOptions
  } = props;

  return (
    <main className={css.container}>
      {fields.map((product: any, index: number) => {
        const handleDeleteClick = () => remove(index);
        return (
          <ProductFields
            key={index}
            index={index}
            quoteIndex={quoteIndex}
            isExpanded={`quote.${quoteIndex}.product.${index}.isExpanded`}
            handleDeleteClick={handleDeleteClick}
            handleDuplicateProduct={handleDuplicateProductField}
            productName={`quote.${quoteIndex}.product.${index}.productName`}
            productPackaging={`quote.${quoteIndex}.product.${index}.productPackaging`}
            dealQuantity={`quote.${quoteIndex}.product.${index}.dealQuantity`}
            dealQuantityUnit={`quote.${quoteIndex}.product.${index}.dealQuantityUnit`}
            basePrice={`quote.${quoteIndex}.product.${index}.base_price.value`}
            basePriceCurrency={`quote.${quoteIndex}.product.${index}.base_price.currency`}
            fobPriceCurrency={`quote.${quoteIndex}.product.${index}.fob_price.currency`}
            fobPrice={`quote.${quoteIndex}.product.${index}.fob_price.value`}
            fobPriceManualOverride={`quote.${quoteIndex}.product.${index}.fobManualOverride`}
            profitMarginExpected={`quote.${quoteIndex}.product.${index}.profitMarginExpected`}
            cifPrice={`quote.${quoteIndex}.product.${index}.cif_price.value`}
            cfrPrice={`quote.${quoteIndex}.product.${index}.cfr_price.value`}
            cifPriceManualOverride={`quote.${quoteIndex}.product.${index}.cifManualOverride`}
            cfrPriceManualOverride={`quote.${quoteIndex}.product.${index}.cfrManualOverride`}
            cifPriceCurrency={`quote.${quoteIndex}.product.${index}.cif_price.currency`}
            cfrPriceCurrency={`quote.${quoteIndex}.product.${index}.cfr_price.currency`}
            finalExWorksPrice={`quote.${quoteIndex}.product.${index}.final_exWorks_price.value`}
            finalExWorksPriceCurrency={`quote.${quoteIndex}.product.${index}.final_exWorks_price.currency`}
            finalQuoteSellingPrice={`quote.${quoteIndex}.product.${index}.finalQuote_selling_price.value`}
            countryOfOrigin={`quote.${quoteIndex}.product.${index}.countryOfOrigin`}
            moq={`quote.${quoteIndex}.product.${index}.moq`}
            moqUnit={`quote.${quoteIndex}.product.${index}.moqUnit`}
            fieldLength={fields.length}
            selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
            selectedCurrency={selectedCurrency}
            filterProductOptions={filterProductOptions}
            productOptions={productOptions}
          />
        );
      })}
      <Button variant="outlined-secondary" onClick={handleAddProductFields}>
        Add Product
      </Button>
    </main>
  );
};

export default QuoteProductFields;

const ProductFields = (props: any) => {
  const {
    index,
    quoteIndex,
    productName,
    productPackaging,
    dealQuantity,
    dealQuantityUnit,
    basePrice,
    basePriceCurrency,
    fobPriceCurrency,
    fobPrice,
    profitMarginExpected,
    fieldLength,
    handleDuplicateProduct,
    handleDeleteClick,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    cifPrice,
    cfrPrice,
    cifPriceCurrency,
    cfrPriceCurrency,
    finalExWorksPrice,
    finalQuoteSellingPrice,
    filterProductOptions,
    moq,
    moqUnit,
    productOptions,
    countryOfOrigin,
    finalExWorksPriceCurrency,
    fobPriceManualOverride,
    cifPriceManualOverride,
    cfrPriceManualOverride
  } = props;

  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
    resetField
  } = useFormContext();

  const primaryUnitWatch = useWatch({
    control,
    name: `genericInformation.primaryUnit`
  });

  const basePriceWatch = useWatch({
    control,
    name: basePrice
  });

  const basePriceCurrencyWatch = useWatch({
    control,
    name: basePriceCurrency
  });

  const finalExWorksPriceWatch = useWatch({
    control,
    name: finalExWorksPrice
  });

  const finalExWorksPriceCurrencyWatch = useWatch({
    control,
    name: finalExWorksPriceCurrency
  });

  const dealQuantityWatch = useWatch({
    control,
    name: dealQuantity
  });

  const dealQuantityUnitWatch = useWatch({
    control,
    name: dealQuantityUnit
  });

  const fobPriceCurrencyWatch = useWatch({
    control,
    name: fobPriceCurrency
  });

  const fobPriceWatch = useWatch({
    control,
    name: fobPrice
  });

  const fobManualOverrideWatch = useWatch({
    control,
    name: fobPriceManualOverride
  });

  const cifPriceWatch = useWatch({
    control,
    name: cifPrice
  });

  const cifPriceCurrencyWatch = useWatch({
    control,
    name: cifPriceCurrency
  });

  const cifManualOverrideWatch = useWatch({
    control,
    name: cifPriceManualOverride
  });

  const cfrPriceCurrencyWatch = useWatch({
    control,
    name: cfrPriceCurrency
  });

  const cfrPriceWatch = useWatch({
    control,
    name: cfrPrice
  });

  const cfrManualOverrideWatch = useWatch({
    control,
    name: cfrPriceManualOverride
  });

  const profitMarginExpectedWatch = useWatch({
    control,
    name: profitMarginExpected
  });

  const currency1Watch = useWatch({
    control,
    name: `genericInformation.currency1`
  });

  const fxRateWatch = useWatch({
    control,
    name: `genericInformation.fxRate`
  });

  const totalLocalFreightWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalLocalFreight.value`
  });

  const totalLocalFrieghtCurrencyWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalLocalFreight.currency`
  });

  const totalPortAndClearanceExpensesWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalPortAndClearanceExpenses.value`
  });

  const totalPortAndClearanceExpensesCurrencyWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalPortAndClearanceExpenses.currency`
  });

  const totalWeightWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalWeight`
  });

  const totalWeightUnitWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalWeightUnit`
  });

  const totalFreightWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalFreight.value`
  });

  const totalFreightCurrencyWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.totalFreight.currency`
  });

  const additionalCostWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.additionalCost.value`
  });

  const additionalCostCurrencyWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.additionalCost.currency`
  });

  const financeCostWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.financeCost`
  });

  const discountWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.discount`
  });

  const insuranceCostWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.insuranceCost`
  });

  const isExpandedWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.product.${index}.isExpanded`
  });

  const switchBLCostCurrencyWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.switchBlCost.currency`
  });

  const switchBLCostWatch = useWatch({
    control,
    name: `quote.${quoteIndex}.switchBlCost.value`
  });

  // Ex-Works Price Calculation
  useEffect(() => {
    if (!basePriceWatch || !fxRateWatch) {
      return;
    }

    const exWorksPrice = calculateExWorksPrice(
      basePriceCurrencyWatch,
      currency1Watch,
      basePriceWatch,
      fxRateWatch || 1,
      finalExWorksPriceCurrencyWatch
    );
    setValue(finalExWorksPrice, exWorksPrice.toFixed(2));
  }, [
    basePriceWatch,
    fxRateWatch,
    currency1Watch,
    basePriceCurrencyWatch,
    finalExWorksPriceCurrencyWatch
  ]);

  // FOB Price Calculation
  useEffect(() => {
    if (fobManualOverrideWatch) return;

    if (!totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }

    const finalFobPrice = calculateFobPrice(
      totalLocalFreightWatch,
      totalLocalFrieghtCurrencyWatch,
      totalPortAndClearanceExpensesWatch,
      totalPortAndClearanceExpensesCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRateWatch || 1,
      currency1Watch,
      primaryUnitWatch,
      fobPriceCurrencyWatch,
      finalExWorksPriceWatch,
      finalExWorksPriceCurrencyWatch
    );
    const newValue = finalFobPrice.toFixed(2);
    const currentValue = getValues(fobPrice);

    if (newValue !== currentValue) {
      setValue(fobPrice, finalFobPrice.toFixed(2));
    } else return;
  }, [
    totalLocalFreightWatch,
    totalLocalFrieghtCurrencyWatch,
    totalPortAndClearanceExpensesWatch,
    totalPortAndClearanceExpensesCurrencyWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    finalExWorksPriceWatch,
    primaryUnitWatch,
    fobPriceCurrencyWatch,
    fxRateWatch,
    finalExWorksPriceCurrencyWatch,
    fobManualOverrideWatch
  ]);

  // CFR Price
  useEffect(() => {
    if (cfrManualOverrideWatch) return;

    if (!fxRateWatch || !totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }
    if (dirtyFields?.quote?.[quoteIndex]?.product?.[index]?.cfrPrice) {
      return;
    }
    const finalCfrPrice = calculateCFRPrice(
      totalFreightWatch,
      totalFreightCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRateWatch || 1,
      cfrPriceCurrencyWatch,
      currency1Watch,
      fobPriceWatch,
      fobPriceCurrencyWatch,
      primaryUnitWatch
    );
    const currentValue = getValues(cfrPrice);
    if (finalCfrPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(cfrPrice, finalCfrPrice.toFixed(2));
  }, [
    fobPriceCurrencyWatch,
    fobPriceWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    totalFreightWatch,
    totalFreightCurrencyWatch,
    cfrPriceCurrencyWatch,
    primaryUnitWatch,
    switchBLCostWatch,
    switchBLCostCurrencyWatch,
    cfrManualOverrideWatch
  ]);

  // CIF Price Calculation
  useEffect(() => {
    if (cifManualOverrideWatch) return;
    if (!fxRateWatch) {
      return;
    }
    const finalCIFPrice = calculateCIFPrice(
      fxRateWatch || 1,
      currency1Watch,
      cfrPriceWatch,
      cfrPriceCurrencyWatch,
      insuranceCostWatch,
      cifPriceCurrencyWatch
    );
    const currentValue = getValues(cifPrice);
    if (finalCIFPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(cifPrice, finalCIFPrice.toFixed(2));
  }, [
    insuranceCostWatch,
    cfrPriceWatch,
    cifPriceCurrencyWatch,
    cfrPriceCurrencyWatch,
    cifManualOverrideWatch
  ]);

  // Quote Selling Price Calculation
  useEffect(() => {
    const QuoteSellingPrice = calculateQuotedPrice(
      cifPriceWatch,
      cifPriceCurrencyWatch,
      additionalCostWatch,
      additionalCostCurrencyWatch,
      switchBLCostWatch,
      switchBLCostCurrencyWatch,
      financeCostWatch,
      profitMarginExpectedWatch,
      discountWatch,
      currency1Watch,
      fxRateWatch ?? 1
    );
    setValue(finalQuoteSellingPrice, QuoteSellingPrice.toFixed(2));
  }, [
    cifPriceWatch,
    cifPriceCurrencyWatch,
    additionalCostWatch,
    additionalCostCurrencyWatch,
    financeCostWatch,
    profitMarginExpectedWatch,
    discountWatch,
    switchBLCostWatch,
    switchBLCostCurrencyWatch
  ]);

  const handleProductSelection = (event: any, param: any) => {
    if (param) {
      setValue(productName, param);
    } else {
      setValue(productName, null);
    }
  };

  return (
    <div className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <div className={css.headerText}>Product {index + 1}</div>
        <div className={css.headerIcons}>
          <ToolTip title="Delete">
            <div>
              <IconNode
                src={fieldLength > 1 ? Images.deleteRed : Images.delete}
                onClick={() => {
                  fieldLength > 1 ? handleDeleteClick() : undefined;
                }}
              />
            </div>
          </ToolTip>
          <ToolTip title="Dublicate">
            <div>
              <IconNode
                src={Images.dublicateRed}
                onClick={() => handleDuplicateProduct(quoteIndex, index)}
              />
            </div>
          </ToolTip>
          <ToolTip title={isExpandedWatch ? 'Collapse' : 'Expand'}>
            <div>
              <IconNode
                src={isExpandedWatch ? Images.arrowUpBig : Images.arrowDownBig}
                onClick={() =>
                  setValue(`quote.${quoteIndex}.product.${index}.isExpanded`, !isExpandedWatch)
                }
              />
            </div>
          </ToolTip>
        </div>
      </div>
      {isExpandedWatch && (
        <>
          <div className={css.rowWrapper}>
            <Controller
              name={productName}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Product"
                    placeholder="Select/Type"
                    options={productOptions}
                    onInputChange={filterProductOptions}
                    onInputSelection={handleProductSelection}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option: any) => inputValue === option.product_name
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          product_name: inputValue,
                          product_id: null
                        });
                      }
                      return filtered;
                    }}
                    keyOption="product_name"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing2}
                    freeSolo
                  />
                );
              }}
            />
            <QuotePackageWrapper
              label="Deal Quantity"
              unitPlaceholder="Unit"
              inputPlaceholder="Deal Quantity"
              input={dealQuantity}
              unit={dealQuantityUnit}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
              currency={false}
              inputRequired
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label="MOQ"
              unitPlaceholder="Unit"
              inputPlaceholder="MOQ"
              input={moq}
              unit={moqUnit}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
              currency={false}
            />
            <Controller
              name={productPackaging}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Product Packaging"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  Base Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Base Price"
              input={basePrice}
              unit={basePriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(fobPriceManualOverride, false, { shouldDirty: false });
                setValue(cfrPriceManualOverride, false, { shouldDirty: false });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
              }}
            />
            <QuotePackageWrapper
              label={
                <span>
                  Ex-Works Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Ex-Works Price"
              input={finalExWorksPrice}
              unit={finalExWorksPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(fobPriceManualOverride, false, { shouldDirty: false });
                setValue(cfrPriceManualOverride, false, { shouldDirty: false });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
                setValue(basePrice, 0);
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  FOB Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Fob Price"
              input={fobPrice}
              unit={fobPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(cfrPriceManualOverride, false, { shouldDirty: false });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
                setValue(fobPriceManualOverride, true, {
                  shouldDirty: true
                });
                setValue(finalExWorksPrice, 0);
                setValue(basePrice, 0);
              }}
            />
            <QuotePackageWrapper
              label={
                <span>
                  CFR Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="CFR Price"
              input={cfrPrice}
              unit={cfrPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
                setValue(cfrPriceManualOverride, true, { shouldDirty: true });
                setValue(finalExWorksPrice, 0);
                setValue(basePrice, 0);
                setValue(fobPrice, 0);
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  CIF Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="CIF Price"
              input={cifPrice}
              unit={cifPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(cifPriceManualOverride, true, { shouldDirty: true });
                setValue(finalExWorksPrice, 0);
                setValue(basePrice, 0);
                setValue(fobPrice, 0);
                setValue(cfrPrice, 0);
              }}
            />
            <Controller
              name={profitMarginExpected}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Profit margin expected
                      <Typography variant="label"> ( % )</Typography>
                    </span>
                  }
                  required
                  placeholder="Enter value in percentage"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={countryOfOrigin}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Country of Origin"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { ProductFields };
