import css from './index.module.scss';
import { IconNode, Modal, Typography } from '@components/base';
import Images from '@assets/images';
import { getCommaSeperatedCurrencyAmount, sanitizeValue } from '@helpers/utils';

const ViewQuoteModal = (props: any) => {
  const { open, onClose, data, isConsolidation, primaryUnit } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Quote Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        {isConsolidation ? (
          <section className={css.modalContent}>
            {data?.products.map((product: any, index: number) => {
              return (
                <div key={index} className={css.productWrapper}>
                  <div className={css.rowWrapper}>
                    <div className={css.keyValue}>
                      <div className={css.key}>Product Name</div>
                      <div className={css.value}>{sanitizeValue(product?.product_data?.name)}</div>
                    </div>
                    <div className={css.keyValue}>
                      <div className={css.key}>Deal Quantity</div>
                      <div className={css.value}>
                        {Number(product?.quantity)} {product?.quantity_unit}
                      </div>
                    </div>
                  </div>
                  <div className={css.rowWrapper}>
                    <div className={css.keyValue}>
                      <div className={css.key}>Product Packaging</div>
                      <div className={css.value}>{sanitizeValue(product?.packaging)}</div>
                    </div>
                    <div className={css.keyValue}>
                      <div className={css.key}>MOQ</div>
                      <div className={css.value}>
                        {Number(product?.moq)} {product?.moq_unit}
                      </div>
                    </div>
                  </div>
                  <div className={css.rowWrapper}>
                    <div className={css.keyValue}>
                      <div className={css.key}>
                        {
                          <span>
                            Base Price
                            {primaryUnit && (
                              <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                            )}
                          </span>
                        }
                      </div>
                      <div className={css.value}>
                        {product?.base_price != null
                          ? getCommaSeperatedCurrencyAmount(
                              product?.base_price?.currency,
                              Number(product?.base_price?.value)
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className={css.keyValue}>
                      <div className={css.key}>
                        {
                          <span>
                            Ex-works Price
                            {primaryUnit && (
                              <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                            )}
                          </span>
                        }
                      </div>
                      <div className={css.value}>
                        {product?.ex_works_price != null
                          ? getCommaSeperatedCurrencyAmount(
                              product?.ex_works_price?.currency,
                              Number(product?.ex_works_price?.value)
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className={css.rowWrapper}>
                    <div className={css.keyValue}>
                      <div className={css.key}>
                        {
                          <span>
                            FOB Price
                            {primaryUnit && (
                              <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                            )}
                          </span>
                        }
                      </div>
                      <div className={css.value}>
                        {product?.fob_price != null
                          ? getCommaSeperatedCurrencyAmount(
                              product?.fob_price?.currency,
                              Number(product?.fob_price?.value)
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className={css.keyValue}>
                      <div className={css.key}>
                        {
                          <span>
                            CFR Price
                            {primaryUnit && (
                              <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                            )}
                          </span>
                        }
                      </div>
                      <div className={css.value}>
                        {product?.cfr_price != null
                          ? getCommaSeperatedCurrencyAmount(
                              product?.cfr_price?.currency,
                              Number(product?.cfr_price?.value)
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className={css.rowWrapper}>
                    <div className={css.keyValue}>
                      <div className={css.key}>
                        {
                          <span>
                            CIF Price
                            {primaryUnit && (
                              <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                            )}
                          </span>
                        }
                      </div>
                      <div className={css.value}>
                        {product?.cif_price != null
                          ? getCommaSeperatedCurrencyAmount(
                              product?.cif_price?.currency,
                              Number(product?.cif_price?.value)
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className={css.keyValue}>
                      <div className={css.key}>
                        {
                          <span>
                            Final Price
                            {primaryUnit && (
                              <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                            )}
                          </span>
                        }
                      </div>
                      <div className={css.value}>
                        {product?.final_price != null
                          ? getCommaSeperatedCurrencyAmount(
                              product?.final_price?.currency,
                              Number(product?.final_price?.value)
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className={css.rowWrapper}>
                    <div className={css.keyValue}>
                      <div className={css.key}>Profit margin expected </div>
                      <div className={css.value}>
                        {product?.profit_margin
                          ? ` ${sanitizeValue(product?.profit_margin)} % `
                          : '-'}
                      </div>
                    </div>
                    <div className={css.keyValue}>
                      <div className={css.key}>Country of Origin</div>
                      <div className={css.value}>{sanitizeValue(product?.origin)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Total Freight </div>
                <div className={css.value}>
                  {data?.total_freight != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.total_freight?.currency,
                        Number(data?.total_freight?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>POL</div>
                <div className={css.value}>{sanitizeValue(data?.pol.name)}</div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>POD</div>
                <div className={css.value}>{sanitizeValue(data?.pod.name)}</div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Insurance cost</div>
                <div className={css.value}>
                  {data?.insurance_cost ? ` ${sanitizeValue(data?.insurance_cost)} % ` : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      Additional Costs
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.additional_cost != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.additional_cost?.currency,
                        Number(data?.additional_cost?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Finance cost</div>
                <div className={css.value}>
                  {data?.finance_cost ? ` ${sanitizeValue(data?.finance_cost)} % ` : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Incoterms </div>
                <div className={css.value}>{sanitizeValue(data?.deal_incoterm)}</div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Discount</div>
                <div className={css.value}>
                  {data?.discount ? ` ${sanitizeValue(data?.discount)} % ` : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Total local freight</div>
                <div className={css.value}>
                  {data?.total_local_freight != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.total_local_freight?.currency,
                        Number(data?.total_local_freight?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Total port and clearance expenses</div>
                <div className={css.value}>
                  {data?.total_port_and_clearance_expenses != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.total_port_and_clearance_expenses?.currency,
                        Number(data?.total_port_and_clearance_expenses?.value)
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Finance Cost</div>
                <div className={css.value}>
                  {data?.finance_cost ? ` ${sanitizeValue(data?.finance_cost)} % ` : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Total weight</div>
                <div className={css.value}>
                  {Number(data?.total_weight)} {data?.total_weight_unit}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className={css.modalContent}>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Product Name</div>
                <div className={css.value}>{sanitizeValue(data?.product_name)}</div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Deal Quantity</div>
                <div className={css.value}>
                  {Number(data?.quantity)} {data?.quantity_unit}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Product Packaging</div>
                <div className={css.value}>{sanitizeValue(data?.packaging)}</div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>MOQ</div>
                <div className={css.value}>
                  {Number(data?.moq)} {data?.moq_unit}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      Base Price
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.base_price != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.base_price?.currency,
                        Number(data?.base_price?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      Ex Works Price
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.ex_works_price != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.ex_works_price?.currency,
                        Number(data?.ex_works_price?.value)
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Total local freight</div>
                <div className={css.value}>
                  {data?.total_local_freight != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.total_local_freight?.currency,
                        Number(data?.total_local_freight?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Total port and clearance expenses</div>
                <div className={css.value}>
                  {data?.total_port_and_clearance_expenses != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.total_port_and_clearance_expenses?.currency,
                        Number(data?.total_port_and_clearance_expenses?.value)
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Total freight per FCL </div>
                <div className={css.value}>
                  {data?.total_freight != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.total_freight?.currency,
                        Number(data?.total_freight?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Total weight in one container</div>
                <div className={css.value}>
                  {Number(data?.weight_one_container)} {data?.weight_one_container_unit}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Insurance cost</div>
                <div className={css.value}>
                  {data?.insurance_cost ? ` ${sanitizeValue(data?.insurance_cost)} % ` : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Switch BL Cost</div>
                <div className={css.value}>
                  {data?.switch_bl_cost != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.switch_bl_cost?.currency,
                        Number(data?.switch_bl_cost?.value)
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      FOB Price
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.fob_price != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.fob_price?.currency,
                        Number(data?.fob_price?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      CFR Price
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.cfr_price != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.cfr_price?.currency,
                        Number(data?.cfr_price?.value)
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      CIF Price
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.cif_price != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.cif_price?.currency,
                        Number(data?.cif_price?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      Final Price
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.final_price != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.final_price?.currency,
                        Number(data?.final_price?.value)
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>POL</div>
                <div className={css.value}>{sanitizeValue(data.pol.name)}</div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>POD</div>
                <div className={css.value}>{sanitizeValue(data.pod?.name)}</div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>
                  {
                    <span>
                      Additional costs
                      {primaryUnit && (
                        <Typography variant="label"> ( Per {primaryUnit} )</Typography>
                      )}
                    </span>
                  }
                </div>
                <div className={css.value}>
                  {data?.additional_cost != null
                    ? getCommaSeperatedCurrencyAmount(
                        data?.additional_cost?.currency,
                        Number(data?.additional_cost?.value)
                      )
                    : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Finance cost</div>
                <div className={css.value}>
                  {data?.finance_cost ? ` ${sanitizeValue(data?.finance_cost)} % ` : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Profit margin expected </div>
                <div className={css.value}>
                  {data?.profit_margin ? ` ${sanitizeValue(data?.profit_margin)} % ` : '-'}
                </div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Discount</div>
                <div className={css.value}>
                  {data?.discount ? ` ${sanitizeValue(data?.discount)} % ` : '-'}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.keyValue}>
                <div className={css.key}>Incoterms </div>
                <div className={css.value}>{sanitizeValue(data?.deal_incoterm)}</div>
              </div>
              <div className={css.keyValue}>
                <div className={css.key}>Country of Origin</div>
                <div className={css.value}>{sanitizeValue(data?.origin)}</div>
              </div>
            </div>
          </section>
        )}
      </div>
    </Modal>
  );
};

export default ViewQuoteModal;
