import { Button } from '@components/base';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  AutoComplete,
  InputDatePicker,
  PaymentTerms,
  SelectLabel,
  TextField
} from '@components/common';
import { IPaymentTermsFilter } from '@helpers/types/add-order';
import { useEffect, useMemo, useState } from 'react';
import { createFilterOptions } from '@mui/material';

const filter = createFilterOptions<any>();

const EnquiryForm = (props: any) => {
  const {
    paymentTermsOptions,
    handleBackClick,
    entityOptions,
    customerOptions,
    filterCustomerOptions,
    onFormSubmit
  } = props;
  const [filteredPaymentTermsOptions, setFilteredPaymentTermsOptions] = useState<
    IPaymentTermsFilter[]
  >([]);
  const { handleSubmit, control, setValue } = useFormContext();

  const {
    fields: paymentTermFields,
    append: paymentTermAppend,
    remove: paymentTermRemove
  } = useFieldArray({
    name: 'payment_terms',
    control
  });

  const paymentTermWatchField = useWatch({
    name: 'payment_terms',
    control
  });

  useEffect(() => {
    const selectedPaymentTerms = paymentTermWatchField?.map(
      (item: any) => item?.payment_term?.payment_term ?? ''
    );
    const filteredPaymentTermsOptionsCopy = paymentTermsOptions.filter((item: any) => {
      return !selectedPaymentTerms?.includes(item.payment_term);
    });
    setFilteredPaymentTermsOptions(filteredPaymentTermsOptionsCopy);
  }, [paymentTermWatchField]);

  const handleAddPaymentTermField = () => {
    paymentTermAppend({
      payment_term: null,
      percentage: '',
      days: ''
    });
  };

  const tomorrow = useMemo(() => {
    const date = new Date();
    // If you want to disallow selecting today's date, set tomorrow = today + 1
    date.setDate(date.getDate());
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <div className={css.container}>
        <div className={css.rowWrapper}></div>
        <div className={css.rowWrapper}>
          <Controller
            name="entity"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                autoFocus
                required
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.entity_id}
                options={entityOptions}
                label="Entity"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="customer"
            control={control}
            render={({ field, fieldState }) => {
              const onCustomerSelection = (event: any, param: any) => {
                if (param) {
                  setValue('customer', param);
                } else {
                  setValue('customer', param);
                }
              };
              return (
                <AutoComplete
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  disableClearable
                  options={customerOptions}
                  keyOption="customer_name"
                  onInputChange={filterCustomerOptions}
                  onInputSelection={onCustomerSelection}
                  label="Customer"
                  placeholder="Select/Type"
                  required={true}
                />
              );
            }}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="priceValidUntill"
            control={control}
            render={({ field, fieldState }) => (
              <InputDatePicker
                {...field}
                label="Price Validity Until"
                value={field.value}
                dayDisabled={[{ before: tomorrow }]}
                onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.fieldSpacing}
              />
            )}
          />
          <Controller
            name="expectedDeliverySchedule"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Expected Delivery Schedule"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <PaymentTerms
            paymentTermFields={paymentTermFields}
            paymentTermRemove={paymentTermRemove}
            filteredPaymentTermsOptions={
              filteredPaymentTermsOptions?.length
                ? filteredPaymentTermsOptions
                : paymentTermsOptions
            }
            handleAddPaymentTermField={handleAddPaymentTermField}
            paymentTermsOptions={paymentTermsOptions}
          />
        </div>
      </div>
      <div className={css.actionButtons}>
        <Button variant="text" onClick={handleBackClick}>
          Back
        </Button>
        <Button type="submit">Save Quote</Button>
      </div>
    </form>
  );
};

export default EnquiryForm;
