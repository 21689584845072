import css from './index.module.scss';
import { Button, CheckboxLabel, IconNode, ToolTip } from '@components/base';
import { getFormattedTime, sanitizeValue } from '@helpers/utils';
import { endOfDay, format } from 'date-fns';
import Images from '@assets/images';
import ViewQuoteModal from '../view-quote';
import AddEditQuote from '../add-edit-quote';
import { useEffect } from 'react';
import AccessWrapper from '@authorization/access-wrapper';

const NonConsolidationDetail = (props: any) => {
  const { actions } = props;
  const productData = props.productData;
  const { quoteDetail, setQuoteDetail, fetchEnquiryDetail, showQuotes, setShowQuotes } = props;
  const {
    openViewQuoteModal,
    detailModalData,
    openAddEditQuoteModal,
    isConsolidation,
    addEditQuoteModalData,
    addEditQuoteIsEdit,
    isFcl,
    isMtt,
    addEditQuoteIsNew,
    selectedQuotes,
    showDisclaimer,
    primaryUnit
  } = quoteDetail;

  useEffect(() => {
    if (productData?.length > 0) {
      const allExpanded = productData.reduce((acc: any, _item: any, index: number) => {
        acc[index] = true;
        return acc;
      }, {});
      setShowQuotes(allExpanded);
    }
  }, [productData, setShowQuotes]);

  const getFormattedDate = (date: any) => {
    if (date) {
      return format(endOfDay(new Date(date).getTime()), 'do LLL');
    } else {
      return null;
    }
  };

  const closeViewQuoteModal = () => {
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        openViewQuoteModal: false
      };
    });
  };

  const isDetailSelected = (detail: any) => {
    return selectedQuotes.some((d: any) => d.quote_id === detail.quote_id);
  };

  const handleDetailCheck = (detail: any) => {
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        selectedQuotes: isDetailSelected(detail)
          ? prevState.selectedQuotes.filter((d: any) => d.quote_id !== detail.quote_id)
          : [...prevState.selectedQuotes, detail]
      };
    });
  };

  const toggleQuotesVisibility = (productIndex: number) => {
    setShowQuotes((prev: any) => ({
      ...prev,
      [productIndex]: !prev[productIndex]
    }));
  };

  const handleAddQuoteClick = (item: any) => {
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        openAddEditQuoteModal: true,
        addEditQuoteModalData: {
          ...item.product
        },
        addEditQuoteIsEdit: false,
        addEditQuoteIsNew: true
      };
    });
  };

  return (
    <AccessWrapper show={actions?.read}>
      <main className={css.mainWrapper}>
        {(productData || []).map((item: any, index: number) => {
          return (
            <div key={index} className={css.productWrapper}>
              <div className={css.header} onClick={() => toggleQuotesVisibility(index)}>
                <div className={css.title}>{item.product.product_name}</div>
                <AccessWrapper show={actions?.create}>
                  <div className={css.buttonWrapper}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleAddQuoteClick(item);
                      }}>
                      Add Quote
                    </Button>
                    <Button variant="outlined-secondary">
                      {showQuotes[index] ? 'Hide Quotes' : 'Show Quotes'}
                    </Button>
                  </div>
                </AccessWrapper>
              </div>
              {showQuotes[index] &&
                (item.details || []).map((detail: any, detailIndex: number) => {
                  return (
                    <div key={detailIndex} className={css.content}>
                      <div className={css.row}>
                        <div className={css.checkBoxKeyValue}>
                          <div className={css.checkBox}>
                            <CheckboxLabel
                              label=""
                              checked={isDetailSelected(detail)}
                              onChange={() => handleDetailCheck(detail)}
                              className={css.checkboxLabel}
                            />
                            <div className={css.checkedKeyValue}>
                              <div className={css.key}>Quantity</div>
                              <div className={css.value}>
                                {Number(detail?.quantity)} {detail?.quantity_unit}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>POL</div>
                          <div className={css.value}>{sanitizeValue(detail?.pol?.name)}</div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>POD</div>
                          <div className={css.value}>{sanitizeValue(detail?.pod?.name)}</div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>Inco Term</div>
                          <div className={css.value}>{sanitizeValue(detail?.deal_incoterm)}</div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>Quoted Amount</div>
                          <div className={css.value}>
                            {detail?.final_price
                              ? `${detail?.final_price?.currency} ${sanitizeValue(
                                  detail?.final_price?.value
                                )}`
                              : '-'}
                          </div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>Profit Margin</div>
                          <div className={css.value}>
                            {`${sanitizeValue(detail?.profit_margin)} %`}
                          </div>
                        </div>
                        <div className={css.rowIcons}>
                          {getFormattedDate(detail.created_at)}{' '}
                          {getFormattedTime(detail.created_at)}
                          <ToolTip title="View Quote" placement="right">
                            <div>
                              <IconNode
                                src={Images.eyeRed}
                                alt="View Quote"
                                className={css.editIcon}
                                onClick={() => {
                                  setQuoteDetail((prevState: any) => {
                                    return {
                                      ...prevState,
                                      openViewQuoteModal: true,
                                      detailModalData: {
                                        ...detail,
                                        ...item.product
                                      }
                                    };
                                  });
                                }}
                              />
                            </div>
                          </ToolTip>
                          <AccessWrapper show={actions?.create}>
                            <ToolTip title="Dublicate Quote" placement="right">
                              <div>
                                <IconNode
                                  src={Images.dublicateRed}
                                  alt="Dublicate icon"
                                  className={css.editIcon}
                                  onClick={() => {
                                    setQuoteDetail((prevState: any) => {
                                      return {
                                        ...prevState,
                                        openAddEditQuoteModal: true,
                                        addEditQuoteModalData: {
                                          ...detail,
                                          ...item.product
                                        },
                                        addEditQuoteIsEdit: false
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </ToolTip>
                          </AccessWrapper>
                          <AccessWrapper show={actions?.update}>
                            <ToolTip title="Edit Quote" placement="right">
                              <div>
                                <IconNode
                                  src={Images.editRed}
                                  alt="Edit icon"
                                  className={css.editIcon}
                                  onClick={() => {
                                    setQuoteDetail((prevState: any) => {
                                      return {
                                        ...prevState,
                                        openAddEditQuoteModal: true,
                                        addEditQuoteModalData: {
                                          ...detail,
                                          ...item.product
                                        },
                                        addEditQuoteIsEdit: true
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </ToolTip>
                          </AccessWrapper>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
        <AccessWrapper show={actions?.read}>
          {openViewQuoteModal && (
            <ViewQuoteModal
              open={openViewQuoteModal}
              onClose={closeViewQuoteModal}
              data={detailModalData}
              isConsolidation={isConsolidation}
              primaryUnit={primaryUnit?.label}
            />
          )}
        </AccessWrapper>
        <AccessWrapper show={actions?.create}>
          {openAddEditQuoteModal && (
            <AddEditQuote
              open={openAddEditQuoteModal}
              onClose={() => {
                setQuoteDetail((prevState: any) => {
                  return {
                    ...prevState,
                    showDisclaimer: true
                  };
                });
              }}
              isConsolidation={isConsolidation}
              isFcl={isFcl}
              isMtt={isMtt}
              data={addEditQuoteModalData}
              isEdit={addEditQuoteIsEdit}
              isNew={addEditQuoteIsNew}
              quoteDetail={quoteDetail}
              onSubmitClose={() => {
                setQuoteDetail((prevState: any) => {
                  return {
                    ...prevState,
                    openAddEditQuoteModal: false,
                    addEditQuoteIsEdit: false,
                    addEditQuoteIsNew: false
                  };
                });
              }}
              fetchEnquiryDetail={fetchEnquiryDetail}
            />
          )}
        </AccessWrapper>
      </main>
    </AccessWrapper>
  );
};

export default NonConsolidationDetail;
