import css from './index.module.scss';
import { Button, CheckboxLabel, IconNode, ToolTip, Typography } from '@components/base';
import Images from '@assets/images';
import { getFormattedTime, sanitizeValue } from '@helpers/utils';
import { endOfDay, format } from 'date-fns';
import ViewQuoteModal from '../view-quote';
import AddEditQuote from '../add-edit-quote';
import { useEffect } from 'react';

const ConsolidationDetail = (props: any) => {
  const {
    productData,
    quoteDetail,
    setQuoteDetail,
    fetchEnquiryDetail,
    showQuotes,
    setShowQuotes
  } = props;
  const {
    openViewQuoteModal,
    detailModalData,
    isConsolidation,
    openAddEditQuoteModal,
    addEditQuoteModalData,
    addEditQuoteIsEdit,
    isFcl,
    isMtt,
    selectedQuotes,
    primaryUnit
  } = quoteDetail;

  useEffect(() => {
    if (productData?.length > 0) {
      const allExpanded = productData.reduce((acc: any, _item: any, index: number) => {
        acc[index] = true;
        return acc;
      }, {});
      setShowQuotes(allExpanded);
    }
  }, [productData, setShowQuotes]);

  const toggleQuotesVisibility = (productIndex: number) => {
    setShowQuotes((prev: any) => ({
      ...prev,
      [productIndex]: !prev[productIndex]
    }));
  };

  const getFormattedDate = (date: any) => {
    if (date) {
      return format(endOfDay(new Date(date).getTime()), 'do LLL');
    } else {
      return null;
    }
  };

  const closeViewQuoteModal = () => {
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        openViewQuoteModal: false
      };
    });
  };

  const isEnquirySelected = (quote: any) => {
    return selectedQuotes.some(
      (q: any) => q.consolidation_details_id === quote.consolidation_details_id
    );
  };

  const handleEnquiryCheck = (quote: any) => {
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        selectedQuotes: isEnquirySelected(quote)
          ? prevState.selectedQuotes.filter(
              (q: any) => q.consolidation_details_id !== quote.consolidation_details_id
            )
          : [...prevState.selectedQuotes, quote]
      };
    });
  };

  const handleAddQuoteClick = (item: any) => {
    const quote = item.quotes?.[0];
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        openAddEditQuoteModal: true,
        addEditQuoteModalData: {
          products: (quote.products || []).map((product: any) => {
            return {
              product_data: product.product_data
            };
          })
        },
        addEditQuoteIsEdit: false
      };
    });
  };

  return (
    <main className={css.mainWrapper}>
      {productData.map((item: any, index: number) => {
        return (
          <div key={index} className={css.productWrapper}>
            <section className={css.header} onClick={() => toggleQuotesVisibility(index)}>
              <div className={css.title}>
                <Typography variant="label">Products</Typography>
                <div className={css.products}>
                  {item.quotes[0].products.map((product: any, productIndex: number) => {
                    return (
                      <div className={css.productNameWrapper} key={productIndex}>
                        <Typography variant="h5">
                          {product.product_data?.name}
                          {productIndex < item.quotes[0].products.length - 1 && ', '}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={css.buttonWrapper}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleAddQuoteClick(item);
                  }}>
                  Add Quote
                </Button>
                <Button variant="outlined-secondary">
                  {showQuotes[index] ? 'Hide Quotes' : 'Show Quotes'}
                </Button>
              </div>
            </section>
            {showQuotes[index] &&
              item.quotes.map((quote: any, quoteIndex: number) => {
                return (
                  <div key={quoteIndex} className={css.quoteWrapper}>
                    <section className={css.content}>
                      <div className={css.contentHeader}>
                        <div className={css.checkBoxKeyValue}>
                          <div className={css.checkBox}>
                            <CheckboxLabel
                              label=""
                              checked={isEnquirySelected(quote)}
                              onChange={() => handleEnquiryCheck(quote)}
                              className={css.checkboxLabel}
                            />
                            <div className={css.checkedKeyValue}>
                              <div className={css.key}>Total Weight</div>
                              <div className={css.value}>
                                {Number(quote.total_weight)} {quote.total_weight_unit}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>POL</div>
                          <div className={css.value}>{quote?.pol?.name}</div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>POD</div>
                          <div className={css.value}>{quote?.pod?.name}</div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>Inco Term</div>
                          <div className={css.value}>{quote?.deal_incoterm}</div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>Total Local Freight</div>
                          <div className={css.value}>
                            {quote?.total_local_freight?.currency}{' '}
                            {Number(quote?.total_local_freight?.value)}
                          </div>
                        </div>
                        <div className={css.keyValue}>
                          <div className={css.key}>Total Port and Clearance Expense</div>
                          <div className={css.value}>
                            {quote?.total_port_and_clearance_expenses?.currency}{' '}
                            {Number(quote?.total_port_and_clearance_expenses?.value)}
                          </div>
                        </div>
                        <div className={css.contentHeaderIcons}>
                          <ToolTip title="View Quote" placement="right">
                            <div>
                              <IconNode
                                src={Images.eyeRed}
                                alt="View Quote"
                                className={css.editIcon}
                                onClick={() => {
                                  setQuoteDetail((prevState: any) => {
                                    return {
                                      ...prevState,
                                      openViewQuoteModal: true,
                                      detailModalData: {
                                        ...quote
                                      }
                                    };
                                  });
                                }}
                              />
                            </div>
                          </ToolTip>
                          <ToolTip title="Dublicate Quote" placement="right">
                            <div>
                              <IconNode
                                src={Images.dublicateRed}
                                alt="Dublicate Quote icon"
                                className={css.editIcon}
                                onClick={() => {
                                  setQuoteDetail((prevState: any) => {
                                    return {
                                      ...prevState,
                                      openAddEditQuoteModal: true,
                                      addEditQuoteModalData: {
                                        ...quote
                                      },
                                      addEditQuoteIsEdit: false
                                    };
                                  });
                                }}
                              />
                            </div>
                          </ToolTip>
                          <ToolTip title="Edit Quote" placement="right">
                            <div>
                              <IconNode
                                src={Images.editRed}
                                alt="Edit Quote"
                                className={css.editIcon}
                                onClick={() => {
                                  setQuoteDetail((prevState: any) => {
                                    return {
                                      ...prevState,
                                      openAddEditQuoteModal: true,
                                      addEditQuoteModalData: {
                                        ...quote
                                      },
                                      addEditQuoteIsEdit: true
                                    };
                                  });
                                }}
                              />
                            </div>
                          </ToolTip>
                        </div>
                      </div>
                      {quote.products.map((product: any, productIndex: number) => {
                        return (
                          <div key={productIndex} className={css.contentBody}>
                            <div className={css.contentBodyItem}>
                              <div className={css.keyValue}>
                                <div className={css.key}>Product Name</div>
                                <div className={css.value}>{product?.product_data?.name}</div>
                              </div>
                              <div className={css.keyValue}>
                                <div className={css.key}>Quantity</div>
                                <div className={css.value}>
                                  {Number(product?.quantity)} {product?.quantity_unit}
                                </div>
                              </div>
                              <div className={css.keyValue}>
                                <div className={css.key}>Basic Price</div>
                                <div className={css.value}>
                                  {product?.base_price?.currency}{' '}
                                  {Number(product?.base_price?.value)}
                                </div>
                              </div>
                              <div className={css.keyValue}>
                                <div className={css.key}>Ex Works Price</div>
                                <div className={css.value}>
                                  {product?.ex_works_price?.currency}{' '}
                                  {Number(product?.ex_works_price?.value)}
                                </div>
                              </div>
                              <div className={css.keyValue}>
                                <div className={css.key}>Fob Price</div>
                                <div className={css.value}>
                                  {product?.fob_price?.currency} {Number(product?.fob_price?.value)}
                                </div>
                              </div>
                              <div className={css.keyValue}>
                                <div className={css.key}>Profit Margin</div>
                                <div className={css.value}>{`${sanitizeValue(
                                  product?.profit_margin
                                )} %`}</div>
                              </div>
                              <div className={css.keyValue}>
                                <div className={css.key}>Product Packaging</div>
                                <div className={css.value}>{sanitizeValue(product?.packaging)}</div>
                              </div>
                            </div>
                            <div className={css.contentBodyIcons}>
                              {getFormattedDate(product.created_at)}{' '}
                              {getFormattedTime(product.created_at)}
                            </div>
                          </div>
                        );
                      })}
                    </section>
                  </div>
                );
              })}
          </div>
        );
      })}
      {openViewQuoteModal && (
        <ViewQuoteModal
          open={openViewQuoteModal}
          onClose={closeViewQuoteModal}
          data={detailModalData}
          isConsolidation={isConsolidation}
          primaryUnit={primaryUnit?.label}
        />
      )}
      {openAddEditQuoteModal && (
        <AddEditQuote
          open={openAddEditQuoteModal}
          onClose={() => {
            setQuoteDetail((prevState: any) => {
              return {
                ...prevState,
                openAddEditQuoteModal: false
              };
            });
          }}
          onSubmitClose={() => {
            setQuoteDetail((prevState: any) => {
              return {
                ...prevState,
                openAddEditQuoteModal: false,
                addEditQuoteIsEdit: false,
                addEditQuoteIsNew: false
              };
            });
          }}
          isConsolidation={isConsolidation}
          isFcl={isFcl}
          isMtt={isMtt}
          data={addEditQuoteModalData}
          isEdit={addEditQuoteIsEdit}
          quoteDetail={quoteDetail}
          fetchEnquiryDetail={fetchEnquiryDetail}
        />
      )}
    </main>
  );
};

export default ConsolidationDetail;
