import { FormProvider, useForm } from 'react-hook-form';
import css from './index.module.scss';
import { useEffect, useState } from 'react';
import { Drawer, IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import NonConsolidatedQuote from './components/non-consolidate';
import ConsolidatedQuote from './components/consolidate';
import { CURRENCY, PORT_FIELD_TYPES } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { addQuote, fetchQuoteProductList, updateQuote } from '@services/quote.service';
import { fetchPortList } from '@services/util.service';
import { useParams } from 'react-router-dom';
import QuoteCostCalculator from './components/cost-calculator';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  consolidationAddEditQuoteValidation,
  nonConsolidationAddEditQuoteValidation
} from '@helpers/yup/add-quote.schema';

interface AddEditQuoteProps {
  open: boolean;
  onClose: () => void;
  isConsolidation: boolean;
  data?: any;
  isEdit?: boolean;
  quoteDetail: any;
  fetchEnquiryDetail?: () => void;
  isFcl: boolean;
  isMtt: boolean;
  enquiryId?: string;
  isNew?: boolean;
  onSubmitClose: any;
}

const AddEditQuote = (props: AddEditQuoteProps) => {
  const params = useParams();
  const {
    open,
    onClose,
    isConsolidation,
    data,
    isEdit,
    quoteDetail,
    fetchEnquiryDetail,
    isFcl,
    isMtt,
    isNew = false,
    onSubmitClose
  } = props;

  const {
    selectedCurrency,
    selectedWeightSystemChildOptions,
    currency1,
    currency2,
    fxRate,
    primaryUnit
  } = quoteDetail;

  const onFormSubmit = async (enquiryQuote: any) => {
    const { quote } = enquiryQuote;
    const reqBody: any = {
      product_details: {
        name: quote.productName?.product_name,
        id: quote.productName?.product_id
      },
      origin: quote.countryOfOrigin,
      quantity: quote.dealQuantity,
      quantity_unit: quote.dealQuantityUnit?.value,
      packaging: quote.productPackaging,
      moq: quote.moq,
      moq_unit: quote.moqUnit?.value,
      weight_one_container: quote.totalWeight,
      weight_one_container_unit: quote.totalWeightUnit?.value,
      insurance_cost: quote.insuranceCost,
      finance_cost: quote.financeCost,
      profit_margin: quote.profitMarginExpected,
      discount: quote.discount,
      deal_incoterm: quote.incoTerms?.value,
      deal_status: null,
      pol: quote.portOfLoading?.value,
      pod: quote.portOfDischarge?.value,
      // enquiry: params.id,
      costs: {
        base_price: {
          value: quote.base_price?.value,
          currency: quote.base_price?.currency?.value,
          cost_id: isEdit || isNew ? quote.base_price?.cost_id : null
        },
        ex_works_price: {
          value: quote.final_exWorks_price?.value,
          currency: quote.final_exWorks_price?.currency?.value ?? 'USD',
          cost_id: isEdit || isNew ? quote.final_exWorks_price?.cost_id : null
        },
        fob_price: {
          value: quote.fob_price?.value,
          currency: quote.fob_price?.currency?.value,
          cost_id: isEdit || isNew ? quote.fob_price?.cost_id : null
        },
        cif_price: {
          value: quote.cif_price?.value,
          currency: quote.cif_price?.currency?.value,
          cost_id: isEdit || isNew ? quote.cif_price?.cost_id : null
        },
        cfr_price: {
          value: quote.cfr_price?.value,
          currency: quote.cfr_price?.currency?.value,
          cost_id: isEdit || isNew ? quote.cfr_price?.cost_id : null
        },
        final_price: {
          value: quote.finalQuote_selling_price?.value,
          currency: quote.finalQuote_selling_price?.currency?.value ?? 'USD',
          cost_id: isEdit || isNew ? quote.finalQuote_selling_price?.cost_id : null
        },
        total_local_freight: {
          value: quote.totalLocalFreight?.value,
          currency: quote.totalLocalFreight?.currency?.value,
          cost_id: isEdit || isNew ? quote.totalLocalFreight?.cost_id : null
        },
        total_port_and_clearance_expenses: {
          value: quote.totalPortAndClearanceExpenses?.value,
          currency: quote.totalPortAndClearanceExpenses?.currency?.value,
          cost_id: isEdit || isNew ? quote.totalPortAndClearanceExpenses?.cost_id : null
        },
        total_freight: {
          value: quote.totalFreight?.value,
          currency: quote.totalFreight?.currency?.value,
          cost_id: isEdit || isNew ? quote.totalFreight?.cost_id : null
        },
        additional_cost: {
          value: quote.additionalCost?.value,
          currency: quote.additionalCost?.currency?.value,
          cost_id: isEdit || isNew ? quote.additionalCost?.cost_id : null
        },
        switch_bl_cost: {
          value: quote.switchBlCost?.value,
          currency: quote.switchBlCost?.currency?.value,
          cost_id: isEdit || isNew ? quote.switchBlCost?.cost_id : null
        }
      }
    };
    if (isEdit) {
      reqBody.quote_id = data?.quote_id;
      reqBody.product_details = quote.productName;
    } else {
      reqBody.product_details = {
        name: quote.productName?.product_name,
        id: quote.productName?.product_id
      };
    }
    const response = isEdit
      ? await updateQuote(props?.enquiryId ?? params.id, reqBody)
      : await addQuote(props?.enquiryId ?? params.id, reqBody);

    if (response?.success) {
      notify({
        message: response?.data?.message ?? 'Enquiry Updated Successfully',
        severity: 'success'
      });
      onSubmitClose();
      fetchEnquiryDetail && fetchEnquiryDetail();
    } else if (response?.error) {
      notify({
        message: 'Something Went Wrong', //response?.error
        severity: 'error'
      });
    }
  };

  const [quoteState, setQuoteState] = useState({
    isLoading: false,
    productOptions: [],
    portOptions: [],
    portOfLoadingOptions: [],
    portOfDischargeOptions: []
  });

  const { isLoading, productOptions, portOptions, portOfLoadingOptions, portOfDischargeOptions } =
    quoteState;

  const nonConsolidationForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(nonConsolidationAddEditQuoteValidation),
    defaultValues: {
      quote: {
        productName: null,
        productPackaging: null,
        dealQuantity: null,
        dealQuantityUnit: null,
        base_price: {
          value: null,
          currency: null,
          cost_id: null
        },
        fob_price: {
          value: null,
          currency: null,
          cost_id: null,
          manualOverride: false
        },
        cif_price: {
          value: null,
          currency: null,
          cost_id: null,
          manualOverride: false
        },
        cfr_price: {
          value: null,
          currency: null,
          cost_id: null,
          manualOverride: false
        },
        countryOfOrigin: null,
        moq: null,
        moqUnit: null,
        totalWeight: null,
        totalWeightUnit: null,
        totalLocalFreight: {
          value: null,
          currency: null,
          cost_id: null
        },
        totalPortAndClearanceExpenses: {
          value: null,
          currency: null,
          cost_id: null
        },
        totalFreight: {
          value: null,
          currency: null,
          cost_id: null
        },
        portOfLoading: null,
        portOfDischarge: null,
        insuranceCost: 0.1,
        additionalCost: {
          value: null,
          currency: null,
          cost_id: null
        },
        financeCost: null,
        profitMarginExpected: null,
        discount: null,
        incoTerms: null,
        switchBlCost: {
          value: null,
          currency: null,
          cost_id: null
        },
        final_exWorks_price: {
          value: null,
          currency: null,
          cost_id: null
        },
        finalQuote_selling_price: {
          value: null,
          currency: null,
          cost_id: null
        }
      }
    }
  });

  const consolidationForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(consolidationAddEditQuoteValidation),
    defaultValues: {
      quote: {
        product: [
          {
            isExpanded: true,
            countryOfOrigin: null,
            productName: null,
            productPackaging: null,
            dealQuantity: null,
            dealQuantityUnit: null,
            base_price: {
              value: null,
              currency: null,
              cost_id: null
            },
            fob_price: {
              value: null,
              currency: null,
              cost_id: null,
              manualOverride: false
            },
            cif_price: {
              value: null,
              currency: null,
              cost_id: null,
              manualOverride: false
            },
            cfr_price: {
              value: null,
              currency: null,
              cost_id: null,
              manualOverride: false
            },
            profitMarginExpected: null,
            final_exWorks_price: {
              value: null,
              currency: null,
              cost_id: null
            },
            finalQuote_selling_price: {
              value: null,
              currency: null,
              cost_id: null
            },
            moq: null,
            moqUnit: null
          }
        ],
        totalWeight: null,
        totalWeightUnit: null,
        totalLocalFreight: {
          value: null,
          currency: null,
          cost_id: null
        },
        totalPortAndClearanceExpenses: {
          value: null,
          currency: null,
          cost_id: null
        },
        totalFreight: {
          value: null,
          currency: null,
          cost_id: null
        },
        portOfLoading: null,
        portOfDischarge: null,
        insuranceCost: 0.1,
        additionalCost: {
          value: null,
          currency: null,
          cost_id: null
        },
        switchBlCost: {
          value: null,
          currency: null,
          cost_id: null
        },
        financeCost: null,
        discount: null,
        incoTerms: null
      }
    }
  });

  const filterProductOptions = async (event: any, param?: any) => {
    if (param) {
      const response = await fetchQuoteProductList({ product_name: param });
      if (response?.success) {
        setQuoteState((prevState: any) => ({
          ...prevState,
          productOptions: response?.data?.results ?? []
        }));
      } else {
        notify({
          severity: 'error',
          message: response?.message
        });
      }
    } else {
      const response = await fetchQuoteProductList();
      if (response?.success) {
        setQuoteState((prevState: any) => ({
          ...prevState,
          productOptions: response?.data?.results ?? []
        }));
      } else {
        notify({
          severity: 'error',
          message: response?.message
        });
      }
    }
  };

  const filterPortList = async () => {
    const data = await fetchPortList({ port_type: 'seaport' });
    if (data?.success) {
      setQuoteState((prevState: any) => ({
        ...prevState,
        portOptions: data.data.filter_options,
        portOfLoadingOptions: data.data.filter_options,
        portOfDischargeOptions: data.data.filter_options
      }));
    }
  };

  const filterPorts = async (event?: any, param?: any, fieldType?: string) => {
    if (param) {
      const portList = await fetchPortList({ search_text: param, port_type: 'seaport' });
      if (portList?.success) {
        fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
          ? setQuoteState((prevState: any) => ({
              ...prevState,
              portOfLoadingOptions: portList.data.filter_options
            }))
          : setQuoteState((prevState: any) => ({
              ...prevState,
              portOfDischargeOptions: portList.data.filter_options
            }));
      } else {
        notify({
          message: portList.error ?? 'Internal Server Error',
          severity: 'error'
        });
      }
    } else {
      fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
        ? setQuoteState((prevState: any) => ({
            ...prevState,
            portOfLoadingOptions: portOptions
          }))
        : setQuoteState((prevState: any) => ({
            ...prevState,
            portOfDischargeOptions: portOptions
          }));
    }
  };

  useEffect(() => {
    filterPortList();
    filterProductOptions(null, '');
  }, []);

  useEffect(() => {
    if (data) {
      if (isConsolidation) {
        consolidationForm.reset({
          quote: {
            product: (data?.products || []).map((item: any) => ({
              productName: item.product_data
                ? {
                    product_name: item.product_data?.name,
                    product_id: item.product_data?.id
                  }
                : null,
              productPackaging: item.packaging,
              dealQuantity: item.quantity,
              dealQuantityUnit: item.quantity_unit
                ? selectedWeightSystemChildOptions?.find(
                    (unit: any) => unit.value === item.quantity_unit
                  )
                : primaryUnit,
              base_price: {
                value: item.base_price?.value,
                currency: item.base_price
                  ? CURRENCY?.find((currency) => currency.value === item.base_price?.currency)
                  : currency1,
                cost_id: item.base_price?.cost_id
              },
              final_exWorks_price: {
                value: item.ex_works_price?.value,
                currency: item.ex_works_price
                  ? CURRENCY?.find((currency) => currency.value === item.ex_works_price?.currency)
                  : currency1,
                cost_id: item.ex_works_price?.cost_id
              },
              fob_price: {
                value: item.fob_price?.value,
                currency: item.fob_price
                  ? CURRENCY?.find((currency) => currency.value === item.fob_price?.currency)
                  : currency1,
                cost_id: item.fob_price?.cost_id
              },
              cif_price: {
                value: item.cif_price?.value,
                currency: item.cif_price
                  ? CURRENCY?.find((currency) => currency.value === item.cif_price?.currency)
                  : currency1,
                cost_id: item.cif_price?.cost_id
              },
              cfr_price: {
                value: item.cfr_price?.value,
                currency: item.cfr_price
                  ? CURRENCY?.find((currency) => currency.value === item.cfr_price?.currency)
                  : currency1,
                cost_id: item.cfr_price?.cost_id
              },
              finalQuote_selling_price: {
                value: item.final_price?.value,
                currency: item.final_price
                  ? CURRENCY?.find((currency) => currency.value === item.final_price?.currency)
                  : currency1,
                cost_id: item.final_price?.cost_id
              },
              profitMarginExpected: item.profit_margin,
              moq: item.moq,
              moqUnit: item.moq_unit
                ? selectedWeightSystemChildOptions?.find(
                    (items: any) => items.value === item.moq_unit
                  )
                : primaryUnit,
              countryOfOrigin: item.origin
            })),
            totalWeight: data.total_weight,
            totalWeightUnit: data.total_weight_unit
              ? selectedWeightSystemChildOptions?.find(
                  (item: any) => item.value === data.total_weight_unit
                )
              : primaryUnit,
            totalLocalFreight: {
              value: data.total_local_freight?.value,
              currency: data.total_local_freight
                ? CURRENCY?.find((item) => item.value === data.total_local_freight?.currency)
                : currency1,
              cost_id: data.total_local_freight?.cost_id
            },
            totalPortAndClearanceExpenses: {
              value: data.total_port_and_clearance_expenses?.value,
              currency: data.total_port_and_clearance_expenses
                ? CURRENCY?.find(
                    (item) => item.value === data.total_port_and_clearance_expenses?.currency
                  )
                : currency1,
              cost_id: data.total_port_and_clearance_expenses?.cost_id
            },
            totalFreight: {
              value: data.total_freight?.value,
              currency: data.total_freight
                ? CURRENCY?.find((item) => item.value === data.total_freight?.currency)
                : currency1,
              cost_id: data.total_freight?.cost_id
            },
            switchBlCost: {
              value: data.switch_bl_cost?.value,
              currency: data.switch_bl_cost
                ? CURRENCY?.find((item) => item.value === data.switch_bl_cost?.currency)
                : currency1,
              cost_id: data.switch_bl_cost?.cost_id
            },
            portOfLoading: data.pol,
            portOfDischarge: data.pod,
            insuranceCost: data.insurance_cost ?? 0.1,
            additionalCost: {
              value: data.additional_cost?.value,
              currency: data.additional_cost
                ? CURRENCY?.find((item) => item.value === data.additional_cost?.currency)
                : currency1,
              cost_id: data.additional_cost?.cost_id
            },
            financeCost: data.finance_cost,
            discount: data.discount,
            incoTerms: data.deal_incoterm
              ? {
                  label: data.deal_incoterm,
                  value: data.deal_incoterm
                }
              : null
          }
        });
      } else {
        const productName = data?.product_data ?? {
          product_name: data.product_name,
          product_id: data.product_id
        };
        nonConsolidationForm.reset({
          quote: {
            productName: productName,
            productPackaging: data.packaging,
            dealQuantity: data?.quantity,
            dealQuantityUnit: data?.quantity_unit
              ? selectedWeightSystemChildOptions?.find(
                  (item: any) => item.value === data?.quantity_unit
                )
              : primaryUnit,
            base_price: {
              value: data.base_price?.value,
              currency: data.base_price
                ? CURRENCY?.find((item) => item.value === data.base_price?.currency)
                : currency1,
              cost_id: data.base_price?.cost_id
            },
            final_exWorks_price: {
              value: data.ex_works_price?.value,
              currency: data.ex_works_price
                ? CURRENCY?.find((item) => item.value === data.ex_works_price?.currency)
                : currency1,
              cost_id: data.ex_works_price?.cost_id
            },
            fob_price: {
              value: data?.fob_price?.value,
              currency: data?.fob_price
                ? CURRENCY?.find((item) => item.value === data?.fob_price?.currency)
                : currency1,
              cost_id: data.fob_price?.cost_id
            },
            cif_price: {
              value: data?.cif_price?.value,
              currency: data.cif_price
                ? CURRENCY?.find((item) => item.value === data?.cif_price?.currency)
                : currency1,
              cost_id: data.cif_price?.cost_id
            },
            cfr_price: {
              value: data?.cfr_price?.value,
              currency: data.cfr_price
                ? CURRENCY?.find((item) => item.value === data?.cfr_price?.currency)
                : currency1,
              cost_id: data.cfr_price?.cost_id
            },
            finalQuote_selling_price: {
              value: data?.final_price?.value,
              currency: data.final_price
                ? CURRENCY?.find((item) => item.value === data?.final_price?.currency)
                : currency1,
              cost_id: data.final_price?.cost_id
            },
            moq: data.moq,
            moqUnit: data.moq_unit
              ? selectedWeightSystemChildOptions?.find((item: any) => item.value === data.moq_unit)
              : primaryUnit,
            countryOfOrigin: data.origin,
            totalWeight: data.weight_one_container,
            totalWeightUnit: data.weight_one_container_unit
              ? selectedWeightSystemChildOptions?.find(
                  (item: any) => item.value === data.weight_one_container_unit
                )
              : primaryUnit,
            totalLocalFreight: {
              value: data.total_local_freight?.value,
              currency: data.total_local_freight
                ? CURRENCY?.find((item) => item.value === data.total_local_freight?.currency)
                : currency1,
              cost_id: data.total_local_freight?.cost_id
            },
            totalPortAndClearanceExpenses: {
              value: data.total_port_and_clearance_expenses?.value,
              currency: data.total_port_and_clearance_expenses
                ? CURRENCY?.find(
                    (item) => item.value === data.total_port_and_clearance_expenses?.currency
                  )
                : currency1,
              cost_id: data.total_port_and_clearance_expenses?.cost_id
            },
            totalFreight: {
              value: data.total_freight?.value,
              currency: data.total_freight
                ? CURRENCY?.find((item) => item.value === data.total_freight?.currency)
                : currency1,
              cost_id: data.total_freight?.cost_id
            },
            portOfLoading: data.pol
              ? {
                  label: data.pol?.name,
                  value: data.pol?.id
                }
              : null,
            portOfDischarge: data.pod
              ? {
                  label: data.pod?.name,
                  value: data.pod?.id
                }
              : null,
            insuranceCost: data.insurance_cost ?? 0.1,
            additionalCost: {
              value: data.additional_cost?.value,
              currency: data.additional_cost
                ? CURRENCY?.find((item) => item.value === data.additional_cost?.currency)
                : currency1,
              cost_id: data.additional_cost?.cost_id
            },
            financeCost: data.finance_cost,
            profitMarginExpected: data.profit_margin,
            discount: data.discount,
            incoTerms: data.deal_incoterm
              ? {
                  label: data.deal_incoterm,
                  value: data.deal_incoterm
                }
              : null,
            switchBlCost: {
              value: data.switch_bl_cost?.value,
              currency: data.switch_bl_cost
                ? CURRENCY?.find((item) => item.value === data.switch_bl_cost?.currency)
                : currency1,
              cost_id: data.switch_bl_cost?.cost_id
            }
          }
        });
      }
    }
  }, []);

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <main className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">
            {isNew ? 'Add Quote' : isEdit ? 'Edit Quote' : 'Dublicate Quote'}
          </Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <section className={css.modalContent}>
          {isConsolidation ? (
            <FormProvider {...consolidationForm}>
              <QuoteCostCalculator currency={currency1} consolidation={isConsolidation} />
              <ConsolidatedQuote
                onFormSubmit={onFormSubmit}
                onClose={onClose}
                selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
                selectedCurrency={selectedCurrency}
                productOptions={productOptions}
                filterProductOptions={filterProductOptions}
                filterPorts={filterPorts}
                portOfLoadingOptions={portOfLoadingOptions}
                portOfDischargeOptions={portOfDischargeOptions}
                isFcl={isFcl}
                isMtt={false}
                isEdit={isEdit}
                quoteDetail={quoteDetail}
              />
            </FormProvider>
          ) : (
            <FormProvider {...nonConsolidationForm}>
              <QuoteCostCalculator currency={currency1} consolidation={isConsolidation} />
              <NonConsolidatedQuote
                onFormSubmit={onFormSubmit}
                onClose={onClose}
                selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
                selectedCurrency={selectedCurrency}
                productOptions={productOptions}
                filterProductOptions={filterProductOptions}
                filterPorts={filterPorts}
                portOfLoadingOptions={portOfLoadingOptions}
                portOfDischargeOptions={portOfDischargeOptions}
                isFcl={isFcl}
                isMtt={isMtt}
                isEdit={isEdit}
                quoteDetail={quoteDetail}
              />
            </FormProvider>
          )}
        </section>
      </main>
    </Drawer>
  );
};

export default AddEditQuote;
