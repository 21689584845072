import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { fetchEntityList, getPaymentTermsFilterOptions } from '@services/order.service';
import { CURRENCY, PORT_FIELD_TYPES, WEIGHT_SYSTEM } from '@helpers/constants';
import { IconNode } from '@components/base';
import notify from '@helpers/toastify-helper';
import QuoteGenerator from './components/quote-generator';
import EnquiryForm from './components/enquiry-form';
import Images from '@assets/images';
import CostCalculator from './components/cost-calculator';
import {
  fetchQuoteCustomerList,
  fetchQuoteProductList,
  submitConsolidationQuote,
  submitNonConsolidationQuote,
  updateNonConsolidationEnquiry
} from '@services/quote.service';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
  consolidationQuoteValidation,
  enquiryFormValidation,
  nonConsolidationQuoteValidation
} from '@helpers/yup/add-quote.schema';
import { fetchPortList } from '@services/util.service';
import { useNavigate } from 'react-router-dom';

export interface IFilterState {
  fcl: boolean;
  mtt: boolean;
  consolidation: boolean;
}

export interface IWeightSystemOption {
  label: string;
  value: string;
  childOptions?: IWeightChildOption[];
}

export interface IWeightChildOption {
  label: string;
  value: string;
  default?: boolean;
  convertToKg?: number;
  convertToMT?: number;
  convertToLb?: number;
  convertToOz?: number;
  convertToGallon?: number;
}

export interface IQuoteFormState {
  isLoading: boolean;
  activeStep: number;
  paymentTermsOptions: any[];
  entityOptions: any[];
  productOptions: any[];
  customerOptions: any[];
  selectedCurrency: any[];
  selectedWeightSystemChildOptions: IWeightChildOption[];
  firstFormReqBody: any;
  portOptions: any[];
  portOfLoadingOptions: any[];
  portOfDischargeOptions: any[];
  enquiryId: string | null;
}

const formPrimaryUnit =
  WEIGHT_SYSTEM.find((item: IWeightSystemOption) => item.value === 'kg/mt/lb')?.childOptions?.find(
    (child) => child.default
  ) ?? null;

const QuoteForm = (props: any) => {
  const {
    onClose,
    data,
    isEdit,
    isConsolidation,
    isFcl,
    isMtt,
    isDuplicate = false,
    onSubmitClose
  } = props;

  const navigate = useNavigate();

  const [quoteFormState, setQuoteFormState] = useState<IQuoteFormState>({
    isLoading: false,
    activeStep: 1,
    paymentTermsOptions: [],
    entityOptions: [],
    productOptions: [],
    customerOptions: [],
    selectedCurrency: CURRENCY,
    selectedWeightSystemChildOptions: WEIGHT_SYSTEM.reduce(
      (acc: any, system: IWeightSystemOption) => {
        if (system.childOptions) {
          acc = acc.concat(system.childOptions);
        }
        return acc;
      },
      []
    ),
    firstFormReqBody: {},
    portOptions: [],
    portOfLoadingOptions: [],
    portOfDischargeOptions: [],
    enquiryId: null
  });

  const {
    isLoading,
    activeStep,
    paymentTermsOptions,
    entityOptions,
    selectedCurrency,
    selectedWeightSystemChildOptions,
    productOptions,
    firstFormReqBody,
    customerOptions,
    portOptions,
    portOfLoadingOptions,
    portOfDischargeOptions,
    enquiryId
  } = quoteFormState;

  const getPaymentTermsOptions = async () => {
    setQuoteFormState((prevState: any) => ({ ...prevState, isLoading: true }));
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setQuoteFormState((prevState: any) => ({
        ...prevState,
        paymentTermsOptions: response.data?.payment_terms_options,
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
      setQuoteFormState((prevState: any) => ({ ...prevState, isLoading: false }));
    }
  };

  const getEntityList = async () => {
    setQuoteFormState((prevState: any) => ({ ...prevState, isLoading: true }));
    const response = await fetchEntityList();
    if (response?.success) {
      setQuoteFormState((prevState: any) => ({
        ...prevState,
        entityOptions: response?.data?.results ?? [],
        isLoading: false
      }));
    } else {
      setQuoteFormState((prevState: any) => ({ ...prevState, isLoading: false }));
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const filterProductOptions = async (event: any, param?: any) => {
    if (param) {
      const response = await fetchQuoteProductList({ product_name: param });
      if (response?.success) {
        setQuoteFormState((prevState: any) => ({
          ...prevState,
          productOptions: response?.data?.results ?? []
        }));
      } else {
        notify({
          severity: 'error',
          message: response?.message
        });
      }
    } else {
      const response = await fetchQuoteProductList();
      if (response?.success) {
        setQuoteFormState((prevState: any) => ({
          ...prevState,
          productOptions: response?.data?.results ?? []
        }));
      } else {
        notify({
          severity: 'error',
          message: response?.message
        });
      }
    }
  };

  const filterPortList = async () => {
    const data = await fetchPortList({ port_type: 'seaport' });
    if (data?.success) {
      setQuoteFormState((prevState: any) => ({
        ...prevState,
        portOptions: data.data.filter_options,
        portOfLoadingOptions: data.data.filter_options,
        portOfDischargeOptions: data.data.filter_options
      }));
    }
  };

  const filterPorts = async (event?: any, param?: any, fieldType?: string) => {
    if (param) {
      const portList = await fetchPortList({ search_text: param, port_type: 'seaport' });
      if (portList?.success) {
        fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
          ? setQuoteFormState((prevState: any) => ({
              ...prevState,
              portOfLoadingOptions: portList.data.filter_options
            }))
          : setQuoteFormState((prevState: any) => ({
              ...prevState,
              portOfDischargeOptions: portList.data.filter_options
            }));
      } else {
        notify({
          message: portList.error ?? 'Internal Server Error',
          severity: 'error'
        });
      }
    } else {
      fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
        ? setQuoteFormState((prevState: any) => ({
            ...prevState,
            portOfLoadingOptions: portOptions
          }))
        : setQuoteFormState((prevState: any) => ({
            ...prevState,
            portOfDischargeOptions: portOptions
          }));
    }
  };

  const filterCustomerOptions = async (event: any, param?: any) => {
    if (param) {
      const response = await fetchQuoteCustomerList({ search: param });
      if (response?.success) {
        setQuoteFormState((prevState: any) => ({
          ...prevState,
          customerOptions: response?.data ?? []
        }));
      } else {
        notify({
          severity: 'error',
          message: response?.message
        });
      }
    } else {
      const response = await fetchQuoteCustomerList();
      if (response?.success) {
        setQuoteFormState((prevState: any) => ({
          ...prevState,
          customerOptions: response?.data ?? []
        }));
      } else {
        notify({
          severity: 'error',
          message: response?.message
        });
      }
    }
  };

  const consolidationForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(consolidationQuoteValidation),
    defaultValues: {
      genericInformation: {
        currency1: CURRENCY.find((item) => item.value === 'USD'),
        currency2: CURRENCY.find((item) => item.value === 'INR'),
        fxRate: 87,
        weightSystem: WEIGHT_SYSTEM.find((item: IWeightSystemOption) => item.value === 'kg/mt/lb'),
        remarks: null,
        primaryUnit: formPrimaryUnit
      },
      quote: [
        {
          product: [
            {
              isExpanded: true,
              productName: null,
              base_price: {
                value: null,
                currency: CURRENCY.find((item) => item.value === 'USD'),
                cost_id: null
              },
              fob_price: {
                value: null,
                currency: CURRENCY.find((item) => item.value === 'USD'),
                cost_id: null,
                manualOverride: false
              },
              cif_price: {
                value: null,
                currency: CURRENCY.find((item) => item.value === 'USD'),
                cost_id: null,
                manualOverride: false
              },
              cfr_price: {
                value: null,
                currency: CURRENCY.find((item) => item.value === 'USD'),
                cost_id: null,
                manualOverride: false
              },
              productPackaging: null,
              dealQuantity: null,
              dealQuantityUnit: formPrimaryUnit,
              profitMarginExpected: null,
              final_exWorks_price: {
                value: null,
                currency: CURRENCY.find((item) => item.value === 'USD'),
                cost_id: null
              },
              finalQuote_selling_price: {
                value: null,
                currency: CURRENCY.find((item) => item.value === 'USD'),
                cost_id: null
              },
              countryOfOrigin: null,
              moq: null,
              moqUnit: formPrimaryUnit
            }
          ],
          totalWeight: 0,
          totalWeightUnit: formPrimaryUnit,
          totalLocalFreight: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          totalPortAndClearanceExpenses: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          totalFreight: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          switchBlCost: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          portOfLoading: null,
          portOfDischarge: null,
          insuranceCost: 0.1,
          additionalCost: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          financeCost: null,
          discount: null,
          incoTerms: null
        }
      ]
    }
  });

  const nonConsolidationForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(nonConsolidationQuoteValidation),
    defaultValues: {
      genericInformation: {
        currency1: CURRENCY.find((item) => item.value === 'USD'),
        currency2: CURRENCY.find((item) => item.value === 'INR'),
        fxRate: 87,
        weightSystem: WEIGHT_SYSTEM.find((item: IWeightSystemOption) => item.value === 'kg/mt/lb'),
        draftDealName: null,
        remarks: null,
        primaryUnit: formPrimaryUnit
      },
      quote: [
        {
          quote_id: null,
          isExpanded: true,
          productName: null,
          productPackaging: null,
          dealQuantity: null,
          dealQuantityUnit: formPrimaryUnit,
          countryOfOrigin: null,
          base_price: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          fob_price: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null,
            manualOverride: false
          },
          cif_price: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null,
            manualOverride: false
          },
          cfr_price: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null,
            manualOverride: false
          },
          moq: null,
          moqUnit: formPrimaryUnit,
          totalWeight: 0,
          totalWeightUnit: formPrimaryUnit,
          totalLocalFreight: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          totalPortAndClearanceExpenses: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          totalFreight: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          portOfLoading: null,
          portOfDischarge: null,
          insuranceCost: 0.1,
          additionalCost: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          financeCost: null,
          profitMarginExpected: null,
          discount: null,
          incoTerms: null,
          switchBlCost: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          final_exWorks_price: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          },
          finalQuote_selling_price: {
            value: null,
            currency: CURRENCY.find((item) => item.value === 'USD'),
            cost_id: null
          }
        }
      ]
    }
  });

  const enquiryForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(enquiryFormValidation),
    defaultValues: {
      enquiryNumber: null,
      customer: null,
      entity: null,
      Createddate: null,
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      priceValidUntill: null,
      expectedDeliverySchedule: null
    }
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    fcl: true,
    mtt: false,
    consolidation: false
  });

  const { consolidation, mtt, fcl } = filterState;

  const handleBackClick = () => {
    setQuoteFormState((prevState) => ({
      ...prevState,
      activeStep: 1
    }));
  };

  const changeActiveStep = (step: number) => {
    setQuoteFormState((prevState) => ({
      ...prevState,
      activeStep: step
    }));
  };

  const generateNonConsolidationReqBody = (data: any) => {
    const reqBody = {
      currency_type_one: data.genericInformation.currency1.value,
      currency_type_two: data.genericInformation.currency2.value,
      fx_rate: parseFloat(data.genericInformation.fxRate),
      weight_system: data.genericInformation.weightSystem.value,
      deal_name: data.genericInformation.draftDealName,
      remarks: data.genericInformation.remarks,
      primary_unit: data.genericInformation.primaryUnit?.value,
      quotes: (data.quote || []).map((quote: any) => {
        return {
          product_details: {
            name: quote.productName?.product_name,
            id: quote.productName?.product_id
          },
          origin: quote.countryOfOrigin,
          quantity: parseFloat(quote.dealQuantity),
          quantity_unit: quote.dealQuantityUnit.value,
          packaging: quote.productPackaging,
          costs: {
            base_price: {
              currency: quote.base_price.currency?.value,
              value: parseFloat(quote.base_price?.value),
              cost_id: quote.base_price?.cost_id
            },
            ex_works_price: {
              currency: data.genericInformation.currency1.value,
              value: parseFloat(quote.final_exWorks_price?.value),
              cost_id: quote.final_exWorks_price?.cost_id
            },
            fob_price: {
              currency: quote.fob_price.currency?.value,
              value: parseFloat(quote.fob_price.value),
              cost_id: quote.fob_price?.cost_id
            },
            cfr_price: {
              currency: quote.cfr_price.currency?.value,
              value: parseFloat(quote.cfr_price.value),
              cost_id: quote.cfr_price?.cost_id
            },
            cif_price: {
              currency: quote.cif_price.currency?.value,
              value: parseFloat(quote.cif_price.value),
              cost_id: quote.cif_price?.cost_id
            },
            total_local_freight: {
              currency: quote.totalLocalFreight.currency?.value,
              value: parseFloat(quote.totalLocalFreight.value),
              cost_id: quote.totalLocalFreight?.cost_id
            },
            total_port_and_clearance_expenses: {
              currency: quote.totalPortAndClearanceExpenses.currency?.value,
              value: parseFloat(quote.totalPortAndClearanceExpenses.value),
              cost_id: quote.totalPortAndClearanceExpenses?.cost_id
            },
            total_freight: {
              currency: quote.totalFreight.currency?.value,
              value: parseFloat(quote.totalFreight.value),
              cost_id: quote.totalFreight?.cost_id
            },
            switch_bl_cost: quote.switchBlCost
              ? {
                  currency: quote.switchBlCost?.currency?.value,
                  value: parseFloat(quote.switchBlCost?.value),
                  cost_id: quote.switchBlCost?.cost_id
                }
              : null,
            final_price: {
              currency: data.genericInformation.currency1.value,
              value: parseFloat(quote.finalQuote_selling_price.value),
              cost_id: quote.finalQuote_selling_price?.cost_id
            },
            additional_cost: {
              currency: quote.additionalCost?.currency?.value,
              value: parseFloat(quote.additionalCost?.value),
              cost_id: quote.additionalCost?.cost_id
            }
          },
          moq: parseFloat(quote.moq),
          moq_unit: quote.moqUnit?.value,
          weight_one_container: parseFloat(quote.totalWeight),
          weight_one_container_unit: quote.totalWeightUnit.value,
          pol: quote.portOfLoading?.value,
          pod: quote.portOfDischarge?.value,
          insurance_cost: parseFloat(quote.insuranceCost),
          finance_cost: parseFloat(quote.financeCost),
          profit_margin: parseFloat(quote.profitMarginExpected),
          discount: parseFloat(quote.discount),
          deal_incoterm: quote.incoTerms?.value,
          is_draft: false,
          deal_status: null,
          enquiry: enquiryId,
          quote_id: quote.quote_id
        };
      })
    };

    return reqBody;
  };

  const generateConsolidationReqBody = (data: any) => {
    const reqBody = {
      currency_type_one: data.genericInformation.currency1?.value,
      currency_type_two: data.genericInformation.currency2?.value,
      fx_rate: data.genericInformation.fxRate,
      weight_system: data.genericInformation.weightSystem?.value,
      deal_name: data.genericInformation.draftDealName,
      remarks: data.genericInformation.remarks,
      primary_unit: data.genericInformation.primaryUnit?.value,
      quotes: (data.quote || []).map((quote: any) => {
        return {
          products: (quote.product || []).map((product: any) => {
            return {
              product_details: {
                name: product?.productName?.product_name,
                id: product?.productName?.product_id
              },
              quantity: product.dealQuantity,
              quantity_unit: product.dealQuantityUnit.value,
              packaging: product.productPackaging,
              moq: product.moq,
              moq_unit: product.moqUnit?.value,
              profit_margin: product.profitMarginExpected,
              costs: {
                base_price: {
                  currency: product?.base_price.currency?.value,
                  value: parseFloat(product.base_price.value),
                  cost_id: product.base_price?.cost_id
                },
                ex_works_price: {
                  currency: data?.genericInformation.currency1?.value,
                  value: parseFloat(product.final_exWorks_price.value),
                  cost_id: product.final_exWorks_price?.cost_id
                },
                fob_price: {
                  currency: product.fob_price.currency?.value,
                  value: parseFloat(product.fob_price.value),
                  cost_id: product.fob_price?.cost_id
                },
                cfr_price: {
                  currency: product.cfr_price.currency?.value,
                  value: parseFloat(product.cfr_price.value),
                  cost_id: product.cfr_price?.cost_id
                },
                cif_price: {
                  currency: product.cif_price.currency?.value,
                  value: parseFloat(product.cif_price.value),
                  cost_id: product.cif_price?.cost_id
                },
                final_price: {
                  currency: data?.genericInformation.currency1?.value,
                  value: parseFloat(product.finalQuote_selling_price.value),
                  cost_id: product.finalQuote_selling_price?.cost_id
                }
              },
              origin: product.countryOfOrigin
            };
          }),
          common_costs: {
            total_local_freight: {
              currency: quote?.totalLocalFreight.currency?.value,
              value: parseFloat(quote.totalLocalFreight.value),
              cost_id: quote.totalLocalFreight?.cost_id
            },
            additional_cost: {
              currency: quote?.additionalCost?.currency?.value,
              value: parseFloat(quote.additionalCost?.value),
              cost_id: quote.additionalCost?.cost_id
            },
            total_port_and_clearance_expenses: {
              currency: quote.totalPortAndClearanceExpenses.currency?.value,
              value: parseFloat(quote.totalPortAndClearanceExpenses.value),
              cost_id: quote.totalPortAndClearanceExpenses?.cost_id
            },
            total_freight: {
              currency: quote.totalFreight.currency?.value,
              value: parseFloat(quote.totalFreight.value),
              cost_id: quote.totalFreight?.cost_id
            },
            switch_bl_cost: {
              currency: quote.switchBlCost.currency?.value,
              value: parseFloat(quote.switchBlCost.value),
              cost_id: quote.switchBlCost?.cost_id
            }
          },
          // weight_one_container: quote.totalWeight,
          // weight_one_container_unit: quote.totalWeightUnit.value,
          total_weight: parseFloat(quote.totalWeight),
          total_weight_unit: quote.totalWeightUnit.value,
          pol_id: quote.portOfLoading?.value,
          pod_id: quote.portOfDischarge?.value,
          insurance_cost: parseFloat(quote.insuranceCost),
          finance_cost: parseFloat(quote.financeCost),
          discount: parseFloat(quote.discount),
          deal_incoterm: quote.incoTerms?.value
        };
      })
    };
    return reqBody;
  };

  const onFirstFormSubmit = (data: any) => {
    setQuoteFormState((prevState: any) => {
      return {
        ...prevState,
        activeStep: 2,
        firstFormReqBody: consolidation
          ? generateConsolidationReqBody(data)
          : generateNonConsolidationReqBody(data)
      };
    });
  };

  const onFormSubmit = async (data: any) => {
    const reqBody: any = { ...firstFormReqBody };
    reqBody.entity = data.entity.entity_id;
    reqBody.last_valid_date = data.priceValidUntill
      ? `${moment(data.priceValidUntill).format('YYYY-MM-DD')}`
      : null;
    reqBody.expected_delivery_schedule = data.expectedDeliverySchedule;
    reqBody.payment_term = (data.payment_terms || []).map((term: any) => {
      const obj: any = {};
      obj['payment_term'] = term.payment_term?.payment_term;
      obj['percentage'] = parseInt(term.percentage);
      if (term.days) {
        obj['days'] = parseInt(term.days);
      }
      return obj;
    });
    reqBody.hubspot_customer_id = data.customer?.id;
    reqBody.customer_name = data.customer.customer_name;
    reqBody.sourcing_type = mtt ? 'MTT' : 'NON_MTT';
    reqBody.container_type = fcl ? 'FCL' : 'LCL';
    reqBody.is_consolidation = consolidation;
    const response = consolidation
      ? await submitConsolidationQuote(reqBody)
      : await submitNonConsolidationQuote(reqBody);

    if (response.success) {
      notify({
        severity: 'success',
        message: response.data.message
      });
      onSubmitClose();
      navigate('/quote/detail/' + (response?.data.enquiry || enquiryId || ''));
    } else {
      notify({
        severity: 'error',
        message: `Somthing Went Wrong!!`
      });
    }
  };

  useEffect(() => {
    getPaymentTermsOptions();
    getEntityList();
    filterProductOptions(null, '');
    filterCustomerOptions(null, '');
    filterPortList();
  }, []);

  useEffect(() => {
    if (data) {
      setFilterState((prevState: any) => ({
        ...prevState,
        fcl: data.container_type === 'FCL',
        mtt: data.sourcing_type === 'MTT',
        consolidation: isConsolidation
      }));

      const selectedChildWeights = WEIGHT_SYSTEM.find(
        (item: IWeightSystemOption) => item.value === data.weight_system
      )?.childOptions;

      setQuoteFormState((prevState: any) => ({
        ...prevState,
        selectedWeightSystemChildOptions: selectedChildWeights,
        selectedCurrency: [
          CURRENCY.find((item) => item.value === data.currency_type_one),
          CURRENCY.find((item) => item.value === data.currency_type_two)
        ],
        enquiryId: data.enquiry_id
      }));

      const primaryCurrency = CURRENCY.find((item) => item.value === data.currency_type_one);
      const primaryUnit = selectedChildWeights?.find((item) => item.value === data?.primary_unit);
      !isConsolidation &&
        nonConsolidationForm.reset({
          genericInformation: {
            currency1: CURRENCY.find((item) => item.value === data.currency_type_one),
            currency2: CURRENCY.find((item) => item.value === data.currency_type_two),
            fxRate: data.fx_rate,
            weightSystem: WEIGHT_SYSTEM?.find(
              (item: IWeightSystemOption) => item.value === data.weight_system
            ),
            draftDealName: data?.deal_name,
            remarks: data?.remarks,
            primaryUnit: selectedChildWeights?.find((item) => item.value === data?.primary_unit)
          },
          quote: (data?.quotes || []).map((quote: any) => {
            return {
              quote_id: quote.quote_id,
              isExpanded: true,
              productName: {
                product_name: quote?.product_details?.product_name,
                product_id: quote?.product_details?.product_id
              },
              productPackaging: quote?.packaging,
              dealQuantity: quote?.quantity,
              dealQuantityUnit: quote?.quantity_unit
                ? selectedChildWeights?.find((item) => item.value === quote.quantity_unit)
                : primaryUnit,
              base_price: {
                value: quote?.base_price?.value,
                currency: quote?.base_price
                  ? CURRENCY.find((item) => item.value === quote?.base_price?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.base_price?.cost_id
              },
              fob_price: {
                value: quote?.fob_price?.value,
                currency: quote?.fob_price
                  ? CURRENCY.find((item) => item.value === quote?.fob_price?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.fob_price?.cost_id
              },
              cfr_price: {
                value: quote?.cfr_price?.value,
                currency: quote?.cfr_price
                  ? CURRENCY.find((item) => item.value === quote?.cfr_price?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.cfr_price?.cost_id
              },
              cif_price: {
                value: quote?.cif_price?.value,
                currency: quote?.cif_price
                  ? CURRENCY.find((item) => item.value === quote?.cif_price?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.cif_price?.cost_id
              },
              moq: quote?.moq,
              moqUnit: quote?.moq_unit
                ? selectedChildWeights?.find((item) => item.value === quote?.moq_unit)
                : primaryUnit,
              totalWeight: quote?.weight_one_container,
              totalWeightUnit: quote?.weight_one_container_unit
                ? selectedChildWeights?.find(
                    (item) => item.value === quote?.weight_one_container_unit
                  )
                : primaryUnit,
              totalLocalFreight: {
                value: quote?.total_local_freight?.value,
                currency: quote.total_local_freight
                  ? CURRENCY.find((item) => item.value === quote?.total_local_freight.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote.total_local_freight?.cost_id
              },
              totalPortAndClearanceExpenses: {
                value: quote?.total_port_and_clearance_expenses?.value,
                currency: quote?.total_port_and_clearance_expenses
                  ? CURRENCY.find(
                      (item) => item.value === quote.total_port_and_clearance_expenses?.currency
                    )
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.total_port_and_clearance_expenses?.cost_id
              },
              totalFreight: {
                value: quote?.total_freight?.value,
                currency: quote?.total_freight
                  ? CURRENCY.find((item) => item.value === quote?.total_freight?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.total_freight?.cost_id
              },
              portOfLoading: quote?.pol
                ? {
                    label: quote.pol?.name,
                    value: quote.pol?.id
                  }
                : null,
              portOfDischarge: quote?.pod
                ? {
                    label: quote.pod?.name,
                    value: quote.pod?.id
                  }
                : null,
              insuranceCost: quote?.insurance_cost,
              additionalCost: {
                value: quote?.additional_cost?.value,
                currency: quote?.additional_cost
                  ? CURRENCY.find((item) => item?.value === quote?.additional_cost?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.additional_cost?.cost_id
              },
              financeCost: quote?.finance_cost,
              profitMarginExpected: quote?.profit_margin,
              discount: quote?.discount,
              incoTerms: quote?.deal_incoterm
                ? {
                    value: quote?.deal_incoterm,
                    label: quote?.deal_incoterm
                  }
                : null,
              switchBlCost: {
                value: quote?.switch_bl_cost?.value,
                currency: quote?.switch_bl_cost
                  ? CURRENCY.find((item) => item.value === quote?.switch_bl_cost?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.switch_bl_cost?.cost_id
              },
              final_exWorks_price: {
                value: quote?.ex_works_price?.value,
                currency: quote?.ex_works_price
                  ? CURRENCY.find((item) => item.value === quote?.ex_works_price?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.ex_works_price?.cost_id
              },
              finalQuote_selling_price: {
                value: quote?.final_price?.value,
                currency: quote?.final_price
                  ? CURRENCY.find((item) => item.value === quote?.final_price?.currency)
                  : primaryCurrency,
                cost_id: isDuplicate ? null : quote?.final_price?.cost_id
              },
              countryOfOrigin: quote?.origin
            };
          })
        });

      isConsolidation &&
        (() => {
          const consolidationQuotes = (data.consolidations || [])
            .map((item: any, index: number) => {
              return item.quotes;
            })
            .flat();
          consolidationForm.reset({
            genericInformation: {
              currency1: CURRENCY.find((item) => item.value === data?.currency_type_one),
              currency2: CURRENCY.find((item) => item.value === data?.currency_type_two),
              fxRate: data.fx_rate,
              weightSystem: WEIGHT_SYSTEM?.find(
                (item: IWeightSystemOption) => item.value === data?.weight_system
              ),
              draftDealName: data?.deal_name,
              remarks: data?.remarks,
              primaryUnit: selectedChildWeights?.find((item) => item.value === data?.primary_unit)
            },
            quote: (consolidationQuotes || []).map((quote: any) => {
              return {
                product: (quote.products || []).map((product: any) => {
                  return {
                    isExpanded: true,
                    productName: {
                      product_name: product?.product_data?.name,
                      product_id: product?.product_data?.id
                    },
                    productPackaging: product?.packaging,
                    dealQuantity: product?.quantity,
                    dealQuantityUnit: product?.quantity_unit
                      ? selectedChildWeights?.find((item) => item.value === product?.quantity_unit)
                      : primaryUnit,
                    profitMarginExpected: product?.profit_margin,
                    base_price: {
                      value: product.base_price.value,
                      currency: product?.base_price
                        ? CURRENCY.find((item) => item.value === product.base_price.currency)
                        : primaryCurrency,
                      cost_id: isDuplicate ? null : product.base_price?.cost_id
                    },
                    fob_price: {
                      value: product.fob_price.value,
                      currency: product.fob_price
                        ? CURRENCY.find((item) => item.value === product.fob_price.currency)
                        : primaryCurrency,
                      cost_id: isDuplicate ? null : product.fob_price?.cost_id
                    },
                    cfr_price: {
                      value: product.cfr_price.value,
                      currency: product.cfr_price
                        ? CURRENCY.find((item) => item.value === product.cfr_price.currency)
                        : primaryCurrency,
                      cost_id: isDuplicate ? null : product.cfr_price?.cost_id
                    },
                    cif_price: {
                      value: product.cif_price.value,
                      currency: product.cif_price
                        ? CURRENCY.find((item) => item.value === product.cif_price.currency)
                        : primaryCurrency,
                      cost_id: isDuplicate ? null : product.cif_price?.cost_id
                    },
                    final_exWorks_price: {
                      value: product.ex_works_price.value,
                      currency: product.ex_works_price
                        ? CURRENCY.find((item) => item.value === product.ex_works_price.currency)
                        : primaryCurrency,
                      cost_id: isDuplicate ? null : product.ex_works_price?.cost_id
                    },
                    finalQuote_selling_price: {
                      value: product.final_price.value,
                      currency: product.final_price
                        ? CURRENCY.find((item) => item.value === product.final_price.currency)
                        : primaryCurrency,
                      cost_id: isDuplicate ? null : product.final_price?.cost_id
                    },
                    moq: product?.moq,
                    moqUnit: product?.moq_unit
                      ? selectedChildWeights?.find((item) => item.value === product?.moq_unit)
                      : primaryUnit,
                    countryOfOrigin: product?.origin
                  };
                }),
                totalWeight: quote?.total_weight,
                totalWeightUnit: quote.total_weight_unit
                  ? selectedChildWeights?.find((item) => item.value === quote.total_weight_unit)
                  : primaryUnit,
                totalLocalFreight: {
                  value: quote.total_local_freight.value,
                  currency: quote.total_local_freight
                    ? CURRENCY.find((item) => item.value === quote.total_local_freight.currency)
                    : null,
                  cost_id: isDuplicate ? null : quote.total_local_freight?.cost_id
                },
                totalPortAndClearanceExpenses: {
                  value: quote.total_port_and_clearance_expenses.value,
                  currency: quote.total_port_and_clearance_expenses
                    ? CURRENCY.find(
                        (item) => item.value === quote.total_port_and_clearance_expenses.currency
                      )
                    : null,
                  cost_id: isDuplicate ? null : quote.total_port_and_clearance_expenses?.cost_id
                },
                switchBlCost: {
                  value: quote.switch_bl_cost.value,
                  currency: quote.switch_bl_cost
                    ? CURRENCY.find((item) => item.value === quote.switch_bl_cost.currency)
                    : primaryCurrency,
                  cost_id: isDuplicate ? null : quote.switch_bl_cost?.cost_id
                },
                totalFreight: {
                  value: quote.total_freight.value,
                  currency: quote.total_freight
                    ? CURRENCY.find((item) => item.value === quote.total_freight.currency)
                    : primaryCurrency,
                  cost_id: isDuplicate ? null : quote.total_freight?.cost_id
                },
                portOfLoading: quote?.pol
                  ? {
                      label: quote.pol?.name,
                      value: quote.pol?.id
                    }
                  : null,
                portOfDischarge: quote?.pod
                  ? {
                      label: quote.pod?.name,
                      value: quote.pod?.id
                    }
                  : null,
                insuranceCost: quote?.insurance_cost,
                additionalCost: {
                  value: quote.additional_cost?.value,
                  currency: quote.additional_cost
                    ? CURRENCY.find((item) => item.value === quote.additional_cost?.currency)
                    : primaryCurrency,
                  cost_id: isDuplicate ? null : quote.additional_cost?.cost_id
                },
                financeCost: quote?.finance_cost,
                profitMarginExpected: quote?.profit_margin,
                discount: quote?.discount,
                incoTerms: quote?.deal_incoterm
                  ? {
                      value: quote?.deal_incoterm,
                      label: quote?.deal_incoterm
                    }
                  : null
              };
            })
          });
        })();

      enquiryForm.reset({
        enquiryNumber: {
          value: data.enquiry_id,
          label: data.readable_enquiry_number
        },
        customer: {
          id: data.hubspot_customer_id,
          customer_name: data.customer_name
        },
        entity: {
          entity_id: data.entity?.entity_id,
          name: data.entity?.name
        },
        Createddate: new Date(data.created_on),
        priceValidUntill: data?.last_valid_date && new Date(data.last_valid_date),
        payment_terms: (data.payment_terms || []).map((term: any) => {
          return {
            payment_term: {
              payment_term: term.payment_term,
              days: term.days ? true : false,
              percentage: term.percentage ? true : false
            },
            percentage: term.percentage,
            days: term.days
          };
        }),
        expectedDeliverySchedule: data.expected_delivery_schedule
      });
    }
  }, [data, isEdit]);

  return (
    <main className={css.mainWrapper}>
      <div className={css.header}>
        <div className={css.quoteHeading}>
          {activeStep == 1 ? 'Quote Generator' : 'Convert To Quote'}
        </div>
        <IconNode
          src={Images.close}
          alt="quote icon"
          onClick={() => onClose()}
          className={css.closeIcon}
        />
      </div>
      {activeStep === 1 &&
        (consolidation ? (
          <FormProvider {...consolidationForm} key="consolidationForm">
            <CostCalculator filters={filterState} />
            <QuoteGenerator
              filterState={filterState}
              setFilterState={setFilterState}
              activeStep={activeStep}
              setQuoteFormState={setQuoteFormState}
              selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
              selectedCurrency={selectedCurrency}
              changeActiveStep={changeActiveStep}
              onFormSubmit={onFirstFormSubmit}
              filterProductOptions={filterProductOptions}
              filterCustomerOptions={filterCustomerOptions}
              productOptions={productOptions}
              customerOptions={customerOptions}
              filterPorts={filterPorts}
              portOfLoadingOptions={portOfLoadingOptions}
              portOfDischargeOptions={portOfDischargeOptions}
              isEdit={isEdit}
            />
          </FormProvider>
        ) : (
          <FormProvider {...nonConsolidationForm} key="nonConsolidationForm">
            <CostCalculator filters={filterState} />
            <QuoteGenerator
              filterState={filterState}
              setFilterState={setFilterState}
              activeStep={activeStep}
              setQuoteFormState={setQuoteFormState}
              selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
              selectedCurrency={selectedCurrency}
              changeActiveStep={changeActiveStep}
              onFormSubmit={onFirstFormSubmit}
              filterProductOptions={filterProductOptions}
              filterCustomerOptions={filterCustomerOptions}
              productOptions={productOptions}
              customerOptions={customerOptions}
              filterPorts={filterPorts}
              portOfLoadingOptions={portOfLoadingOptions}
              portOfDischargeOptions={portOfDischargeOptions}
              isEdit={isEdit}
            />
          </FormProvider>
        ))}
      {activeStep === 2 && (
        <FormProvider {...enquiryForm}>
          <EnquiryForm
            paymentTermsOptions={paymentTermsOptions}
            handleBackClick={handleBackClick}
            entityOptions={entityOptions}
            onFormSubmit={onFormSubmit}
            customerOptions={customerOptions}
            filterCustomerOptions={filterCustomerOptions}
          />
        </FormProvider>
      )}
    </main>
  );
};

export default QuoteForm;
