import { Controller, useFormContext, useWatch } from 'react-hook-form';
import css from './index.module.scss';
import SelectLabel from '@components/common/select-label';
import { CURRENCY, WEIGHT_SYSTEM } from '@helpers/constants';
import { TextField } from '@components/common';
import { useEffect } from 'react';

const EnquiryGenericInformation = (props: any) => {
  const { setQuoteFormState, filterState, selectedWeightSystemChildOptions } = props;
  const { control, setValue, getValues } = useFormContext();
  const { consolidation } = filterState;

  const currency1 = useWatch({
    name: `genericInformation.currency1`,
    control
  });

  const currency2 = useWatch({
    name: `genericInformation.currency2`,
    control
  });

  const weightSystem = useWatch({
    name: `genericInformation.weightSystem`,
    control
  });

  const fxRate = useWatch({
    name: `genericInformation.fxRate`,
    control
  });

  useEffect(() => {
    setQuoteFormState((prevState: any) => {
      if (!currency1 && !currency2) {
        return {
          ...prevState,
          selectedCurrency: CURRENCY
        };
      }
      const items = [currency1, currency2].filter(Boolean);

      const uniqueArray = Array.from(new Set(items.map((item) => JSON.stringify(item)))).map(
        (item) => JSON.parse(item)
      );
      return {
        ...prevState,
        selectedCurrency: uniqueArray
      };
    });
    if (currency1 && !currency2) {
      {
        !consolidation
          ? getValues('quote').forEach((quote: any, index: number) => {
              setValue(`quote.${index}.base_price.currency`, currency1);
              setValue(`quote.${index}.fob_price.currency`, currency1);
              setValue(`quote.${index}.cfr_price.currency`, currency1);
              setValue(`quote.${index}.cif_price.currency`, currency1);
              setValue(`quote.${index}.totalLocalFreight.currency`, currency1);
              setValue(`quote.${index}.totalPortAndClearanceExpenses.currency`, currency1);
              setValue(`quote.${index}.totalFreight.currency`, currency1);
              setValue(`quote.${index}.additionalCost.currency`, currency1);
              setValue(`quote.${index}.switchBlCost.currency`, currency1);
              setValue(`quote.${index}.final_exWorks_price.currency`, currency1);
              setValue(`quote.${index}.finalQuote_selling_price.currency`, currency1);
            })
          : getValues('quote').forEach((quote: any, index: number) => {
              setValue(`quote.${index}.totalLocalFreight.currency`, currency1);
              setValue(`quote.${index}.totalPortAndClearanceExpenses.currency`, currency1);
              setValue(`quote.${index}.totalFreight.currency`, currency1);
              setValue(`quote.${index}.additionalCost.currency`, currency1);
              getValues(`quote.${index}.product`).forEach((product: any, productIndex: number) => {
                setValue(`quote.${index}.product.${productIndex}.base_price.currency`, currency1);
                setValue(`quote.${index}.product.${productIndex}.fob_price.currency`, currency1);
                setValue(`quote.${index}.product.${productIndex}.cfr_price.currency`, currency1);
                setValue(`quote.${index}.product.${productIndex}.cif_price.currency`, currency1);
                setValue(
                  `quote.${index}.product.${productIndex}.final_exWorks_price.currency`,
                  currency1
                );
                setValue(
                  `quote.${index}.product.${productIndex}.finalQuote_selling_price.currency`,
                  currency1
                );
              });
            });
      }
    }
  }, [currency1, currency2]);

  useEffect(() => {
    setQuoteFormState((prevState: any) => {
      if (!weightSystem) {
        return {
          ...prevState,
          selectedWeightSystemChildOptions: WEIGHT_SYSTEM
        };
      }
      return {
        ...prevState,
        selectedWeightSystemChildOptions: weightSystem?.childOptions
      };
    });
  }, [weightSystem]);

  return (
    <main className={css.mainWrapper}>
      <div className={css.header}>Generic Information</div>
      <div className={css.formWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name={`genericInformation.currency1`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                options={CURRENCY}
                required
                label="Currency 1"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`genericInformation.currency2`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                options={CURRENCY}
                label="Currency 2"
                isClearable
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`genericInformation.fxRate`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={currency1 && currency2}
                label={
                  <span>
                    Fx Rate
                    {currency1 && currency2 && currency1?.value !== currency2?.value && (
                      <span className={css.fxRate}>
                        (1 {currency1?.value} = {fxRate} {currency2?.value})
                      </span>
                    )}
                  </span>
                }
                // "Fx Rate"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`genericInformation.weightSystem`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                options={WEIGHT_SYSTEM}
                required
                label="Weight System"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`genericInformation.primaryUnit`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                options={selectedWeightSystemChildOptions}
                required
                label="Primary Unit"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`genericInformation.draftDealName`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Deal Name"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`genericInformation.remarks`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Remarks"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </main>
  );
};

export default EnquiryGenericInformation;
