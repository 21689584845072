import { useEffect } from 'react';
import css from './index.module.scss';
import Images from '@assets/images';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { INCO_TERMS, PORT_FIELD_TYPES } from '@helpers/constants';
import { IconNode, ToolTip } from '@components/base';
import { AutoComplete, SelectLabel, TextField } from '@components/common';
import {
  calculateCFRPrice,
  calculateCIFPrice,
  calculateExWorksPrice,
  calculateFobPrice,
  calculateQuotedPrice,
  formatPortOptions
} from '@helpers/utils';
import QuotePackageWrapper from '../quote-package-wrapper';
import { createFilterOptions } from '@mui/material';
import Typography from '@components/base/typography';

const filter = createFilterOptions<any>();

const NonConsolidationQuote = (props: any) => {
  const {
    index,
    productName,
    productPackaging,
    dealQuantity,
    basePrice,
    fobPrice,
    moq,
    moqUnit,
    totalWeight,
    totalLocalFrieght,
    totalPortAndClearanceExpenses,
    totalFreight,
    portOfLoading,
    portOfDischarge,
    insuranceCost,
    additionalCost,
    financeCost,
    profitMarginExpected,
    discount,
    incoTerms,
    switchBlCost,
    switchBLCostCurrency,
    handleDeleteClick,
    fieldLength,
    handleDublicateQuote,
    dealQuantityUnit,
    basePriceCurrency,
    fobPriceCurrency,
    totalWeightUnit,
    totalLocalFrieghtCurrency,
    totalPortAndClearanceExpensesCurrency,
    totalFreightCurrency,
    additionalCostCurrency,
    cifPrice,
    cfrPrice,
    cifPriceCurrency,
    cfrPriceCurrency,
    filterState,
    selectedCurrency,
    selectedWeightSystemChildOptions,
    finalExWorksPrice,
    finalExWorksPriceCurrency,
    finalQuoteSellingPrice,
    isExpanded,
    filterProductOptions,
    productOptions,
    filterPorts,
    portOfLoadingOptions,
    portOfDischargeOptions,
    countryOfOrigin,
    isEdit,
    fobPriceManualOverride,
    cfrPriceManualOverride,
    cifPriceManualOverride
  } = props;

  const { fcl, mtt, consolidation } = filterState;
  const {
    control,
    setValue,
    getValues,
    resetField,
    formState: { dirtyFields, errors }
  } = useFormContext();

  const primaryUnitWatch = useWatch({
    control,
    name: `genericInformation.primaryUnit`
  });

  const basePriceWatch = useWatch({
    control,
    name: basePrice
  });

  const basePriceCurrencyWatch = useWatch({
    control,
    name: basePriceCurrency
  });

  const finalExWorksPriceWatch = useWatch({
    control,
    name: finalExWorksPrice
  });

  const finalExWorksPriceCurrencyWatch = useWatch({
    control,
    name: finalExWorksPriceCurrency
  });

  const currency1Watch = useWatch({
    control,
    name: `genericInformation.currency1`
  });

  const dealQuantityWatch = useWatch({
    control,
    name: dealQuantity
  });

  const dealQuantityUnitWatch = useWatch({
    control,
    name: dealQuantityUnit
  });

  const fxRateWatch = useWatch({
    control,
    name: `genericInformation.fxRate`
  });

  const totalLocalFreightWatch = useWatch({
    control,
    name: totalLocalFrieght
  });

  const totalLocalFrieghtCurrencyWatch = useWatch({
    control,
    name: totalLocalFrieghtCurrency
  });

  const totalPortAndClearanceExpensesWatch = useWatch({
    control,
    name: totalPortAndClearanceExpenses
  });

  const totalPortAndClearanceExpensesCurrencyWatch = useWatch({
    control,
    name: totalPortAndClearanceExpensesCurrency
  });

  const totalWeightWatch = useWatch({
    control,
    name: totalWeight
  });

  const totalWeightUnitWatch = useWatch({
    control,
    name: totalWeightUnit
  });

  const fobPriceCurrencyWatch = useWatch({
    control,
    name: fobPriceCurrency
  });

  const fobPriceWatch = useWatch({
    control,
    name: fobPrice
  });

  const fobPriceManualOverrideWatch = useWatch({
    control,
    name: fobPriceManualOverride
  });

  const totalFreightWatch = useWatch({
    control,
    name: totalFreight
  });

  const totalFreightCurrencyWatch = useWatch({
    control,
    name: totalFreightCurrency
  });

  const cifPriceWatch = useWatch({
    control,
    name: cifPrice
  });

  const cifPriceManualOverrideWatch = useWatch({
    control,
    name: cifPriceManualOverride
  });

  const cfrPriceManualOverrideWatch = useWatch({
    control,
    name: cfrPriceManualOverride
  });

  const additionalCostWatch = useWatch({
    control,
    name: additionalCost
  });

  const additionalCostCurrencyWatch = useWatch({
    control,
    name: additionalCostCurrency
  });

  const financeCostWatch = useWatch({
    control,
    name: financeCost
  });

  const profitMarginExpectedWatch = useWatch({
    control,
    name: profitMarginExpected
  });

  const discountWatch = useWatch({
    control,
    name: discount
  });

  const cfrPriceCurrencyWatch = useWatch({
    control,
    name: cfrPriceCurrency
  });

  const cfrPriceWatch = useWatch({
    control,
    name: cfrPrice
  });

  const insuranceCostWatch = useWatch({
    control,
    name: insuranceCost
  });

  const cifPriceCurrencyWatch = useWatch({
    control,
    name: cifPriceCurrency
  });

  const switchBLCostWatch = useWatch({
    control,
    name: switchBlCost
  });

  const switchBLCostCurrencyWatch = useWatch({
    control,
    name: switchBLCostCurrency
  });

  const isExpandedWatch = useWatch({
    control,
    name: `quote.${index}.isExpanded`
  });

  const productWatch = useWatch({
    control,
    name: productName
  });

  // Ex-Works Price Calculation
  useEffect(() => {
    if (!basePriceWatch || !fxRateWatch) {
      return;
    }

    const exWorksPrice = calculateExWorksPrice(
      basePriceCurrencyWatch,
      currency1Watch,
      basePriceWatch,
      fxRateWatch || 1,
      finalExWorksPriceCurrencyWatch
    );
    setValue(finalExWorksPrice, exWorksPrice.toFixed(2));
  }, [
    basePriceWatch,
    fxRateWatch,
    currency1Watch,
    basePriceCurrencyWatch,
    finalExWorksPriceCurrencyWatch
  ]);

  // FOB Price Calculation
  useEffect(() => {
    if (fobPriceManualOverrideWatch) return;

    if (!totalWeightWatch || !totalWeightUnitWatch || !fxRateWatch) {
      return;
    }

    const finalFobPrice = calculateFobPrice(
      totalLocalFreightWatch,
      totalLocalFrieghtCurrencyWatch,
      totalPortAndClearanceExpensesWatch,
      totalPortAndClearanceExpensesCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRateWatch || 1,
      currency1Watch,
      primaryUnitWatch,
      fobPriceCurrencyWatch,
      finalExWorksPriceWatch,
      finalExWorksPriceCurrencyWatch
    );
    const newValue = finalFobPrice.toFixed(2);
    const currentValue = getValues(fobPrice);
    if (newValue !== currentValue) {
      setValue(fobPrice, finalFobPrice.toFixed(2));
    } else return;
  }, [
    totalLocalFreightWatch,
    totalLocalFrieghtCurrencyWatch,
    totalPortAndClearanceExpensesWatch,
    totalPortAndClearanceExpensesCurrencyWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    finalExWorksPriceWatch,
    primaryUnitWatch,
    fobPriceCurrencyWatch,
    fxRateWatch,
    finalExWorksPriceCurrency,
    fobPriceManualOverrideWatch
  ]);

  // CFR Price
  useEffect(() => {
    if (cfrPriceManualOverrideWatch) return;
    if (!fxRateWatch || !totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }
    const finalCfrPrice = calculateCFRPrice(
      totalFreightWatch,
      totalFreightCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRateWatch,
      cfrPriceCurrencyWatch,
      currency1Watch,
      fobPriceWatch,
      fobPriceCurrencyWatch,
      primaryUnitWatch
    );
    const currentValue = getValues(cfrPrice);
    if (finalCfrPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(cfrPrice, finalCfrPrice.toFixed(2));
  }, [
    fobPriceCurrencyWatch,
    fobPriceWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    totalFreightWatch,
    totalFreightCurrencyWatch,
    cfrPriceCurrencyWatch,
    primaryUnitWatch,
    switchBLCostWatch,
    switchBLCostCurrencyWatch,
    cfrPriceManualOverrideWatch
  ]);

  // CIF Price Calculation
  useEffect(() => {
    if (cifPriceManualOverrideWatch) return;

    if (!fxRateWatch) {
      return;
    }
    const finalCIFPrice = calculateCIFPrice(
      fxRateWatch || 1,
      currency1Watch,
      cfrPriceWatch,
      cfrPriceCurrencyWatch,
      insuranceCostWatch,
      cifPriceCurrencyWatch
    );
    const currentValue = getValues(cifPrice);
    if (finalCIFPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(cifPrice, finalCIFPrice.toFixed(2));
  }, [
    insuranceCostWatch,
    cfrPriceWatch,
    cifPriceCurrencyWatch,
    cfrPriceCurrencyWatch,
    cifPriceManualOverrideWatch
  ]);

  // Quote Selling Price Calculation
  useEffect(() => {
    if (!fxRateWatch) {
      return;
    }

    const QuoteSellingPrice = calculateQuotedPrice(
      cifPriceWatch,
      cifPriceCurrencyWatch,
      additionalCostWatch,
      additionalCostCurrencyWatch,
      switchBLCostWatch,
      switchBLCostCurrencyWatch,
      financeCostWatch,
      profitMarginExpectedWatch,
      discountWatch,
      currency1Watch,
      fxRateWatch ?? 1
    );
    setValue(finalQuoteSellingPrice, QuoteSellingPrice.toFixed(2));
  }, [
    cifPriceWatch,
    cifPriceCurrencyWatch,
    additionalCostWatch,
    additionalCostCurrencyWatch,
    financeCostWatch,
    profitMarginExpectedWatch,
    discountWatch,
    switchBLCostWatch,
    switchBLCostCurrencyWatch
  ]);

  const handleProductSelection = (event: any, param: any) => {
    if (param) {
      setValue(productName, param);
    } else {
      setValue(productName, null);
    }
  };

  return (
    <main className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <div className={css.headerText}>Product {index + 1}</div>
        <div className={css.headerIcons}>
          <ToolTip title="Delete">
            <div>
              <IconNode
                src={fieldLength > 1 ? Images.deleteRed : Images.delete}
                onClick={fieldLength > 1 ? handleDeleteClick : undefined}
              />
            </div>
          </ToolTip>
          <ToolTip title="Dublicate">
            <div>
              <IconNode src={Images.dublicateRed} onClick={() => handleDublicateQuote(index)} />
            </div>
          </ToolTip>
          <ToolTip title={isExpandedWatch ? 'Collapse' : 'Expand'}>
            <div>
              <IconNode
                src={isExpandedWatch ? Images.arrowUpBig : Images.arrowDownBig}
                onClick={() => setValue(`quote.${index}.isExpanded`, !isExpandedWatch)}
              />
            </div>
          </ToolTip>
        </div>
      </div>
      {isExpandedWatch && (
        <div className={css.contentWrapper}>
          <div className={css.rowWrapper}>
            <Controller
              name={productName}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <AutoComplete
                    {...field}
                    required
                    autoFocus={!isEdit}
                    label="Product"
                    placeholder="Select/Type"
                    options={productOptions}
                    onInputChange={filterProductOptions}
                    onInputSelection={handleProductSelection}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option: any) => inputValue === option.product_name
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          product_name: inputValue,
                          product_id: null
                        });
                      }
                      return filtered;
                    }}
                    keyOption="product_name"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing2}
                    freeSolo
                  />
                );
              }}
            />

            <QuotePackageWrapper
              label="Deal Quantity"
              unitPlaceholder="Unit"
              inputPlaceholder="Deal Quantity"
              input={dealQuantity}
              unit={dealQuantityUnit}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
              currency={false}
              inputRequired={true}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={productPackaging}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Product Packaging"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <QuotePackageWrapper
              label="MOQ"
              unitPlaceholder="Unit"
              inputPlaceholder="MOQ"
              input={moq}
              unit={moqUnit}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
              currency={false}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  Base Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Base Price"
              input={basePrice}
              unit={basePriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(fobPriceManualOverride, false, { shouldDirty: false });
                setValue(cfrPriceManualOverride, false, { shouldDirty: false });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
              }}
            />
            <QuotePackageWrapper
              label={
                <span>
                  Ex-Works Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Ex-Works Price"
              input={finalExWorksPrice}
              unit={finalExWorksPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(fobPriceManualOverride, false, { shouldDirty: false });
                setValue(cfrPriceManualOverride, false, { shouldDirty: false });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
                setValue(`quote.${index}.base_price.value`, 0);
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label="Total local freight"
              unitPlaceholder="Currency"
              inputPlaceholder="Total local freight"
              input={totalLocalFrieght}
              unit={totalLocalFrieghtCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
            />
            <QuotePackageWrapper
              label="Total Port and Clearance Expenses"
              unitPlaceholder="Currency"
              inputPlaceholder="Total Port and Clearance Expenses"
              input={totalPortAndClearanceExpenses}
              unit={totalPortAndClearanceExpensesCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={fcl ? 'Total weight in one container' : 'Total weight'}
              unitPlaceholder="Unit"
              inputPlaceholder="Total weight"
              input={totalWeight}
              unit={totalWeightUnit}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
              currency={false}
              inputRequired={true}
            />
            <QuotePackageWrapper
              label={
                <span>
                  FOB Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Fob Price"
              input={fobPrice}
              unit={fobPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(fobPriceManualOverride, true, { shouldDirty: true });
                setValue(cfrPriceManualOverride, false, { shouldDirty: false });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
                setValue(`quote.${index}.totalPortAndClearanceExpenses.value`, 0);
                setValue(`quote.${index}.totalLocalFreight.value`, 0);
                setValue(`quote.${index}.final_exWorks_price.value`, 0);
                setValue(`quote.${index}.base_price.value`, 0);
                setValue(finalExWorksPrice, 0);
              }}
            />
          </div>

          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={fcl ? 'Total freight per FCL ' : 'Total freight'}
              unitPlaceholder="Currency"
              inputPlaceholder="Total freight"
              input={totalFreight}
              unit={totalFreightCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
            />
            <QuotePackageWrapper
              label={
                <span>
                  CFR Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="CFR Price"
              input={cfrPrice}
              unit={cfrPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(cfrPriceManualOverride, true, { shouldDirty: true });
                setValue(cifPriceManualOverride, false, { shouldDirty: false });
                setValue(basePrice, 0);
                setValue(finalExWorksPrice, 0);
                setValue(`quote.${index}.fob_price.value`, 0);
                setValue(`quote.${index}.totalPortAndClearanceExpenses.value`, 0);
                setValue(`quote.${index}.totalLocalFreight.value`, 0);
                setValue(`quote.${index}.totalFreight.value`, 0);
                setValue(totalWeight, 0);
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={insuranceCost}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Insurance cost
                      <Typography variant="label"> ( % )</Typography>
                    </span>
                  }
                  placeholder="Enter value in percentage"
                  onChange={(e) => {
                    field.onChange(e);
                    resetField(`quote.${index}.cif_price.value`, {
                      keepDirty: false
                    });
                  }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <QuotePackageWrapper
              label={
                <span>
                  CIF Price
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="CIF Price"
              input={cifPrice}
              unit={cifPriceCurrency}
              control={control}
              unitOptions={selectedCurrency}
              inputOnChange={() => {
                setValue(cifPriceManualOverride, true, { shouldDirty: true });
                setValue(`quote.${index}.insuranceCost`, 0);
                setValue(`quote.${index}.cfr_price.value`, 0);
                setValue(basePrice, 0);
                setValue(finalExWorksPrice, 0);
                setValue(`quote.${index}.fob_price.value`, 0);
                setValue(`quote.${index}.totalPortAndClearanceExpenses.value`, 0);
                setValue(`quote.${index}.totalLocalFreight.value`, 0);
                setValue(`quote.${index}.totalFreight.value`, 0);
                setValue(totalWeight, 0);
              }}
              currency
            />
          </div>

          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  Additional Cost
                  {primaryUnitWatch && (
                    <Typography variant="label"> ( Per {primaryUnitWatch?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Additional Cost"
              input={additionalCost}
              unit={additionalCostCurrency}
              control={control}
              unitOptions={selectedCurrency}
              currency
            />
            {mtt && (
              <QuotePackageWrapper
                label="Switch BL Cost"
                unitPlaceholder="Currency"
                inputPlaceholder="Switch BL Cost"
                input={switchBlCost}
                unit={switchBLCostCurrency}
                control={control}
                unitOptions={selectedCurrency}
                currency
              />
            )}
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={financeCost}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Finance cost
                      <Typography variant="label"> ( % )</Typography>
                    </span>
                  }
                  placeholder="Enter value in percentage"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={profitMarginExpected}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Profit margin expected
                      <Typography variant="label"> ( % )</Typography>
                    </span>
                  }
                  required
                  placeholder="Enter value in percentage"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={discount}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Discount
                      <Typography variant="label"> ( % )</Typography>
                    </span>
                  }
                  placeholder="Enter value in percentage"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={incoTerms}
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  options={INCO_TERMS}
                  label="Inco Terms"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={portOfLoading}
              control={control}
              render={({ field, fieldState }) => {
                const onPortSelection = (event: any, param: any) => {
                  if (param) {
                    setValue(portOfLoading, param);
                  } else {
                    setValue(portOfLoading, null);
                  }
                };
                const formattedPortOfLoading = formatPortOptions(portOfLoadingOptions);
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Port Of Loading"
                    placeholder="Start typing"
                    options={formattedPortOfLoading}
                    onInputChange={(event: any, param: any) =>
                      filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_LOADING)
                    }
                    onInputSelection={onPortSelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
            <Controller
              name={portOfDischarge}
              control={control}
              render={({ field, fieldState }) => {
                const onPortSelection = (event: any, param: any) => {
                  if (param) {
                    setValue(portOfDischarge, param);
                  } else {
                    setValue(portOfDischarge, null);
                  }
                };
                const formattedPortOfDischarge = formatPortOptions(portOfDischargeOptions);
                return (
                  <AutoComplete
                    {...field}
                    label="Port Of Discharge"
                    placeholder="Start typing"
                    options={formattedPortOfDischarge}
                    onInputChange={(event: any, param: any) =>
                      filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_DISCHARGE)
                    }
                    onInputSelection={onPortSelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={countryOfOrigin}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Country of Origin"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
      )}
    </main>
  );
};

export default NonConsolidationQuote;
