import React, { useEffect } from 'react';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { AutoComplete, SelectLabel, TextField } from '@components/common';
import QuotePackageWrapper from '@components/common/quote-form/components/quote-generator/components/quote-package-wrapper';
import { INCO_TERMS } from '@helpers/constants';
import { createFilterOptions } from '@mui/material';
import { Button, IconNode, ToolTip, Typography } from '@components/base';
import {
  calculateCFRPrice,
  calculateCIFPrice,
  calculateExWorksPrice,
  calculateFobPrice,
  calculateQuotedPrice
} from '@helpers/utils';
import Images from '@assets/images';

const filter = createFilterOptions<any>();

const ConsolidatedQuote = (props: any) => {
  const {
    onFormSubmit,
    onClose,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    isFcl,
    isMtt,
    isEdit,
    productOptions,
    filterProductOptions,
    quoteDetail
  } = props;
  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
    resetField,
    handleSubmit
  } = useFormContext();

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove
  } = useFieldArray({
    name: `quote.product`,
    control
  });

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.mainWrapper}>
      <section className={css.formWrapper}>
        {productFields.map((item, index) => (
          <QuoteProductFields
            key={index}
            index={index}
            selectedWeightSystemChildOptions={selectedWeightSystemChildOptions}
            selectedCurrency={selectedCurrency}
            productOptions={productOptions}
            filterProductOptions={filterProductOptions}
            quoteDetail={quoteDetail}
          />
        ))}
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label="Total local freight"
            unitPlaceholder="Currency"
            inputPlaceholder="Total local freight"
            input={`quote.totalLocalFreight.value`}
            unit={`quote.totalLocalFreight.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
          <QuotePackageWrapper
            label="Total Port and Clearance Expenses"
            unitPlaceholder="Currency"
            inputPlaceholder="Total Port and Clearance Expenses"
            input={`quote.totalPortAndClearanceExpenses.value`}
            unit={`quote.totalPortAndClearanceExpenses.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label={isFcl ? 'Total weight in one container' : 'Total weight'}
            unitPlaceholder="Unit"
            inputPlaceholder="Total weight"
            input={`quote.totalWeight`}
            unit={`quote.totalWeightUnit`}
            control={control}
            unitOptions={selectedWeightSystemChildOptions}
            currency={false}
            inputRequired
          />
          <QuotePackageWrapper
            label={isFcl ? 'Total freight per FCL ' : 'Total freight'}
            unitPlaceholder="Currency"
            inputPlaceholder="Total freight"
            input={`quote.totalFreight.value`}
            unit={`quote.totalFreight.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.portOfLoading.name`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="POL"
                required
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`quote.portOfDischarge.name`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="POD"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.insuranceCost`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Insurance cost
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <QuotePackageWrapper
            label="Additional Cost"
            unitPlaceholder="Currency"
            inputPlaceholder="Additional Cost"
            input={`quote.additionalCost.value`}
            unit={`quote.additionalCost.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.financeCost`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Finance cost
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`quote.discount`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Discount
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.incoTerms`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                options={INCO_TERMS}
                label="Inco Terms"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </section>
      <section className={css.actionButtons}>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined-secondary" type="submit">
          {isEdit ? 'Update Quote' : 'Create Quote'}
        </Button>
      </section>
    </form>
  );
};

export default ConsolidatedQuote;

const QuoteProductFields = (props: any) => {
  const {
    index,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    productOptions,
    filterProductOptions,
    quoteDetail
  } = props;

  const { currency1, currency2, fxRate, primaryUnit } = quoteDetail;

  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
    resetField
  } = useFormContext();

  const basePriceWatch = useWatch({
    control,
    name: `quote.product.${index}.base_price.value`
  });

  const basePriceCurrencyWatch = useWatch({
    control,
    name: `quote.product.${index}.base_price.currency`
  });

  const finalExWorksPriceWatch = useWatch({
    control,
    name: `quote.product.${index}.final_exWorks_price.value`
  });

  const finalExWorksPriceCurrencyWatch = useWatch({
    control,
    name: `quote.product.${index}.final_exWorks_price.currency`
  });

  const dealQuantityWatch = useWatch({
    control,
    name: `quote.product.${index}.dealQuantity`
  });

  const dealQuantityUnitWatch = useWatch({
    control,
    name: `quote.product.${index}.dealQuantityUnit`
  });

  const fobPriceCurrencyWatch = useWatch({
    control,
    name: `quote.product.${index}.fob_price.currency`
  });

  const fobPriceWatch = useWatch({
    control,
    name: `quote.product.${index}.fob_price.value`
  });

  const fobPriceManualOverrideWatch = useWatch({
    control,
    name: `quote.product.${index}.fob_price.manualOverride`
  });

  const cifPriceWatch = useWatch({
    control,
    name: `quote.product.${index}.cif_price.value`
  });

  const cifPriceCurrencyWatch = useWatch({
    control,
    name: `quote.product.${index}.cif_price.currency`
  });

  const cifPriceManualOverrideWatch = useWatch({
    control,
    name: `quote.product.${index}.cif_price.manualOverride`
  });

  const cfrPriceCurrencyWatch = useWatch({
    control,
    name: `quote.product.${index}.cfr_price.currency`
  });

  const cfrPriceWatch = useWatch({
    control,
    name: `quote.product.${index}.cfr_price.value`
  });

  const cfrPriceManualOverrideWatch = useWatch({
    control,
    name: `quote.product.${index}.cfr_price.manualOverride`
  });

  const profitMarginExpectedWatch = useWatch({
    control,
    name: `quote.product.${index}.profitMarginExpected`
  });

  const totalLocalFreightWatch = useWatch({
    control,
    name: `quote.totalLocalFreight.value`
  });

  const totalLocalFrieghtCurrencyWatch = useWatch({
    control,
    name: `quote.totalLocalFreight.currency`
  });

  const totalPortAndClearanceExpensesWatch = useWatch({
    control,
    name: `quote.totalPortAndClearanceExpenses.value`
  });

  const totalPortAndClearanceExpensesCurrencyWatch = useWatch({
    control,
    name: `quote.totalPortAndClearanceExpenses.currency`
  });

  const totalWeightWatch = useWatch({
    control,
    name: `quote.totalWeight`
  });

  const totalWeightUnitWatch = useWatch({
    control,
    name: `quote.totalWeightUnit`
  });

  const totalFreightWatch = useWatch({
    control,
    name: `quote.totalFreight.value`
  });

  const totalFreightCurrencyWatch = useWatch({
    control,
    name: `quote.totalFreight.currency`
  });

  const additionalCostWatch = useWatch({
    control,
    name: `quote.additionalCost.value`
  });

  const additionalCostCurrencyWatch = useWatch({
    control,
    name: `quote.additionalCost.currency`
  });

  const financeCostWatch = useWatch({
    control,
    name: `quote.financeCost`
  });

  const discountWatch = useWatch({
    control,
    name: `quote.discount`
  });

  const insuranceCostWatch = useWatch({
    control,
    name: `quote.insuranceCost`
  });

  const isExpandedWatch = useWatch({
    control,
    name: `quote.product.${index}.isExpanded`
  });

  const switchBLCostCurrencyWatch = useWatch({
    control,
    name: `quote.switchBLCost.currency`
  });

  const switchBLCostWatch = useWatch({
    control,
    name: `quote.switchBLCost.value`
  });

  // Ex-Works Price Calculation
  useEffect(() => {
    if (!basePriceWatch) {
      return;
    }

    const exWorksPrice = calculateExWorksPrice(
      basePriceCurrencyWatch,
      currency1,
      basePriceWatch,
      fxRate || 1,
      finalExWorksPriceCurrencyWatch
    );
    setValue(`quote.product.${index}.final_exWorks_price.value`, exWorksPrice.toFixed(2));
  }, [basePriceWatch, fxRate, currency1, basePriceCurrencyWatch, finalExWorksPriceCurrencyWatch]);

  // FOB Price Calculation
  useEffect(() => {
    if (fobPriceManualOverrideWatch) return;
    if (!totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }
    const finalFobPrice = calculateFobPrice(
      totalLocalFreightWatch,
      totalLocalFrieghtCurrencyWatch,
      totalPortAndClearanceExpensesWatch,
      totalPortAndClearanceExpensesCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRate || 1,
      currency1,
      dealQuantityUnitWatch,
      fobPriceCurrencyWatch,
      finalExWorksPriceWatch,
      finalExWorksPriceCurrencyWatch
    );
    const newValue = finalFobPrice.toFixed(2);
    const currentValue = getValues(`quote.product.${index}.fob_price.value`);
    if (newValue !== currentValue) {
      setValue(`quote.product.${index}.fob_price.value`, finalFobPrice.toFixed(2));
    } else return;
  }, [
    totalLocalFreightWatch,
    totalLocalFrieghtCurrencyWatch,
    totalPortAndClearanceExpensesWatch,
    totalPortAndClearanceExpensesCurrencyWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    finalExWorksPriceWatch,
    dealQuantityUnitWatch,
    fobPriceCurrencyWatch,
    fxRate,
    basePriceWatch,
    finalExWorksPriceCurrencyWatch,
    fobPriceManualOverrideWatch
  ]);

  // CFR Price
  useEffect(() => {
    if (cfrPriceManualOverrideWatch) return;
    if (!fxRate || !totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }
    const finalCfrPrice = calculateCFRPrice(
      totalFreightWatch,
      totalFreightCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRate || 1,
      cfrPriceCurrencyWatch,
      currency1,
      fobPriceWatch,
      fobPriceCurrencyWatch,
      dealQuantityUnitWatch
    );
    const currentValue = getValues(`quote.product.${index}.cfr_price.value`);
    if (finalCfrPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(`quote.product.${index}.cfr_price.value`, finalCfrPrice.toFixed(2));
  }, [
    fobPriceCurrencyWatch,
    fobPriceWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    totalFreightWatch,
    totalFreightCurrencyWatch,
    cfrPriceCurrencyWatch,
    dealQuantityUnitWatch,
    cfrPriceManualOverrideWatch
  ]);

  // CIF Price Calculation
  useEffect(() => {
    if (cifPriceManualOverrideWatch) return;
    if (!fxRate) {
      return;
    }
    const finalCIFPrice = calculateCIFPrice(
      fxRate || 1,
      currency1,
      cfrPriceWatch,
      cfrPriceCurrencyWatch,
      insuranceCostWatch,
      cifPriceCurrencyWatch
    );
    const currentValue = getValues(`quote.product.${index}.cif_price.value`);
    if (finalCIFPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(`quote.product.${index}.cif_price.value`, finalCIFPrice.toFixed(2));
  }, [insuranceCostWatch, cfrPriceWatch, cifPriceCurrencyWatch, cfrPriceCurrencyWatch]);

  // Quote Selling Price Calculation
  useEffect(() => {
    const QuoteSellingPrice = calculateQuotedPrice(
      cifPriceWatch,
      cifPriceCurrencyWatch,
      additionalCostWatch,
      additionalCostCurrencyWatch,
      switchBLCostWatch,
      switchBLCostCurrencyWatch,
      financeCostWatch,
      profitMarginExpectedWatch,
      discountWatch,
      currency1,
      fxRate || 1
    );
    setValue(`quote.product.${index}.finalQuote_selling_price.value`, QuoteSellingPrice.toFixed(2));
  }, [
    cifPriceWatch,
    cifPriceCurrencyWatch,
    additionalCostWatch,
    additionalCostCurrencyWatch,
    financeCostWatch,
    profitMarginExpectedWatch,
    discountWatch,
    cifPriceManualOverrideWatch
  ]);

  const handleProductSelection = (event: any, param: any) => {
    if (param) {
      setValue(`quote.product.${index}.productName`, param);
    } else {
      setValue(`quote.product.${index}.productName`, null);
    }
  };

  return (
    <div className={css.productWrapper}>
      <div className={css.productHeaderIcons}>
        <h3>Product {index + 1}</h3>
        <ToolTip title={isExpandedWatch ? 'Collapse' : 'Expand'}>
          <div>
            <IconNode
              src={isExpandedWatch ? Images.arrowUpBig : Images.arrowDownBig}
              onClick={() => setValue(`quote.product.${index}.isExpanded`, !isExpandedWatch)}
            />
          </div>
        </ToolTip>
      </div>
      {isExpandedWatch && (
        <>
          <div className={css.rowWrapper}>
            <Controller
              name={`quote.product.${index}.productName`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Product"
                    placeholder="Select/Type"
                    disabled={true}
                    options={productOptions}
                    onInputChange={filterProductOptions}
                    onInputSelection={handleProductSelection}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option: any) => inputValue === option.product_name
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          product_name: inputValue,
                          product_id: null
                        });
                      }
                      return filtered;
                    }}
                    keyOption="product_name"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing2}
                    freeSolo
                  />
                );
              }}
            />
            <QuotePackageWrapper
              label="Deal Quantity"
              unitPlaceholder="Unit"
              inputPlaceholder="Deal Quantity"
              input={`quote.product.${index}.dealQuantity`}
              unit={`quote.product.${index}.dealQuantityUnit`}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
              currency={false}
              inputRequired
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label="MOQ"
              unitPlaceholder="Unit"
              inputPlaceholder="MOQ"
              input={`quote.product.${index}.moq`}
              unit={`quote.product.${index}.moqUnit`}
              control={control}
              unitOptions={selectedWeightSystemChildOptions}
            />
            <Controller
              name={`quote.product.${index}.productPackaging`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Product Packaging"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  Base Price
                  {primaryUnit && (
                    <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Base Price"
              input={`quote.product.${index}.base_price.value`}
              unit={`quote.product.${index}.base_price.currency`}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(`quote.product.${index}.fob_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.cfr_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.cif_price.manualOverride`, false, {
                  shouldDirty: false
                });
              }}
            />
            <QuotePackageWrapper
              label={
                <span>
                  Ex-Works Price
                  {primaryUnit && (
                    <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Ex-Works Price"
              input={`quote.product.${index}.final_exWorks_price.value`}
              unit={`quote.product.${index}.final_exWorks_price.currency`}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(`quote.product.${index}.fob_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.cfr_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.cif_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.base_price.value`, 0);
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  Fob Price
                  {primaryUnit && (
                    <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="Fob Price"
              input={`quote.product.${index}.fob_price.value`}
              unit={`quote.product.${index}.fob_price.currency`}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(`quote.product.${index}.fob_price.manualOverride`, true, {
                  shouldDirty: true
                });
                setValue(`quote.product.${index}.cfr_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.cif_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.base_price.value`, 0);
                setValue(`quote.product.${index}.final_exWorks_price.value`, 0);
              }}
            />
            <QuotePackageWrapper
              label={
                <span>
                  CFR Price
                  {primaryUnit && (
                    <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="CFR Price"
              input={`quote.product.${index}.cfr_price.value`}
              unit={`quote.product.${index}.cfr_price.currency`}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(`quote.product.${index}.cfr_price.manualOverride`, true, {
                  shouldDirty: true
                });
                setValue(`quote.product.${index}.cif_price.manualOverride`, false, {
                  shouldDirty: false
                });
                setValue(`quote.product.${index}.final_exWorks_price.value`, 0);
                setValue(`quote.product.${index}.base_price.value`, 0);
                setValue(`quote.product.${index}.fob_price.value`, 0);
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <QuotePackageWrapper
              label={
                <span>
                  CIF Price
                  {primaryUnit && (
                    <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                  )}
                </span>
              }
              unitPlaceholder="Currency"
              inputPlaceholder="CIF Price"
              input={`quote.product.${index}.cif_price.value`}
              unit={`quote.product.${index}.cif_price.currency`}
              control={control}
              unitOptions={selectedCurrency}
              currency
              inputOnChange={() => {
                setValue(`quote.product.${index}.cif_price.manualOverride`, true, {
                  shouldDirty: true
                });
                setValue(`quote.product.${index}.final_exWorks_price.value`, 0);
                setValue(`quote.product.${index}.base_price.value`, 0);
                setValue(`quote.product.${index}.fob_price.value`, 0);
                setValue(`quote.product.${index}.cfr_price.value`, 0);
              }}
            />
            <Controller
              name={`quote.product.${index}.profitMarginExpected`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Profit margin expected
                      <Typography variant="label"> ( % )</Typography>
                    </span>
                  }
                  required
                  placeholder="Enter value in percentage"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={`quote.product.${index}.countryOfOrigin`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Country of Origin"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};
