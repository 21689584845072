import { getCommaSeperatedCurrencyAmount } from '@helpers/utils';
import css from './index.module.scss';
import { useFormContext, useWatch } from 'react-hook-form';

const QuoteCostCalculator = (props: any) => {
  const { consolidation, currency } = props;
  const { control } = useFormContext();

  const quote = useWatch({
    name: 'quote',
    control
  });

  return (
    <main className={css.mainWrapper}>
      <div className={css.wrapper}>
        <div className={css.header}>Cost calculator</div>

        {consolidation
          ? quote?.product?.map((product: any, index: number) => {
              const productName = product.productName?.product_name;
              const finalQuotePrice = product.finalQuote_selling_price?.value;
              const finalExWorksPriceCurrency = product.final_exWorks_price?.currency;
              const finalExWorksPrice = product.final_exWorks_price?.value;
              const finalFobPriceCurrency = product.fob_price?.currency;
              const finalFobPrice = product.fob_price?.value;
              const finalCfrPriceCurrency = product.cfr_price?.currency;
              const finalCfrPrice = product.cfr_price?.value;
              const finalCifPriceCurrency = product.cif_price?.currency;
              const finalCifPrice = product.cif_price?.value;
              const isExpanded = product.isExpanded; // Adjust if needed

              return (
                <div key={index} className={css.cardWrapper}>
                  <div className={css.cardHeader}>
                    {productName ? productName : `Product ${index + 1}`}
                  </div>
                  {isExpanded && (
                    <>
                      <div className={css.cardQuotePrice}>
                        <div className={css.finalQuotePrice}>Final Selling Price:</div>
                        <div className={css.finalQuotePriceValue}>
                          {currency
                            ? getCommaSeperatedCurrencyAmount(currency.label, finalQuotePrice ?? 0)
                            : 0}
                        </div>
                      </div>
                      <div className={css.cardContent}>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>EX Works</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {currency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalExWorksPriceCurrency.label,
                                  finalExWorksPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>FOB</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalFobPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalFobPriceCurrency.label,
                                  finalFobPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>CFR</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalCfrPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalCfrPriceCurrency.label,
                                  finalCfrPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>CIF</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalCifPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalCifPriceCurrency.label,
                                  finalCifPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })
          : (() => {
              const productName = quote?.productName?.product_name;
              const finalQuotePrice = quote?.finalQuote_selling_price?.value;
              const finalExWorksPriceCurrency = quote?.final_exWorks_price?.currency;
              const finalExWorksPrice = quote?.final_exWorks_price?.value;
              const finalFobPriceCurrency = quote?.fob_price?.currency;
              const finalFobPrice = quote?.fob_price?.value;
              const finalCfrPriceCurrency = quote?.cfr_price?.currency;
              const finalCfrPrice = quote?.cfr_price?.value;
              const finalCifPriceCurrency = quote?.cif_price?.currency;
              const finalCifPrice = quote?.cif_price?.value;
              const isExpanded = true;

              return (
                <div className={css.cardWrapper}>
                  <div className={css.cardHeader}>{productName ? productName : `Product`}</div>
                  {isExpanded && (
                    <>
                      <div className={css.cardQuotePrice}>
                        <div className={css.finalQuotePrice}>Final Selling Price:</div>
                        <div className={css.finalQuotePriceValue}>
                          {currency
                            ? getCommaSeperatedCurrencyAmount(currency.label, finalQuotePrice ?? 0)
                            : 0}
                        </div>
                      </div>
                      <div className={css.cardContent}>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>EX Works</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {currency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalExWorksPriceCurrency.label,
                                  finalExWorksPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>FOB</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalFobPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalFobPriceCurrency.label,
                                  finalFobPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>CFR</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalCfrPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalCfrPriceCurrency.label,
                                  finalCfrPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>CIF</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalCifPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalCifPriceCurrency.label,
                                  finalCifPrice ?? 0
                                )
                              : 0}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })()}
      </div>
    </main>
  );
};

export default QuoteCostCalculator;
