import { SERVER_CONFIG } from '@helpers/constants';
import httpClient from './index.service';

export async function fetchQuoteProductList(params?: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.formFilterOptions.index}/${
        SERVER_CONFIG.product
      }?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchQuoteCustomerList(params?: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.formFilterOptions.index}/${
        SERVER_CONFIG.updateCustomer
      }?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export const submitNonConsolidationQuote = async (data: any) => {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}`,
      data
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const submitConsolidationQuote = async (data: any) => {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}/${SERVER_CONFIG.consolidation}`,
      data
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getEnquiryDetails = async (enquiryId: string) => {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}/${enquiryId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const addQuote = async (enquiryId = '', data: any) => {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}/${SERVER_CONFIG.quote}${enquiryId}/`,
      data
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const updateQuote = async (enquiryId = '', data: any) => {
  try {
    const response = await httpClient.put(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}/${SERVER_CONFIG.quote}${enquiryId}/`,
      data
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const updateNonConsolidationEnquiry = async (enquiryId: string, data: any) => {
  try {
    const response = await httpClient.put(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}/${enquiryId}/`,
      data
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getEnquiryList = async (params: any) => {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.enquiry}?${
        paramList?.length ? paramList.join('&') : ``
      }`
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getGeneratePDFOptions = async () => {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.formFilterOptions.index}/${SERVER_CONFIG.formFilterOptions.pdfOptions}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const submitGeneratePDF = async (enquiryId: string, reqBody: any) => {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.pdf}${enquiryId}/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getQuoteDocuments = async (enquiryId: string) => {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.quoteGeneration}${SERVER_CONFIG.pdf}${enquiryId}/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
};
