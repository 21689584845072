/* eslint-disable @typescript-eslint/no-var-requires */
import { ReactComponent as CalendarIcon } from '@assets/images/calendar-grey.svg';
import { ClickAwayListener, DatePicker } from '@components/base';
import { FocusEvent, Ref, forwardRef, useCallback, useState } from 'react';
import { ActiveModifiers, DayPickerSingleProps, UseInputOptions, useInput } from 'react-day-picker';
import TextField from '../text-field';
import css from './index.module.scss';
const moment = require('moment');

interface InputDatePickerStates {
  isOpen: boolean;
}

interface InputDatePickerProps extends Omit<DayPickerSingleProps, 'mode'> {
  required?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  rootClassName?: string;
  value?: Date | null;
  inputOptions?: UseInputOptions;
  setDatePickerOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  dayDisabled?: DayPickerSingleProps['disabled'];
}

const InputDatePicker = forwardRef((props: InputDatePickerProps, ref: Ref<HTMLInputElement>) => {
  const {
    label,
    error,
    helperText,
    required,
    placeholder,
    value,
    inputOptions,
    rootClassName,
    setDatePickerOpen,
    dayDisabled,
    ...otherProps
  } = props;

  const [pickerState, setPickerState] = useState<InputDatePickerStates>({
    isOpen: false
  });

  const { isOpen } = pickerState;
  const { inputProps, dayPickerProps } = useInput(inputOptions);

  const { onFocus, ...restInputProps } = inputProps;

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setPickerState((prevState) => ({ ...prevState, isOpen: true }));
    onFocus?.(event);
    if (setDatePickerOpen) setDatePickerOpen(true);
  }, []);

  const handleClickAway = useCallback(() => {
    setPickerState((prevState) => ({ ...prevState, isOpen: false }));
    if (setDatePickerOpen) setDatePickerOpen(false);
  }, []);

  const handleSelect = useCallback(
    (
      day: Date | undefined,
      selectedDay: Date,
      activeModifiers: ActiveModifiers,
      e: React.MouseEvent
    ) => {
      const { onSelect } = props;
      setPickerState((prevState) => ({ ...prevState, isOpen: false }));
      onSelect?.(day, selectedDay, activeModifiers, e);
    },
    [props.onSelect]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`${css.mainWrapper} ${rootClassName}`}>
        <TextField
          {...restInputProps}
          value={`${
            moment(value, 'DD-MM-YYYY', true).isValid() ? moment(value).format('DD-MM-YYYY') : ''
          }`}
          ref={ref}
          readOnly
          required={required}
          label={label}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          onFocus={handleFocus}
          disabled={props.disabled ?? (false as any)}
          endIcon={<CalendarIcon />}
        />
        {isOpen && (
          <div className={`${css.dropdownWrapper}`}>
            <DatePicker
              {...otherProps}
              disabled={dayDisabled}
              {...dayPickerProps}
              onSelect={handleSelect}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
});

InputDatePicker.defaultProps = {
  placeholder: 'DD-MM-YYYY'
};

export default InputDatePicker;
