import { Badge, Button, Chip, Drawer, IconNode, ToolTip, Typography } from '@components/base';
import css from './index.module.scss';
import { useEffect, useState } from 'react';
import {
  getCommaSeperatedCurrencyAmount,
  getFormattedDate,
  getFormattedTime,
  sanitizeValue
} from '@helpers/utils';
import { useNavigate } from 'react-router-dom';
import AddEditQuote from '@pages/quote/quote-detail/components/add-edit-quote';
import { CURRENCY, WEIGHT_SYSTEM } from '@helpers/constants';
import ViewQuoteModal from '@pages/quote/quote-detail/components/view-quote';
import { DeletePrompt } from '@components/common';
import { endOfDay, format } from 'date-fns';
import { set } from 'lodash';
import QuoteForm from '@components/common/quote-form';
import notify from '@helpers/toastify-helper';
import { getEnquiryDetails } from '@services/quote.service';
import Images from '@assets/images';

const EnquiryCard = (props: any) => {
  const { data } = props;
  const navigate = useNavigate();

  const [enquiryCardState, setEnquiryCardState] = useState<any>({
    detailView: false,
    showAddQuote: false,
    isConsolidation: false,
    quoteDetails: null,
    addQuoteProductData: null,
    showViewQuote: false,
    viewQuoteData: null,
    showDisclaimer: false,
    primaryUnit: null,
    showQuoteForm: false,
    quoteFormData: null
  });

  const {
    detailView,
    showAddQuote,
    isConsolidation,
    quoteDetails,
    addQuoteProductData,
    showViewQuote,
    viewQuoteData,
    showDisclaimer,
    primaryUnit,
    showQuoteForm,
    quoteFormData
  } = enquiryCardState;

  useEffect(() => {
    setEnquiryCardState((prevState: any) => {
      return {
        ...prevState,
        isConsolidation: data?.is_consolidation,
        quoteDetails: {
          currency1: CURRENCY.find((item) => item.value === data.currency_type_one),
          currency2: CURRENCY.find((item) => item.value === data.currency_type_two),
          fxRate: data.fx_rate,
          selectedWeightSystemChildOptions: WEIGHT_SYSTEM.find(
            (item) => item.value === data.weight_system
          )?.childOptions,
          selectedCurrency: [
            CURRENCY.find((item) => item.value === data.currency_type_one),
            CURRENCY.find((item) => item.value === data.currency_type_two)
          ],
          primaryUnit:
            WEIGHT_SYSTEM.find((item) => item.value === data.weight_system)?.childOptions?.find(
              (item) => item.value === data.primary_unit
            ) ?? null
        }
      };
    });
  }, [data]);

  const transformData = (data: any) => {
    const firstQuote = data[0].latest_quote;
    const transformed: any = {
      consolidation_details_id: firstQuote.consolidation_id || '',
      total_weight: firstQuote.total_weight || '',
      total_weight_unit: firstQuote.total_weight_unit || '',
      total_local_freight: firstQuote.total_local_freight || {},
      total_port_and_clearance_expenses: firstQuote.total_port_and_clearance_expenses || {},
      total_freight: firstQuote.total_freight || {},
      pol: firstQuote.pol || {},
      pod: firstQuote.pod || {},
      insurance_cost: firstQuote.insurance_cost || null,
      additional_cost: firstQuote.additional_cost || {},
      discount: firstQuote.discount || null,
      deal_incoterm: firstQuote.deal_incoterm || '',
      finance_cost: firstQuote.finance_cost || null
    };
    transformed.products = data.map((item: any) => {
      const quote = item.latest_quote;
      return {
        quote_id: quote.consolidation_id,
        product_data: {
          name: item.product_data.product_name,
          id: item.product_data.product_id
        },
        quantity: Number(quote.quantity) || 0,
        quantity_unit: quote.quantity_unit || '',
        moq: Number(quote.moq) || 0,
        moq_unit: quote.moq_unit || '',
        base_price: quote.base_price || {},
        ex_works_price: quote.ex_works_price || {},
        fob_price: quote.fob_price || {},
        cfr_price: quote.cfr_price || {},
        cif_price: quote.cif_price || {},
        final_price: quote.final_price || {},
        profit_margin: Number(quote.profit_margin) || 0,
        packaging: quote.packaging || '',
        origin: quote.origin || ''
      };
    });
    return transformed;
  };

  const openViewQuoteModal = (product: any) => {
    const productData = isConsolidation
      ? transformData(product)
      : {
          ...product.latest_quote,
          product_name: product.product_data?.product_name,
          product_id: product.product_data?.product_id
        };
    setEnquiryCardState(() => {
      return {
        ...enquiryCardState,
        showViewQuote: true,
        viewQuoteData: productData
      };
    });
  };

  const closeViewQuoteModal = () => {
    setEnquiryCardState(() => {
      return {
        ...enquiryCardState,
        showViewQuote: false
      };
    });
  };

  const getFormattedDateWithoutYear = (date: any) => {
    if (date) {
      return format(endOfDay(new Date(date).getTime()), 'do LLL');
    } else {
      return null;
    }
  };

  const fetchEnquiryDetail = async (enquiry_id: string) => {
    const response = await getEnquiryDetails(enquiry_id);
    if (response?.success) {
      const { data } = response;
      setEnquiryCardState(() => {
        return {
          ...enquiryCardState,
          showQuoteForm: true,
          quoteFormData: data
        };
      });
    } else if (response?.error) {
      notify({
        severity: 'error',
        message: 'Something Went Wrong' // response?.error?.message
      });
    }
  };

  return (
    <main className={css.mainWrapper}>
      <div className={css.EnquiryHeader}>
        <div className={css.EnquiryHeaderText}>
          <div className={css.keyValue}>
            <Typography variant="label">Enquiry Number</Typography>
            <Typography variant="h5">{sanitizeValue(data?.readable_enquiry_number)}</Typography>
          </div>
          <div className={css.keyValue}>
            <Typography variant="label">Date</Typography>
            <Typography variant="body">
              {sanitizeValue(getFormattedDate(data?.created_at), '-')}
            </Typography>
          </div>
          <div className={css.keyValue}>
            <Typography variant="label">Customer</Typography>
            <Typography variant="h5">{sanitizeValue(data?.customer_name)}</Typography>
          </div>
          <div className={css.keyValue}>
            <Typography variant="label">Deal Name</Typography>
            <Typography variant="body">{sanitizeValue(data?.deal_name)}</Typography>
          </div>
          <div className={css.keyValue}>
            <Typography variant="label">Created By</Typography>
            <Typography variant="h5">
              {sanitizeValue(data?.created_by.given_name + ' ' + data?.created_by.family_name)}
            </Typography>
          </div>
          <div className={css.filterStates}>
            <Chip
              className={css.chips}
              variant="filled-blue"
              label={data.container_type == 'FCL' ? 'FCL' : 'LCL'}
            />
            <Chip
              className={css.chips}
              variant="filled-yellow"
              label={data.sourcing_type == 'MTT' ? 'MTT' : 'Non-MTT'}
            />
            <Chip
              className={css.chips}
              variant="filled-green"
              label={data.is_consolidation ? 'Consolidation' : 'Non-Consolidation'}
            />
          </div>
        </div>
        <div className={css.EnquiryHeaderButton}>
          <ToolTip title="Duplicate this Enquiry" placement="left">
            <div>
              <IconNode
                src={Images.dublicateRed}
                alt="Duplicate Enquiry"
                className={css.editIcon}
                onClick={() => {
                  fetchEnquiryDetail(data.enquiry_id);
                }}
              />
            </div>
          </ToolTip>
          <Button
            variant="outlined-secondary"
            onClick={() => {
              navigate(`/quote/detail/${data.enquiry_id}`);
            }}>
            View Enquiry
          </Button>
        </div>
      </div>
      <>
        <div className={css.arrowIconWrapper}>
          {detailView ? (
            <div
              className={css.badgeWrapper}
              onClick={() =>
                setEnquiryCardState(() => {
                  return {
                    ...enquiryCardState,
                    detailView: false
                  };
                })
              }>
              <Badge className={css.badge}>Close</Badge>
            </div>
          ) : (
            <div
              className={css.badgeWrapper}
              onClick={() =>
                setEnquiryCardState(() => {
                  return {
                    ...enquiryCardState,
                    detailView: true
                  };
                })
              }>
              <Badge className={css.badge}>
                {data.product_details?.length > 1 ? 'View Products' : 'View Product'}
              </Badge>
            </div>
          )}
        </div>
      </>
      {detailView &&
        (isConsolidation
          ? (() => {
              const groupedProducts = data.product_details.reduce((acc: any, product: any) => {
                const { consolidation_id } = product.latest_quote;
                if (!acc[consolidation_id]) {
                  acc[consolidation_id] = [];
                }
                acc[consolidation_id].push(product);
                return acc;
              }, {});
              return Object.keys(groupedProducts).map(
                (consolidationId: string, consolidationIndex: number) => (
                  <div key={consolidationId} className={css.ConsolidationProductWrapper}>
                    <div className={css.ConsolidationHeader}>
                      <Typography variant="h5">Consolidation {consolidationIndex + 1}</Typography>
                      <div className={css.buttonWrapper}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setEnquiryCardState((prevState: any) => {
                              const productData = {
                                products: groupedProducts[consolidationId].map((item: any) => ({
                                  product_data: {
                                    name: item.product_data?.product_name,
                                    id: item.product_data?.product_id
                                  }
                                }))
                              };
                              return {
                                ...prevState,
                                showAddQuote: true,
                                addQuoteProductData: productData
                              };
                            });
                          }}>
                          Add Quote
                        </Button>
                        <Button
                          variant="outlined-secondary"
                          onClick={() => {
                            openViewQuoteModal(groupedProducts[consolidationId]);
                          }}>
                          View Quote
                        </Button>
                      </div>
                    </div>
                    {groupedProducts[consolidationId].map((product: any, index: number) => (
                      <div key={index} className={css.consolidationProductDetails}>
                        <div className={css.ProductDetails}>
                          <div className={css.keyValue}>
                            <div className={css.productName}>
                              {sanitizeValue(
                                product.product_data?.product_name,
                                `Product ${index + 1}`
                              )}
                            </div>
                          </div>
                          <div className={css.keyValue}>
                            <Typography variant="label">POL</Typography>
                            <Typography variant="body">
                              {sanitizeValue(product?.latest_quote?.pol?.name)}
                            </Typography>
                          </div>
                          <div className={css.keyValue}>
                            <Typography variant="label">POD</Typography>
                            <Typography variant="body">
                              {sanitizeValue(product?.latest_quote?.pod?.name)}
                            </Typography>
                          </div>
                          <div className={css.keyValue}>
                            <Typography variant="label">Quoted Amount</Typography>
                            <Typography variant="h5">
                              {product?.latest_quote?.final_price?.currency
                                ? getCommaSeperatedCurrencyAmount(
                                    product?.latest_quote?.final_price?.currency,
                                    product?.latest_quote?.final_price?.value
                                  )
                                : '-'}
                            </Typography>
                          </div>
                          <div className={css.keyValue}>
                            <Typography variant="label">Deal Inco Term</Typography>
                            <Typography variant="body">
                              {sanitizeValue(product?.latest_quote?.deal_incoterm?.value, '-')}
                            </Typography>
                          </div>
                          <div className={css.keyValue}>
                            <Typography variant="label">Date</Typography>
                            <Typography variant="body">
                              {sanitizeValue(
                                getFormattedDateWithoutYear(product?.latest_quote?.created_at)
                              )}{' '}
                              {getFormattedTime(product?.latest_quote?.created_at)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              );
            })()
          : (data.product_details || []).map((product: any, index: number) => (
              <div key={index} className={css.ProductWrapper}>
                <div className={css.ProductDetails}>
                  <div className={css.keyValue}>
                    <div className={css.productName}>
                      {sanitizeValue(product.product_data?.product_name, `Product ${index + 1}`)}
                    </div>
                  </div>
                  <div className={css.keyValue}>
                    <Typography variant="label">POL</Typography>
                    <Typography variant="body">
                      {sanitizeValue(product?.latest_quote?.pol?.name, 'N.A.')}
                    </Typography>
                  </div>
                  <div className={css.keyValue}>
                    <Typography variant="label">POD</Typography>
                    <Typography variant="body">
                      {sanitizeValue(product?.latest_quote?.pod?.name, 'N.A.')}
                    </Typography>
                  </div>
                  <div className={css.keyValue}>
                    <Typography variant="label">Quoted Amount</Typography>
                    <Typography variant="h5">
                      {product?.latest_quote?.final_price?.currency
                        ? getCommaSeperatedCurrencyAmount(
                            product?.latest_quote?.final_price?.currency,
                            product?.latest_quote?.final_price?.value
                          )
                        : '-'}
                    </Typography>
                  </div>
                  <div className={css.keyValue}>
                    <Typography variant="label">Deal Inco Term</Typography>
                    <Typography variant="body">
                      {sanitizeValue(product?.latest_quote?.deal_incoterm, '-')}
                    </Typography>
                  </div>
                  <div className={css.keyValue}>
                    <Typography variant="label">Date</Typography>
                    <Typography variant="body">
                      {sanitizeValue(
                        getFormattedDateWithoutYear(product?.latest_quote?.created_at)
                      )}{' '}
                      {getFormattedTime(product?.latest_quote?.created_at)}
                    </Typography>
                  </div>
                </div>
                <div className={css.ProductView}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setEnquiryCardState(() => {
                        return {
                          ...enquiryCardState,
                          showAddQuote: true,
                          addQuoteProductData: product
                        };
                      });
                    }}>
                    Add Quote
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      openViewQuoteModal(product);
                    }}>
                    View
                  </Button>
                </div>
              </div>
            )))}

      {showAddQuote && (
        <AddEditQuote
          open={showAddQuote}
          onClose={() => {
            setEnquiryCardState(() => {
              return {
                ...enquiryCardState,
                showDisclaimer: true
              };
            });
          }}
          onSubmitClose={() => {
            setEnquiryCardState(() => {
              return {
                ...enquiryCardState,
                showAddQuote: false
              };
            });
          }}
          isConsolidation={isConsolidation}
          isFcl={data.container_type == 'FCL'}
          isMtt={data.sourcing_type == 'MTT'}
          quoteDetail={quoteDetails}
          isNew={true}
          enquiryId={data.enquiry_id}
          data={addQuoteProductData}
        />
      )}

      {showViewQuote && (
        <ViewQuoteModal
          open={showViewQuote}
          onClose={closeViewQuoteModal}
          data={viewQuoteData}
          isConsolidation={isConsolidation}
          primaryUnit={primaryUnit ? primaryUnit.value : ''}
        />
      )}

      {showDisclaimer && (
        <DeletePrompt
          open={showDisclaimer}
          onClose={() =>
            setEnquiryCardState((prevState: any) => ({
              ...prevState,
              showDisclaimer: false
            }))
          }
          onConfirm={() =>
            setEnquiryCardState((prevState: any) => ({
              ...prevState,
              showDisclaimer: false,
              showAddQuote: false,
              showQuoteForm: false
            }))
          }
          message="Are you sure you want to close?"
        />
      )}

      <Drawer
        open={showQuoteForm}
        anchor="right"
        onClose={() =>
          setEnquiryCardState(() => {
            return {
              ...enquiryCardState,
              showDisclaimer: true
            };
          })
        }>
        <QuoteForm
          onClose={() => {
            setEnquiryCardState(() => {
              return {
                ...enquiryCardState,
                showDisclaimer: true
              };
            });
          }}
          onSubmitClose={() => {
            setEnquiryCardState(() => {
              return {
                ...enquiryCardState,
                showDisclaimer: false,
                quoteFormData: false
              };
            });
          }}
          data={quoteFormData}
          isDuplicate={true}
          isConsolidation={isConsolidation}
        />
      </Drawer>
    </main>
  );
};

export default EnquiryCard;
