import { Controller } from 'react-hook-form';
import css from './index.module.scss';
import { SelectLabel, TextField } from '@components/common';

const QuotePackageWrapper = (props: any) => {
  const {
    label,
    inputLabel = '‎',
    unitLabel = '‎',
    unitPlaceholder,
    inputPlaceholder,
    input,
    unit,
    control,
    unitOptions,
    currency,
    inputOnChange,
    unitOnChange,
    inputRequired = false
  } = props;

  const showAsterisk = inputRequired ? ' *' : undefined;

  return (
    <main className={css.mainWrapper}>
      <div className={css.mainWrapperLabel} data-value={showAsterisk}>
        {label}
      </div>
      <div
        className={css.contentWrapper}
        style={currency ? { display: 'flex', flexDirection: 'row-reverse' } : undefined}>
        <div className={css.contentWrapperInput}>
          <Controller
            name={input}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={inputLabel}
                placeholder={inputPlaceholder}
                onChange={(e) => {
                  inputOnChange && inputOnChange();
                  field.onChange(e);
                }}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.contentWrapperUnit}>
          <Controller
            name={unit}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                options={unitOptions}
                placeholder={unitPlaceholder}
                label={unitLabel}
                onChange={(e) => {
                  unitOnChange && unitOnChange();
                  field.onChange(e);
                }}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </main>
  );
};

export default QuotePackageWrapper;
