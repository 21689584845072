import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Button, Drawer, IconNode, Loader, Tab, Tabs } from '@components/base';
import { CLIENT_ROUTES } from '@router/routes';
import { useParams, useSearchParams } from 'react-router-dom';
import Images from '@assets/images';
import NonConsolidationDetail from './components/non-consolidation-detail';
import { getFormattedDate, openDocumentSignedLink, sanitizeValue } from '@helpers/utils';
import ConsolidationDetail from './components/consolidation-detail';
import { CURRENCY, WEIGHT_SYSTEM } from '@helpers/constants';
import QuoteForm from '@components/common/quote-form';
import { getEnquiryDetails, submitGeneratePDF } from '@services/quote.service';
import notify from '@helpers/toastify-helper';
import GeneratePDFTab from './components/generate-pdf-tab';
import { DeletePrompt } from '@components/common';
import AccessWrapper from '@authorization/access-wrapper';

const QuoteDetail = (props: any) => {
  const { actions } = props.modulePermissions;
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [quoteDetail, setQuoteDetail] = useState<any>({
    isLoading: false,
    activeTab: 0,
    openViewQuoteModal: false,
    detailModalData: null,
    isConsolidation: false,
    data: null,
    productData: null,
    openAddEditQuoteModal: false,
    addEditQuoteModalData: null,
    addEditQuoteIsEdit: false,
    addEditQuoteIsNew: false,
    selectedCurrency: [],
    selectedWeightSystemChildOptions: [],
    currency1: null,
    currency2: null,
    fxRate: null,
    openGeneratePDFModal: false,
    openEditEnquiryDrawer: false,
    readableEnquiryNumber: '',
    isFcl: false,
    isMtt: false,
    selectedQuotes: [],
    showDisclaimer: false,
    primaryUnit: null
  });

  const [showQuotes, setShowQuotes] = useState<{ [key: number]: boolean }>({});

  const {
    activeTab,
    isConsolidation,
    openGeneratePDFModal,
    openEditEnquiryDrawer,
    data,
    productData,
    readableEnquiryNumber,
    selectedQuotes,
    currency1,
    currency2,
    showDisclaimer
  } = quoteDetail;

  const fetchEnquiryDetail = async (enquiry_id?: string) => {
    const response = await getEnquiryDetails(enquiry_id ? enquiry_id : params.id ?? '');
    if (response?.success) {
      const { data } = response;
      let productDetails: any;
      if (data.is_consolidation) {
        productDetails = data.consolidations;
      } else {
        const groupedData: any = {};
        data.quotes.forEach((quote: any) => {
          const { product_details, ...rest } = quote;
          const key = product_details.product_id;

          if (!groupedData[key]) {
            groupedData[key] = {
              product: product_details,
              details: []
            };
          }
          groupedData[key].details.push(rest);
        });
        productDetails = Object.values(groupedData);
      }
      setQuoteDetail((prevState: any) => ({
        ...prevState,
        data: response.data,
        productData: productDetails,
        isConsolidation: data.is_consolidation,
        isFcl: data.container_type == 'FCL' ? true : false,
        isMtt: data.sourcing_type == 'MTT' ? true : false,
        currency1: CURRENCY.find((item) => item.value === data.currency_type_one),
        currency2: CURRENCY.find((item) => item.value === data.currency_type_two),
        fxRate: data.fx_rate,
        selectedWeightSystemChildOptions: WEIGHT_SYSTEM.find(
          (item) => item.value === data.weight_system
        )?.childOptions,
        selectedCurrency: [
          CURRENCY.find((item) => item.value === data.currency_type_one),
          CURRENCY.find((item) => item.value === data.currency_type_two)
        ],
        readableEnquiryNumber: data.readable_enquiry_number,
        primaryUnit:
          WEIGHT_SYSTEM.find((item) => item.value === data?.weight_system)?.childOptions?.find(
            (item) => item.value === data.primary_unit
          ) ?? null
      }));
    } else if (response?.error) {
      notify({
        severity: 'error',
        message: 'Something Went Wrong' // response?.error?.message
      });
    }
  };

  useEffect(() => {
    fetchEnquiryDetail(params.id ?? '');
  }, [params.id]);

  const handleTabChange = (tab: number) => {
    setQuoteDetail({ ...quoteDetail, activeTab: tab });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeTab: tab === 0 ? 'product' : 'documents'
    });
  };

  const generatePDF = async () => {
    if (selectedQuotes.length === 0) {
      if (productData?.length > 0) {
        // Build a new object like {0: true, 1: true, 2: true, ...}
        const allExpanded = productData.reduce((acc: any, _item: any, index: number) => {
          acc[index] = true;
          return acc;
        }, {});
        setShowQuotes(allExpanded);
      }
      notify({
        severity: 'warning',
        message: 'Please select at least one quote to generate PDF'
      });
      return;
    }
    setQuoteDetail((prevState: any) => {
      return {
        ...prevState,
        isLoading: true
      };
    });
    const data =
      selectedQuotes
        .map((quote: any) => (isConsolidation ? quote.products : { quote_id: quote.quote_id }))
        .flat() ?? [];

    const reqBody = isConsolidation
      ? {
          quotes: data.map((product: any) => {
            return {
              quote_id: product.quote_id
            };
          })
        }
      : {
          quotes: data.map((quote: any) => {
            return {
              quote_id: quote.quote_id
            };
          })
        };

    const response = await submitGeneratePDF(params.id || '', reqBody);
    if (response.success) {
      notify({
        severity: 'success',
        message: 'PDF Generated Successfully'
      });
      setQuoteDetail((prevState: any) => {
        return {
          ...prevState,
          isLoading: false,
          selectedQuotes: []
        };
      });
      openDocumentSignedLink(response.data?.pdf_path);
    } else {
      notify({
        severity: 'error',
        message: response?.error?.error || 'Something Went Wrong!, Contact Tech Team'
      });
      setQuoteDetail((prevState: any) => {
        return {
          ...prevState,
          isLoading: false
        };
      });
    }
  };

  return (
    <AccessWrapper show={actions?.read}>
      <main className={css.mainWrapper}>
        <BreadCrumb>
          <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.quoteGenerator}`}>
            Enquiry List
          </BreadCrumb.Item>
          <BreadCrumb.Item
            value={1}
            to={`/${CLIENT_ROUTES.quoteGenerator}/${CLIENT_ROUTES.detail}/${params.id}`}>
            {readableEnquiryNumber}
          </BreadCrumb.Item>
        </BreadCrumb>

        <div className={css.container}>
          <section className={css.basicDetails}>
            <div className={css.header}>
              <div className={css.headerText}>Basic Details</div>
              {/* <Button
              variant="contained"
              className={css.headerButton}
              onClick={() => {
                setQuoteDetail(() => {
                  return {
                    ...quoteDetail,
                    openEditEnquiryDrawer: true
                  };
                });
              }}>
              <div>
                <IconNode src={Images.editWhite} alt="quote icon" className={css.editIcon} />
              </div>
              <div className={css.headerButtonText}>Edit</div>
            </Button> */}
            </div>
            <div className={css.basicDetailsContent}>
              <div className={css.basicDetailsContentItem}>
                <div className={css.keyValue}>
                  <div className={css.key}>Customer Name</div>
                  <div className={css.value}>{sanitizeValue(data?.customer_name)}</div>
                </div>
                <div className={css.keyValue}>
                  <div className={css.key}>Enquiry/Deal Name</div>
                  <div className={css.value}>{sanitizeValue(data?.deal_name)}</div>
                </div>
                <div className={css.keyValue}>
                  <div className={css.key}>Price Validity Until</div>
                  <div className={css.value}>
                    {sanitizeValue(getFormattedDate(data?.last_valid_date))}
                  </div>
                </div>
                <div className={css.keyValue}>
                  <div className={css.key}>Date </div>
                  <div className={css.value}>
                    {sanitizeValue(getFormattedDate(data?.created_at))}
                  </div>
                </div>
              </div>
              <div className={css.basicDetailsContentItem}>
                <div className={css.keyValue}>
                  <div className={css.key}>Entity</div>
                  <div className={css.value}>{sanitizeValue(data?.entity?.name)}</div>
                </div>

                <div className={css.keyValue}>
                  <div className={css.key}>Payment Terms</div>
                  <div className={css.value}>{sanitizeValue(data?.readable_payment_terms)}</div>
                </div>

                <div className={css.keyValue}>
                  <div className={css.key}>Expected Delivery Schedule</div>
                  <div className={css.value}>{sanitizeValue(data?.expected_delivery_schedule)}</div>
                </div>

                <div className={css.keyValue}>
                  <div className={css.key}>FxRate</div>
                  <div className={css.value}>
                    {data?.fx_rate
                      ? `1 ${currency1.value} = ${data.fx_rate} ${currency2.value}`
                      : '-'}
                  </div>
                </div>
              </div>
              <div className={css.basicDetailsContentItem}>
                <div className={css.keyValue}>
                  <div className={css.key}>Enquiry Number </div>
                  <div className={css.value}>{sanitizeValue(readableEnquiryNumber)} </div>
                </div>
                <div className={css.keyValue}>
                  <div className={css.key}>Created By</div>
                  <div className={css.value}>
                    {sanitizeValue(data?.created_by?.given_name)}{' '}
                    {sanitizeValue(data?.created_by?.family_name, '')}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={css.productDetails}>
            <div className={css.header}>
              <Tabs value={activeTab}>
                <Tab value={0} label="Products" onClick={() => handleTabChange(0)} />
                <Tab value={1} label="Generated PDF" onClick={() => handleTabChange(1)} />
              </Tabs>
            </div>

            <AccessWrapper show={actions?.create}>
              {activeTab === 0 && (
                <Button
                  variant="outlined-secondary"
                  className={css.generatePDFButton}
                  onClick={() => generatePDF()}>
                  Generate PDF
                </Button>
              )}
            </AccessWrapper>

            {activeTab === 0 &&
              (isConsolidation ? (
                <ConsolidationDetail
                  productData={productData}
                  setQuoteDetail={setQuoteDetail}
                  quoteDetail={quoteDetail}
                  fetchEnquiryDetail={fetchEnquiryDetail}
                  showQuotes={showQuotes}
                  setShowQuotes={setShowQuotes}
                  actions={actions}
                />
              ) : (
                <NonConsolidationDetail
                  productData={productData}
                  setQuoteDetail={setQuoteDetail}
                  quoteDetail={quoteDetail}
                  fetchEnquiryDetail={fetchEnquiryDetail}
                  showQuotes={showQuotes}
                  setShowQuotes={setShowQuotes}
                  actions={actions}
                />
              ))}
            {activeTab === 1 && <GeneratePDFTab />}
          </section>
        </div>

        {/* {openGeneratePDFModal && (
        <GeneratePDF
          open={openGeneratePDFModal}
          onClose={() => setQuoteDetail({ ...quoteDetail, openGeneratePDFModal: false })}
          isConsolidation={isConsolidation}
          data={
            selectedQuotes.map((quote: any) =>
              isConsolidation
                ? { consolidation_details_id: quote.consolidation_details_id }
                : { quote_id: quote.quote_id }
            ) ?? []
          }
          enquiryId={params.id}
        />
      )} */}

        {/* <AccessWrapper show={actions?.create}>
          <Drawer
            open={openEditEnquiryDrawer}
            anchor="right"
            onClose={() =>
              setQuoteDetail(() => {
                return {
                  ...quoteDetail,
                  showDisclaimer: true
                };
              })
            }>
            <QuoteForm
              onClose={() => {
                setQuoteDetail(() => {
                  return {
                    ...quoteDetail,
                    showDisclaimer: true
                  };
                });
              }}
              onSubmitClose={() => {
                setQuoteDetail(() => {
                  return {
                    ...quoteDetail,
                    showDisclaimer: false,
                    openEditEnquiryDrawer: false,
                    openAddEditQuoteModal: false,
                    addEditQuoteIsNew: false,
                    addEditQuoteIsEdit: false
                  };
                });
                fetchEnquiryDetail(params.id ?? '');
              }}
              data={data}
              isEdit={true}
              isConsolidation={isConsolidation}
            />
          </Drawer>

          {showDisclaimer && (
            <DeletePrompt
              open={showDisclaimer}
              onClose={() =>
                setQuoteDetail((prevState: any) => ({
                  ...prevState,
                  showDisclaimer: false
                }))
              }
              onConfirm={() =>
                setQuoteDetail((prevState: any) => ({
                  ...prevState,
                  showDisclaimer: false,
                  openEditEnquiryDrawer: false,
                  openAddEditQuoteModal: false,
                  addEditQuoteIsNew: false,
                  addEditQuoteIsEdit: false
                }))
              }
              message="Are you sure you want to close?"
            />
          )}
        </AccessWrapper> */}
        <Loader open={quoteDetail.isLoading} />
      </main>
    </AccessWrapper>
  );
};

export default QuoteDetail;
