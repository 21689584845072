import * as yup from 'yup';
import { paymentTerm } from './add-payment.schema';

const selectSchema = yup.object().shape({
  value: yup.string(),
  label: yup.string()
});

// Helper to allow "null" and transform to an empty object
const objectAllowNull = yup
  .object()
  .nullable()
  .transform((currentValue, originalValue) => {
    // If the original value was `null`, convert it to an empty object {}
    return originalValue === null ? {} : currentValue;
  });

export const genericInformation = yup.object().shape({
  currency1: selectSchema.required('Currency 1 is required'),
  currency2: selectSchema.nullable(),
  fxRate: yup.string().when('currency2', (currency2: any) => {
    if (currency2?.[0]) {
      return yup.string().required('FX Rate is required when Currency 2 is selected');
    }
    return yup.string().nullable();
  }),
  weightSystem: selectSchema.required('Weight system is required'),
  draftDealName: yup.string().nullable(),
  remarks: yup.string().nullable()
});

export const nonConsolidationQuote = yup.object().shape({
  productName: objectAllowNull
    .shape({
      product_name: yup.string(),
      product_id: yup.string().nullable()
    })
    .test('requireProductName', 'Product Name is required', function (value: any) {
      if (!value?.product_name) {
        return this.createError({ path: this.path });
      }
      return true;
    }),
  dealQuantity: yup
    .number()
    .typeError('Deal Quantity must be a number')
    .required('Deal Quantity is required'),
  dealQuantityUnit: selectSchema.when('dealQuantity', (dealQuantity: any) => {
    if (dealQuantity?.[0]) {
      return selectSchema.required('Deal Quantity Unit is required when Deal Quantity is present');
    }
    return selectSchema.required('Deal Quantity Unit is required');
  }),

  base_price: yup.object().shape({
    value: yup.number().nullable().typeError('Base Price value must be a number'),
    currency: selectSchema.when('base_price', (base_price: any) => {
      if (base_price?.[0]) {
        return selectSchema.required('Base Price Currency is required when Base Price is present');
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  final_exWorks_price: yup.object().shape({
    value: yup.number().nullable().typeError('Final Ex Works Price value must be a number'),
    currency: selectSchema.when('final_exWorks_price', (final_exWorks_price: any) => {
      if (final_exWorks_price?.[0]) {
        return selectSchema.required(
          'Final Ex Works Price Currency is required when Final Ex Works Price is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  fob_price: yup.object().shape({
    value: yup.number().nullable().typeError('FOB Price value must be a number'),
    currency: selectSchema.when('fob_price', (fob_price: any) => {
      if (fob_price?.[0]) {
        return selectSchema.required('FOB Price Currency is required when FOB Price is present');
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  cif_price: yup.object().shape({
    value: yup.number().nullable().typeError('CIF Price value must be a number'),
    currency: selectSchema.when('cif_price', (cif_price: any) => {
      if (cif_price?.[0]) {
        return selectSchema.required('CIF Price Currency is required when CIF Price is present');
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  cfr_price: yup.object().shape({
    value: yup.number().nullable().typeError('CFR Price value must be a number'),
    currency: selectSchema.when('cfr_price', (cfr_price: any) => {
      if (cfr_price?.[0]) {
        return selectSchema.required('CFR Price Currency is required when CFR Price is present');
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  moq: yup.number().nullable().typeError('MOQ must be a number'),
  totalWeight: yup
    .number()
    .typeError('Total Weight must be a number')
    .required('Total Weight is required'),
  totalWeightUnit: selectSchema.when('totalWeight', (totalWeight: any) => {
    if (totalWeight?.[0]) {
      return selectSchema.required('Total Weight Unit is required when Total Weight is present');
    }
    return selectSchema.nullable();
  }),
  totalLocalFreight: yup.object().shape({
    value: yup.number().nullable().typeError('Total Local Freight value must be a number'),
    currency: selectSchema.when('totalLocalFreight', (totalLocalFreight: any) => {
      if (totalLocalFreight?.[0]) {
        return selectSchema.required(
          'Total Local Freight Currency is required when Total Local Freight is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  totalPortAndClearanceExpenses: yup.object().shape({
    value: yup
      .number()
      .nullable()
      .typeError('Total Port and Clearance Expenses value must be a number'),
    currency: selectSchema.when('totalPortAndClearanceExpenses', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Total Port And Clearance Expenses Currency is required when Total Port And Clearance Expenses is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  portOfLoading: objectAllowNull
    .shape({
      label: yup.string(),
      value: yup.string().nullable()
    })
    .test('requirePortOfLoading', 'Port of Loading is required', function (value: any) {
      if (!value?.label) {
        return this.createError({ path: this.path });
      }
      return true;
    }),
  incoTerms: objectAllowNull
    .shape({
      label: yup.string(),
      value: yup.string().nullable()
    })
    .test('requireIncoTerms', 'Inco Term is required', function (value: any) {
      if (!value?.label) {
        return this.createError({ path: this.path });
      }
      return true;
    }),
  totalFreight: yup.object().shape({
    value: yup.number().nullable().typeError('Total Freight value must be a number'),
    currency: selectSchema.when('totalFreight', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Total Freight Currency is required when Total Freight is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  additionalCost: yup.object().shape({
    value: yup.number().nullable().typeError('Additional Cost value must be a number'),
    currency: selectSchema.when('additionalCost', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Additional Cost Currency is required when Additional Cost is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  profitMarginExpected: yup
    .mixed()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .test('is-number-or-null', 'Expected Profit Margin must be a number', (value: any) => {
      if (value === null) return false; // fails because it's required
      return !isNaN(parseFloat(value)) && isFinite(value);
    })
    .required('Expected Profit Margin is required')
});

export const nonConsolidationQuoteValidation = yup.object().shape({
  genericInformation: genericInformation,
  quote: yup.array().of(nonConsolidationQuote).required('At least one quote is required')
});

export const consolidationQuote = yup.object().shape({
  moq: yup.number().nullable().typeError('MOQ must be a number'),
  totalWeight: yup
    .number()
    .typeError('Total Weight must be a number')
    .required('Total Weight is required'),
  totalWeightUnit: selectSchema.when('totalWeight', (totalWeight: any) => {
    if (totalWeight?.[0]) {
      return selectSchema.required('Total Weight Unit is required when Total Weight is present');
    }
    return selectSchema.nullable();
  }),
  totalLocalFreight: yup.object().shape({
    value: yup.number().nullable().typeError('Total Local Freight value must be a number'),
    currency: selectSchema.when('totalLocalFreight', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Total Local Freight Currency is required when Total Local Freight is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  totalPortAndClearanceExpenses: yup.object().shape({
    value: yup
      .number()
      .nullable()
      .typeError('Total Port And Clearance Expenses value must be a number'),
    currency: selectSchema.when('totalPortAndClearanceExpenses', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Total Port And Clearance Expenses Currency is required when Total Port And Clearance Expenses is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  portOfLoading: objectAllowNull
    .shape({
      label: yup.string(),
      value: yup.string().nullable()
    })
    .test('requirePortOfLoading', 'Port of Loading is required', function (value: any) {
      if (!value?.label) {
        return this.createError({ path: this.path });
      }
      return true;
    }),
  incoTerms: objectAllowNull
    .shape({
      label: yup.string(),
      value: yup.string().nullable()
    })
    .test('requireIncoTerms', 'Inco Term is required', function (value: any) {
      if (!value?.label) {
        return this.createError({ path: this.path });
      }
      return true;
    }),
  totalFreight: yup.object().shape({
    value: yup.number().nullable().typeError('Total Freight value must be a number'),
    currency: selectSchema.when('totalFreight', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Total Freight Currency is required when Total Freight is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  additionalCost: yup.object().shape({
    value: yup.number().nullable().typeError('Additional Cost value must be a number'),
    currency: selectSchema.when('additionalCost', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required(
          'Additional Cost Currency is required when Additional Cost is present'
        );
      }
      return selectSchema.nullable();
    }),
    cost_id: yup.string().nullable()
  }),
  product: yup.array().of(
    yup.object().shape({
      productName: objectAllowNull
        .shape({
          product_name: yup.string(),
          product_id: yup.string().nullable()
        })
        .test('requireProductName', 'Product Name is required', function (value: any) {
          if (!value?.product_name) {
            return this.createError({ path: this.path });
          }
          return true;
        }),
      productPackaging: yup.string().nullable(),
      dealQuantity: yup
        .number()
        .typeError('Deal Quantity must be a number')
        .required('Deal Quantity is required'),
      dealQuantityUnit: selectSchema.when('dealQuantity', (dealQuantity: any) => {
        if (dealQuantity?.[0]) {
          return selectSchema.required(
            'Deal Quantity Unit is required when Deal Quantity is present'
          );
        }
        return selectSchema.nullable();
      }),
      base_price: yup.object().shape({
        value: yup.number().nullable().typeError('Base Price value must be a number'),
        currency: selectSchema.when('base_price', (base_price: any) => {
          if (base_price?.[0]) {
            return selectSchema.required(
              'Base Price Currency is required when Base Price is present'
            );
          }
          return selectSchema.nullable();
        }),
        cost_id: yup.string().nullable()
      }),
      fob_price: yup.object().shape({
        value: yup.number().nullable().typeError('FOB Price value must be a number'),
        currency: selectSchema.when('fob_price', (fob_price: any) => {
          if (fob_price?.[0]) {
            return selectSchema.required(
              'FOB Price Currency is required when FOB Price is present'
            );
          }
          return selectSchema.nullable();
        }),
        cost_id: yup.string().nullable()
      }),
      cif_price: yup.object().shape({
        value: yup.number().nullable().typeError('CIF Price value must be a number'),
        currency: selectSchema.when('cif_price', (cif_price: any) => {
          if (cif_price?.[0]) {
            return selectSchema.required(
              'CIF Price Currency is required when CIF Price is present'
            );
          }
          return selectSchema.nullable();
        }),
        cost_id: yup.string().nullable()
      }),
      cfr_price: yup.object().shape({
        value: yup.number().nullable().typeError('CFR Price value must be a number'),
        currency: selectSchema.when('cfr_price', (cfr_price: any) => {
          if (cfr_price?.[0]) {
            return selectSchema.required(
              'CFR Price Currency is required when CFR Price is present'
            );
          }
          return selectSchema.nullable();
        }),
        cost_id: yup.string().nullable()
      }),
      profitMarginExpected: yup
        .mixed()
        .transform((value) => (value === '' ? null : value))
        .nullable()
        .test('is-number-or-null', 'Expected Profit Margin must be a number', (value: any) => {
          if (value === null) return false; // fails because it's required
          return !isNaN(parseFloat(value)) && isFinite(value);
        })
        .required('Expected Profit Margin is required'),

      final_exWorks_price: yup.object().shape({
        value: yup.number().nullable().typeError('Final Ex Works Price value must be a number'),
        currency: selectSchema.when('final_exWorks_price', (final_exWorks_price: any) => {
          if (final_exWorks_price?.[0]) {
            return selectSchema.required(
              'Final Ex Works Price Currency is required when Final Ex Works Price is present'
            );
          }
          return selectSchema.nullable();
        }),
        cost_id: yup.string().nullable()
      }),
      finalQuote_selling_price: yup.object().shape({
        value: yup
          .number()
          .nullable()
          .typeError('Final Quote Selling Price value must be a number'),
        currency: selectSchema.when('finalQuote_selling_price', (finalQuote_selling_price: any) => {
          if (finalQuote_selling_price?.[0]) {
            return selectSchema.required(
              'Final Quote Selling Price Currency is required when Final Quote Selling Price is present'
            );
          }
          return selectSchema.nullable();
        }),
        cost_id: yup.string().nullable()
      })
    })
  )
});

export const consolidationQuoteValidation = yup.object().shape({
  genericInformation: genericInformation,
  quote: yup.array().of(consolidationQuote).required('At least one quote is required')
});

export const enquiryFormValidation = yup.object().shape({
  customer: selectSchema.required('Customer is required'),
  entity: selectSchema.required('Entity is required'),
  payment_terms: yup.array().of(paymentTerm),
  priceValidUntill: yup.date().nullable(),
  expectedDeliverySchedule: yup.string().nullable()
});

export const nonConsolidationAddEditQuoteValidation = yup.object().shape({
  quote: yup.object().shape({
    productName: objectAllowNull
      .shape({
        product_name: yup.string(),
        product_id: yup.string().nullable()
      })
      .test('requireProductName', 'Product Name is required', function (value: any) {
        if (!value?.product_name) {
          return this.createError({ path: this.path });
        }
        return true;
      }),

    dealQuantity: yup
      .number()
      .typeError('Deal Quantity must be a number')
      .required('Deal Quantity is required'),

    dealQuantityUnit: selectSchema.when('dealQuantity', (dealQuantity: any) => {
      if (dealQuantity?.[0]) {
        return selectSchema.required(
          'Deal Quantity Unit is required when Deal Quantity is present'
        );
      }
      return selectSchema.required('Deal Quantity Unit is required');
    }),

    base_price: yup.object().shape({
      value: yup.number().nullable().typeError('Base Price value must be a number'),
      currency: selectSchema.when('base_price', (base_price: any) => {
        if (base_price?.[0]) {
          return selectSchema.required(
            'Base Price Currency is required when Base Price is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    final_exWorks_price: yup.object().shape({
      value: yup.number().nullable().typeError('Final Ex Works Price value must be a number'),
      currency: selectSchema.when('final_exWorks_price', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Final Ex Works Price Currency is required when Final Ex Works Price is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    fob_price: yup.object().shape({
      value: yup.number().nullable().typeError('FOB Price value must be a number'),
      currency: selectSchema.when('fob_price', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required('FOB Price Currency is required when FOB Price is present');
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    cif_price: yup.object().shape({
      value: yup.number().nullable().typeError('CIF Price value must be a number'),
      currency: selectSchema.when('cif_price', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required('CIF Price Currency is required when CIF Price is present');
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    cfr_price: yup.object().shape({
      value: yup.number().nullable().typeError('CFR Price value must be a number'),
      currency: selectSchema.when('cfr_price', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required('CFR Price Currency is required when CFR Price is present');
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    moq: yup.number().nullable().typeError('MOQ must be a number'),

    totalWeight: yup
      .number()
      .typeError('Total Weight must be a number')
      .required('Total Weight is required'),

    totalWeightUnit: selectSchema.when('totalWeight', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required('Total Weight Unit is required when Total Weight is present');
      }
      return selectSchema.nullable();
    }),

    totalLocalFreight: yup.object().shape({
      value: yup.number().nullable().typeError('Total Local Freight value must be a number'),
      currency: selectSchema.when('totalLocalFreight', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Total Local Freight Currency is required when Total Local Freight is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    totalPortAndClearanceExpenses: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .typeError('Total Port and Clearance Expenses value must be a number'),
      currency: selectSchema.when('totalPortAndClearanceExpenses', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Total Port And Clearance Expenses Currency is required when Total Port And Clearance Expenses is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    portOfLoading: objectAllowNull
      .shape({
        label: yup.string(),
        value: yup.string().nullable()
      })
      .test('requirePortOfLoading', 'Port of Loading is required', function (value: any) {
        if (!value?.label) {
          return this.createError({ path: this.path });
        }
        return true;
      }),
    incoTerms: objectAllowNull
      .shape({
        label: yup.string(),
        value: yup.string().nullable()
      })
      .test('requireIncoTerms', 'Inco Term is required', function (value: any) {
        if (!value?.label) {
          return this.createError({ path: this.path });
        }
        return true;
      }),

    totalFreight: yup.object().shape({
      value: yup.number().nullable().typeError('Total Freight value must be a number'),
      currency: selectSchema.when('totalFreight', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Total Freight Currency is required when Total Freight is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    additionalCost: yup.object().shape({
      value: yup.number().nullable().typeError('Additional Cost value must be a number'),
      currency: selectSchema.when('additionalCost', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Additional Cost Currency is required when Additional Cost is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),
    profitMarginExpected: yup
      .number()
      .typeError('Expected Profit Margin must be a number')
      .required('Expected Profit Margin is required')
  })
});

export const consolidationAddEditQuoteValidation = yup.object().shape({
  quote: yup.object().shape({
    totalWeight: yup
      .number()
      .typeError('Total Weight must be a number')
      .required('Total Weight is required'),
    totalWeightUnit: selectSchema.when('totalWeight', (val: any) => {
      if (val?.[0]) {
        return selectSchema.required('Total Weight Unit is required when Total Weight is present');
      }
      return selectSchema.nullable();
    }),
    totalLocalFreight: yup.object().shape({
      value: yup.number().nullable().typeError('Total Local Freight value must be a number'),
      currency: selectSchema.when('totalLocalFreight', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Total Local Freight Currency is required when Total Local Freight is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),

    totalPortAndClearanceExpenses: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .typeError('Total Port And Clearance Expenses value must be a number'),
      currency: selectSchema.when('totalPortAndClearanceExpenses', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Total Port And Clearance Expenses Currency is required when Total Port And Clearance Expenses is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),
    totalFreight: yup.object().shape({
      value: yup.number().nullable().typeError('Total Freight value must be a number'),
      currency: selectSchema.when('totalFreight', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Total Freight Currency is required when Total Freight is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),
    portOfLoading: objectAllowNull
      .shape({
        label: yup.string().nullable(),
        value: yup.string().nullable()
      })
      .test('requirePortOfLoading', 'Port of Loading is required', function (value) {
        if (!value?.label) {
          return this.createError({ path: this.path });
        }
        return true;
      }),
    incoTerms: objectAllowNull
      .shape({
        label: yup.string(),
        value: yup.string().nullable()
      })
      .test('requireIncoTerms', 'Inco Term is required', function (value: any) {
        if (!value?.label) {
          return this.createError({ path: this.path });
        }
        return true;
      }),
    portOfDischarge: objectAllowNull
      .shape({
        label: yup.string().nullable(),
        value: yup.string().nullable()
      })
      .nullable(),
    insuranceCost: yup.number().nullable().typeError('Insurance Cost must be a number'),
    financeCost: yup.number().nullable().typeError('Finance Cost must be a number'),
    discount: yup.number().nullable().typeError('Discount must be a number'),
    additionalCost: yup.object().shape({
      value: yup.number().nullable().typeError('Additional Cost value must be a number'),
      currency: selectSchema.when('additionalCost', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Additional Cost Currency is required when Additional Cost is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),
    switchBlCost: yup.object().shape({
      value: yup.number().nullable().typeError('Switch BL Cost value must be a number'),
      currency: selectSchema.when('switchBlCost', (val: any) => {
        if (val?.[0]) {
          return selectSchema.required(
            'Switch BL Cost Currency is required when Switch BL Cost is present'
          );
        }
        return selectSchema.nullable();
      }),
      cost_id: yup.string().nullable()
    }),
    product: yup.array().of(
      yup.object().shape({
        isExpanded: yup.boolean().nullable(),
        countryOfOrigin: yup.string().nullable(),

        productName: objectAllowNull
          .shape({
            product_name: yup.string(),
            product_id: yup.string().nullable()
          })
          .test('requireProductName', 'Product Name is required', function (value) {
            if (!value?.product_name) {
              return this.createError({ path: this.path });
            }
            return true;
          }),

        productPackaging: yup.string().nullable(),

        dealQuantity: yup
          .number()
          .typeError('Deal Quantity must be a number')
          .required('Deal Quantity is required'),

        dealQuantityUnit: selectSchema.when('dealQuantity', (dealQuantity: any) => {
          if (dealQuantity?.[0]) {
            return selectSchema.required(
              'Deal Quantity Unit is required when Deal Quantity is present'
            );
          }
          return selectSchema.nullable();
        }),

        base_price: yup.object().shape({
          value: yup.number().nullable().typeError('Base Price value must be a number'),
          currency: selectSchema.when('base_price', (val: any) => {
            if (val?.[0]) {
              return selectSchema.required(
                'Base Price Currency is required when Base Price is present'
              );
            }
            return selectSchema.nullable();
          }),
          cost_id: yup.string().nullable()
        }),

        fob_price: yup.object().shape({
          value: yup.number().nullable().typeError('FOB Price value must be a number'),
          currency: selectSchema.when('fob_price', (val: any) => {
            if (val?.[0]) {
              return selectSchema.required(
                'FOB Price Currency is required when FOB Price is present'
              );
            }
            return selectSchema.nullable();
          }),
          cost_id: yup.string().nullable()
        }),

        cif_price: yup.object().shape({
          value: yup.number().nullable().typeError('CIF Price value must be a number'),
          currency: selectSchema.when('cif_price', (val: any) => {
            if (val?.[0]) {
              return selectSchema.required(
                'CIF Price Currency is required when CIF Price is present'
              );
            }
            return selectSchema.nullable();
          }),
          cost_id: yup.string().nullable()
        }),

        cfr_price: yup.object().shape({
          value: yup.number().nullable().typeError('CFR Price value must be a number'),
          currency: selectSchema.when('cfr_price', (val: any) => {
            if (val?.[0]) {
              return selectSchema.required(
                'CFR Price Currency is required when CFR Price is present'
              );
            }
            return selectSchema.nullable();
          }),
          cost_id: yup.string().nullable()
        }),

        profitMarginExpected: yup
          .mixed()
          .transform((value) => (value === '' ? null : value))
          .nullable()
          .test('is-number-or-null', 'Expected Profit Margin must be a number', (value: any) => {
            if (value === null) return false; // fails because it's required
            return !isNaN(parseFloat(value)) && isFinite(value);
          })
          .required('Expected Profit Margin is required'),

        final_exWorks_price: yup.object().shape({
          value: yup.number().nullable().typeError('Final Ex Works Price value must be a number'),
          currency: selectSchema.when('final_exWorks_price', (val: any) => {
            if (val?.[0]) {
              return selectSchema.required(
                'Final Ex Works Price Currency is required when Final Ex Works Price is present'
              );
            }
            return selectSchema.nullable();
          }),
          cost_id: yup.string().nullable()
        }),

        finalQuote_selling_price: yup.object().shape({
          value: yup
            .number()
            .nullable()
            .typeError('Final Quote Selling Price value must be a number'),
          currency: selectSchema.when('finalQuote_selling_price', (val: any) => {
            if (val?.[0]) {
              return selectSchema.required(
                'Final Quote Selling Price Currency is required when Final Quote Selling Price is present'
              );
            }
            return selectSchema.nullable();
          }),
          cost_id: yup.string().nullable()
        }),

        moq: yup.number().nullable().typeError('MOQ must be a number'),
        moqUnit: selectSchema.nullable()
      })
    )
  })
});
