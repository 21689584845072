import React, { useEffect } from 'react';
import css from './index.module.scss';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AutoComplete, SelectLabel, TextField } from '@components/common';
import QuotePackageWrapper from '@components/common/quote-form/components/quote-generator/components/quote-package-wrapper';
import { INCO_TERMS, PORT_FIELD_TYPES } from '@helpers/constants';
import { createFilterOptions } from '@mui/material';
import {
  calculateCFRPrice,
  calculateCIFPrice,
  calculateExWorksPrice,
  calculateFobPrice,
  calculateQuotedPrice,
  formatPortOptions
} from '@helpers/utils';
import { Button, Typography } from '@components/base';

const filter = createFilterOptions<any>();

const NonConsolidatedQuote = (props: any) => {
  const {
    onFormSubmit,
    onClose,
    selectedWeightSystemChildOptions,
    selectedCurrency,
    isFcl,
    isMtt,
    isEdit,
    productOptions,
    filterProductOptions,
    filterPorts,
    portOfLoadingOptions,
    portOfDischargeOptions,
    quoteDetail
  } = props;

  const { currency1, currency2, fxRate, primaryUnit } = quoteDetail;

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
    resetField,
    setValue,
    getValues
  } = useFormContext();

  const basePriceWatch = useWatch({
    control,
    name: `quote.base_price.value`
  });

  const basePriceCurrencyWatch = useWatch({
    control,
    name: `quote.base_price.currency`
  });

  const finalExWorksPriceWatch = useWatch({
    control,
    name: `quote.final_exWorks_price.value`
  });

  const finalExWorksPriceCurrencyWatch = useWatch({
    control,
    name: `quote.final_exWorks_price.currency`
  });

  const dealQuantityWatch = useWatch({
    control,
    name: `quote.dealQuantity`
  });

  const dealQuantityUnitWatch = useWatch({
    control,
    name: `quote.dealQuantityUnit`
  });

  const totalLocalFreightWatch = useWatch({
    control,
    name: `quote.totalLocalFreight.value`
  });

  const totalLocalFreightCurrencyWatch = useWatch({
    control,
    name: `quote.totalLocalFreight.currency`
  });

  const totalPortAndClearanceExpensesWatch = useWatch({
    control,
    name: `quote.totalPortAndClearanceExpenses.value`
  });

  const totalPortAndClearanceExpensesCurrencyWatch = useWatch({
    control,
    name: `quote.totalPortAndClearanceExpensesCurrency.currency`
  });

  const totalWeightWatch = useWatch({
    control,
    name: `quote.totalWeight`
  });

  const totalWeightUnitWatch = useWatch({
    control,
    name: `quote.totalWeightUnit`
  });

  const fobPriceCurrencyWatch = useWatch({
    control,
    name: `quote.fob_price.currency`
  });

  const fobPriceWatch = useWatch({
    control,
    name: `quote.fob_price.value`
  });

  const fobPriceManualOverrideWatch = useWatch({
    control,
    name: `quote.fob_price.manualOverride`
  });

  const cfrPriceManualOverrideWatch = useWatch({
    control,
    name: `quote.cfr_price.manualOverride`
  });

  const cifPriceManualOverrideWatch = useWatch({
    control,
    name: `quote.cif_price.manualOverride`
  });

  const totalFreightWatch = useWatch({
    control,
    name: `quote.totalFreight.value`
  });

  const totalFreightCurrencyWatch = useWatch({
    control,
    name: `quote.totalFreight.currency`
  });

  const cifPriceWatch = useWatch({
    control,
    name: `quote.cif_price.value`
  });

  const additionalCostWatch = useWatch({
    control,
    name: `quote.additionalCost.value`
  });

  const additionalCostCurrencyWatch = useWatch({
    control,
    name: `quote.additionalCost.currency`
  });

  const financeCostWatch = useWatch({
    control,
    name: `quote.financeCost`
  });

  const profitMarginExpectedWatch = useWatch({
    control,
    name: `quote.profitMarginExpected`
  });

  const discountWatch = useWatch({
    control,
    name: `quote.discount`
  });

  const cfrPriceCurrencyWatch = useWatch({
    control,
    name: `quote.cfr_price.currency`
  });

  const cfrPriceWatch = useWatch({
    control,
    name: `quote.cfr_price.value`
  });

  const insuranceCostWatch = useWatch({
    control,
    name: `quote.insuranceCost`
  });

  const cifPriceCurrencyWatch = useWatch({
    control,
    name: `quote.cif_price.currency`
  });

  const switchBLCostWatch = useWatch({
    control,
    name: `quote.switchBlCost.value`
  });

  const switchBLCostCurrencyWatch = useWatch({
    control,
    name: `quote.switchBlCost.currency`
  });

  useEffect(() => {
    if (!basePriceWatch) {
      return;
    }
    const exWorksPrice = calculateExWorksPrice(
      basePriceCurrencyWatch,
      currency1,
      basePriceWatch,
      fxRate || 1,
      finalExWorksPriceCurrencyWatch
    );
    setValue(`quote.final_exWorks_price.value`, exWorksPrice.toFixed(2));
  }, [basePriceWatch, fxRate, currency1, basePriceCurrencyWatch, finalExWorksPriceCurrencyWatch]);

  useEffect(() => {
    if (fobPriceManualOverrideWatch) return;
    if (!totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }
    const finalFobPrice = calculateFobPrice(
      totalLocalFreightWatch,
      totalLocalFreightCurrencyWatch,
      totalPortAndClearanceExpensesWatch,
      totalPortAndClearanceExpensesCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRate || 1,
      currency1,
      dealQuantityUnitWatch,
      fobPriceCurrencyWatch,
      finalExWorksPriceWatch,
      finalExWorksPriceCurrencyWatch
    );
    const newValue = finalFobPrice.toFixed(2);
    const currentValue = getValues(`quote.fob_price.value`);
    if (newValue !== currentValue) {
      setValue(`quote.fob_price.value`, finalFobPrice.toFixed(2));
    } else return;
  }, [
    totalLocalFreightWatch,
    totalLocalFreightCurrencyWatch,
    totalPortAndClearanceExpensesWatch,
    totalPortAndClearanceExpensesCurrencyWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    finalExWorksPriceWatch,
    dealQuantityUnitWatch,
    fobPriceCurrencyWatch,
    finalExWorksPriceCurrencyWatch,
    fobPriceManualOverrideWatch
  ]);

  // CFR Price
  useEffect(() => {
    if (cfrPriceManualOverrideWatch) return;
    if (!fxRate || !totalWeightWatch || !totalWeightUnitWatch) {
      return;
    }
    const finalCfrPrice = calculateCFRPrice(
      totalFreightWatch,
      totalFreightCurrencyWatch,
      totalWeightWatch,
      totalWeightUnitWatch,
      fxRate || 1,
      cfrPriceCurrencyWatch,
      currency1,
      fobPriceWatch,
      fobPriceCurrencyWatch,
      dealQuantityUnitWatch
    );
    const currentValue = getValues(`quote.cfr_price.value`);
    if (finalCfrPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(`quote.cfr_price.value`, finalCfrPrice.toFixed(2));
  }, [
    fobPriceCurrencyWatch,
    fobPriceWatch,
    totalWeightWatch,
    totalWeightUnitWatch,
    totalFreightWatch,
    totalFreightCurrencyWatch,
    cfrPriceCurrencyWatch,
    dealQuantityUnitWatch,
    switchBLCostWatch,
    switchBLCostCurrencyWatch,
    cfrPriceManualOverrideWatch
  ]);

  // CIF Price Calculation
  useEffect(() => {
    if (cifPriceManualOverrideWatch) return;
    if (!fxRate) {
      return;
    }
    const finalCIFPrice = calculateCIFPrice(
      fxRate || 1,
      currency1,
      cfrPriceWatch,
      cfrPriceCurrencyWatch,
      insuranceCostWatch,
      cifPriceCurrencyWatch
    );
    const currentValue = getValues(`quote.cif_price.value`);
    if (finalCIFPrice.toFixed(2) === currentValue) {
      return;
    }
    setValue(`quote.cif_price.value`, finalCIFPrice.toFixed(2));
  }, [insuranceCostWatch, cfrPriceWatch, cifPriceCurrencyWatch, cfrPriceCurrencyWatch]);

  // Quote Selling Price Calculation
  useEffect(() => {
    const QuoteSellingPrice = calculateQuotedPrice(
      cifPriceWatch,
      cifPriceCurrencyWatch,
      additionalCostWatch,
      additionalCostCurrencyWatch,
      switchBLCostWatch,
      switchBLCostCurrencyWatch,
      financeCostWatch,
      profitMarginExpectedWatch,
      discountWatch,
      currency1,
      fxRate || 1
    );
    setValue(`quote.finalQuote_selling_price.value`, QuoteSellingPrice.toFixed(2));
  }, [
    cifPriceWatch,
    cifPriceCurrencyWatch,
    additionalCostWatch,
    additionalCostCurrencyWatch,
    financeCostWatch,
    profitMarginExpectedWatch,
    discountWatch,
    switchBLCostWatch,
    switchBLCostCurrencyWatch,
    cifPriceManualOverrideWatch
  ]);

  const handleProductSelection = (event: any, param: any) => {
    if (param) {
      setValue(`quote.productName`, param);
    } else {
      setValue(`quote.productName`, null);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.mainWrapper}>
      <section className={css.formWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.productName`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <AutoComplete
                  {...field}
                  required
                  label="Product"
                  placeholder="Select/Type"
                  disabled={true}
                  options={productOptions}
                  onInputChange={filterProductOptions}
                  onInputSelection={handleProductSelection}
                  filterOptions={(options: any, params: any) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option: any) => inputValue === option.product_name
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        product_name: inputValue,
                        product_id: null
                      });
                    }
                    return filtered;
                  }}
                  keyOption="product_name"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing2}
                  freeSolo
                />
              );
            }}
          />
          <QuotePackageWrapper
            label="Deal Quantity"
            unitPlaceholder="Unit"
            inputPlaceholder="Deal Quantity"
            input={`quote.dealQuantity`}
            unit={`quote.dealQuantityUnit`}
            control={control}
            unitOptions={selectedWeightSystemChildOptions}
            currency={false}
            inputRequired
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label="MOQ"
            unitPlaceholder="Unit"
            inputPlaceholder="MOQ"
            input={`quote.moq`}
            unit={`quote.moqUnit`}
            control={control}
            unitOptions={selectedWeightSystemChildOptions}
          />
          <Controller
            name={`quote.productPackaging`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Product Packaging"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label={
              <span>
                Base Price
                {primaryUnit && (
                  <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                )}
              </span>
            }
            unitPlaceholder="Currency"
            inputPlaceholder="Base Price"
            input={`quote.base_price.value`}
            unit={`quote.base_price.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
            inputOnChange={() => {
              setValue(`quote.fob_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.cfr_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.cif_price.manualOverride`, false, { shouldDirty: false });
            }}
          />
          <QuotePackageWrapper
            label={
              <span>
                Ex-Works Price
                {primaryUnit && (
                  <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                )}
              </span>
            }
            unitPlaceholder="Currency"
            inputPlaceholder="Ex-Works Price"
            input={`quote.final_exWorks_price.value`}
            unit={`quote.final_exWorks_price.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
            inputOnChange={() => {
              setValue(`quote.fob_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.cfr_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.cif_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.base_price.value`, 0);
            }}
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label="Total local freight"
            unitPlaceholder="Currency"
            inputPlaceholder="Total local freight"
            input={`quote.totalLocalFreight.value`}
            unit={`quote.totalLocalFreight.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
          <QuotePackageWrapper
            label="Total Port and Clearance Expenses"
            unitPlaceholder="Currency"
            inputPlaceholder="Total Port and Clearance Expenses"
            input={`quote.totalPortAndClearanceExpenses.value`}
            unit={`quote.totalPortAndClearanceExpenses.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label={isFcl ? 'Total weight in one container' : 'Total weight'}
            unitPlaceholder="Unit"
            inputPlaceholder="Total weight"
            input={`quote.totalWeight`}
            unit={`quote.totalWeightUnit`}
            control={control}
            unitOptions={selectedWeightSystemChildOptions}
            currency={false}
            inputRequired
          />
          <QuotePackageWrapper
            label={
              <span>
                Fob Price
                {primaryUnit && (
                  <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                )}
              </span>
            }
            unitPlaceholder="Currency"
            inputPlaceholder="Fob Price"
            input={`quote.fob_price.value`}
            unit={`quote.fob_price.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
            inputOnChange={() => {
              setValue(`quote.fob_price.manualOverride`, true, { shouldDirty: true });
              setValue(`quote.cfr_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.cif_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.totalPortAndClearanceExpenses.value`, 0);
              setValue(`quote.totalLocalFreight.value`, 0);
              setValue(`quote.final_exWorks_price.value`, 0);
              setValue(`quote.base_price.value`, 0);
            }}
          />
        </div>

        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label={isFcl ? 'Total freight per FCL ' : 'Total freight'}
            unitPlaceholder="Currency"
            inputPlaceholder="Total freight"
            input={`quote.totalFreight.value`}
            unit={`quote.totalFreight.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
          <QuotePackageWrapper
            label={
              <span>
                CFR Price
                {primaryUnit && (
                  <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                )}
              </span>
            }
            unitPlaceholder="Currency"
            inputPlaceholder="CFR Price"
            input={`quote.cfr_price.value`}
            unit={`quote.cfr_price.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
            inputOnChange={() => {
              setValue(`quote.cfr_price.manualOverride`, true, { shouldDirty: true });
              setValue(`quote.cif_price.manualOverride`, false, { shouldDirty: false });
              setValue(`quote.totalPortAndClearanceExpenses.value`, 0);
              setValue(`quote.totalLocalFreight.value`, 0);
              setValue(`quote.final_exWorks_price.value`, 0);
              setValue(`quote.base_price.value`, 0);
              setValue(`quote.fob_price.value`, 0);
              setValue(`quote.totalFreight.value`, 0);
            }}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.insuranceCost`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Insurance cost
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <QuotePackageWrapper
            label={
              <span>
                CIF Price
                {primaryUnit && (
                  <Typography variant="label"> ( Per {primaryUnit?.label} )</Typography>
                )}
              </span>
            }
            unitPlaceholder="Currency"
            inputPlaceholder="CIF Price"
            input={`quote.cif_price.value`}
            unit={`quote.cif_price.currency`}
            control={control}
            unitOptions={selectedCurrency}
            inputOnChange={() => {
              setValue(`quote.cif_price.manualOverride`, true, { shouldDirty: true });
              setValue(`quote.insuranceCost`, 0);
              setValue(`quote.cfr_price.value`, 0);
              setValue(`quote.totalPortAndClearanceExpenses.value`, 0);
              setValue(`quote.totalLocalFreight.value`, 0);
              setValue(`quote.final_exWorks_price.value`, 0);
              setValue(`quote.base_price.value`, 0);
              setValue(`quote.fob_price.value`, 0);
              setValue(`quote.totalFreight.value`, 0);
              setValue(`quote.totalWeight`, 0);
            }}
            currency
          />
        </div>
        <div className={css.rowWrapper}>
          <QuotePackageWrapper
            label="Additional Cost"
            unitPlaceholder="Currency"
            inputPlaceholder="Additional Cost"
            input={`quote.additionalCost.value`}
            unit={`quote.additionalCost.currency`}
            control={control}
            unitOptions={selectedCurrency}
            currency
          />
          {isMtt && (
            <QuotePackageWrapper
              label="Switch BL Cost"
              unitPlaceholder="Currency"
              inputPlaceholder="Switch BL Cost"
              input={`quote.switchBlCost.value`}
              unit={`quote.switchBlCost.currency`}
              control={control}
              unitOptions={selectedCurrency}
              currency
            />
          )}
        </div>

        <div className={css.rowWrapper}>
          <Controller
            name={`quote.financeCost`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Finance cost
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`quote.profitMarginExpected`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Profit margin expected
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                required
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.discount`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={
                  <span>
                    Discount
                    <Typography variant="label"> ( % )</Typography>
                  </span>
                }
                placeholder="Enter value in percentage"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name={`quote.incoTerms`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                options={INCO_TERMS}
                label="Inco Terms"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.portOfLoading`}
            control={control}
            render={({ field, fieldState }) => {
              const onPortSelection = (event: any, param: any) => {
                if (param) {
                  setValue(`quote.portOfLoading`, param);
                } else {
                  setValue(`quote.portOfLoading`, null);
                }
              };
              const formattedPortOfLoading = formatPortOptions(portOfLoadingOptions);
              return (
                <AutoComplete
                  {...field}
                  required
                  label="Port Of Loading"
                  placeholder="Start typing"
                  options={formattedPortOfLoading}
                  onInputChange={(event: any, param: any) =>
                    filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_LOADING)
                  }
                  onInputSelection={onPortSelection}
                  keyOption="label"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper1}
                />
              );
            }}
          />
          <Controller
            name={`quote.portOfDischarge`}
            control={control}
            render={({ field, fieldState }) => {
              const onPortSelection = (event: any, param: any) => {
                if (param) {
                  setValue(`quote.portOfDischarge`, param);
                } else {
                  setValue(`quote.portOfDischarge`, null);
                }
              };
              const formattedPortOfDischarge = formatPortOptions(portOfDischargeOptions);
              return (
                <AutoComplete
                  {...field}
                  label="Port Of Discharge"
                  placeholder="Start typing"
                  options={formattedPortOfDischarge}
                  onInputChange={(event: any, param: any) =>
                    filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_DISCHARGE)
                  }
                  onInputSelection={onPortSelection}
                  keyOption="label"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper1}
                />
              );
            }}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`quote.countryOfOrigin`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Country of Origin"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </section>
      <section className={css.actionButtons}>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined-secondary" type="submit">
          {isEdit ? 'Update Quote' : 'Create Quote'}
        </Button>
      </section>
    </form>
  );
};

export default NonConsolidatedQuote;
