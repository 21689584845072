import { Card, CardContent } from '@mui/material';
import React from 'react';
import css from './index.module.scss';
import { useFormContext, useWatch } from 'react-hook-form';
import { getCommaSeperatedCurrencyAmount } from '@helpers/utils';
import { Typography } from '@components/base';

const CostCalculator = (props: any) => {
  const { filters } = props;
  const { fcl, mtt, consolidation } = filters;

  const { control } = useFormContext();

  const currency = useWatch({
    name: `genericInformation.currency1`,
    control
  });

  const primaryUnitWatch = useWatch({
    name: 'genericInformation.primaryUnit',
    control
  });

  const quote = useWatch({
    name: 'quote',
    control
  });

  return (
    <main className={css.mainWrapper}>
      <div className={css.wrapper}>
        <div className={css.header}>Cost calculator</div>
        {!consolidation
          ? quote?.map((item: any, index: number) => {
              const productName = item.productName?.product_name;
              const finalQuotePrice = item.finalQuote_selling_price?.value;
              const finalExWorksPriceCurrency = item.final_exWorks_price?.currency;
              const finalExWorksPrice = item.final_exWorks_price?.value;
              const finalFobPriceCurrency = item.fob_price?.currency;
              const finalFobPrice = item.fob_price?.value;
              const finalCfrPriceCurrency = item.cfr_price?.currency;
              const finalCfrPrice = item.cfr_price?.value;
              const finalCifPriceCurrency = item.cif_price?.currency;
              const finalCifPrice = item.cif_price?.value;
              const isExpanded = item.isExpanded;
              return (
                <div key={index} className={css.cardWrapper}>
                  <div className={css.cardHeader}>
                    {productName ? productName : `Product ${index + 1}`}
                  </div>
                  {isExpanded && (
                    <>
                      <div className={css.cardQuotePrice}>
                        <div className={css.finalQuotePrice}>Final Selling Price: </div>
                        <div className={css.finalQuotePriceValue}>
                          {currency
                            ? getCommaSeperatedCurrencyAmount(currency?.label, finalQuotePrice ?? 0)
                            : 0}
                          <span>
                            {primaryUnitWatch && (
                              <Typography variant="label">
                                {' '}
                                ( Per {primaryUnitWatch?.label} )
                              </Typography>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className={css.cardContent}>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>EX Works</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {currency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalExWorksPriceCurrency?.label,
                                  finalExWorksPrice
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>FOB</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalFobPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalFobPriceCurrency?.label,
                                  finalFobPrice
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>CFR</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalCfrPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalCfrPriceCurrency?.label,
                                  finalCfrPrice
                                )
                              : 0}
                          </div>
                        </div>
                        <div className={css.cardContentItem}>
                          <div className={css.cardContentItemHeader}>CIF</div>
                          <div className={css.cardContentItemColon}>:</div>
                          <div className={css.cardContentItemValue}>
                            {finalCifPriceCurrency
                              ? getCommaSeperatedCurrencyAmount(
                                  finalCifPriceCurrency?.label,
                                  finalCifPrice
                                )
                              : 0}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })
          : quote?.map((item: any, index: number) => {
              const products = item.product;
              return (
                <div key={index} className={css.quoteWrapper}>
                  {products?.map((product: any, productIndex: number) => {
                    const productName = product.productName?.product_name;
                    const finalQuotePrice = product.finalQuote_selling_price?.value;
                    const finalExWorksPriceCurrency = product.final_exWorks_price?.currency;
                    const finalExWorksPrice = product.final_exWorks_price?.value;
                    const finalFobPriceCurrency = product.fob_price?.currency;
                    const finalFobPrice = product.fob_price?.value;
                    const finalCfrPriceCurrency = product.cfr_price?.currency;
                    const finalCfrPrice = product.cfr_price?.value;
                    const finalCifPriceCurrency = product.cif_price?.currency;
                    const finalCifPrice = product.cif_price?.value;
                    const isExpanded = product.isExpanded;
                    return (
                      <div key={productIndex} className={css.cardWrapper}>
                        <div className={css.cardHeader}>
                          {productName ? productName : `Product ${productIndex + 1}`}
                        </div>
                        {isExpanded && (
                          <>
                            <div className={css.cardQuotePrice}>
                              <div className={css.finalQuotePrice}>Final Selling Price: </div>
                              <div className={css.finalQuotePriceValue}>
                                {currency
                                  ? getCommaSeperatedCurrencyAmount(
                                      currency?.label,
                                      finalQuotePrice ?? 0
                                    )
                                  : 0}
                                <span>
                                  {primaryUnitWatch && (
                                    <Typography variant="label">
                                      {' '}
                                      ( Per {primaryUnitWatch?.label} )
                                    </Typography>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className={css.cardContent}>
                              <div className={css.cardContentItem}>
                                <div className={css.cardContentItemHeader}>EX Works</div>
                                <div className={css.cardContentItemColon}>:</div>
                                <div className={css.cardContentItemValue}>
                                  {currency
                                    ? getCommaSeperatedCurrencyAmount(
                                        finalExWorksPriceCurrency?.label,
                                        finalExWorksPrice
                                      )
                                    : 0}
                                </div>
                              </div>
                              <div className={css.cardContentItem}>
                                <div className={css.cardContentItemHeader}>FOB</div>
                                <div className={css.cardContentItemColon}>:</div>
                                <div className={css.cardContentItemValue}>
                                  {finalFobPriceCurrency
                                    ? getCommaSeperatedCurrencyAmount(
                                        finalFobPriceCurrency?.label,
                                        finalFobPrice
                                      )
                                    : 0}
                                </div>
                              </div>
                              <div className={css.cardContentItem}>
                                <div className={css.cardContentItemHeader}>CFR</div>
                                <div className={css.cardContentItemColon}>:</div>
                                <div className={css.cardContentItemValue}>
                                  {finalCfrPriceCurrency
                                    ? getCommaSeperatedCurrencyAmount(
                                        finalCfrPriceCurrency?.label,
                                        finalCfrPrice
                                      )
                                    : 0}
                                </div>
                              </div>
                              <div className={css.cardContentItem}>
                                <div className={css.cardContentItemHeader}>CIF</div>
                                <div className={css.cardContentItemColon}>:</div>
                                <div className={css.cardContentItemValue}>
                                  {finalCifPriceCurrency
                                    ? getCommaSeperatedCurrencyAmount(
                                        finalCifPriceCurrency?.label,
                                        finalCifPrice
                                      )
                                    : 0}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
      </div>
    </main>
  );
};

export default CostCalculator;
